import { url } from "inspector";
import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../context/Provider";


const DashboardCard = (props: any) => {
    const { authState,language_data } = useContext(GlobalContext);
    return (
        <Link to={props.url} className="text-decoration-none">
            <div className="dashboard-card h-100">
                {/* card title  */}
                <div className="dashboard-card-title d-flex justify-content-between align-items-start gap-1">
                    <h5 className={props.loading?'placeholder':''}>{props.title}</h5>
                    <span className='px-2'><small className="text-nowrap">{language_data?.[authState.lang]?.common_monthly}</small></span>
                </div>
                {/* count and Description */}
                <div className="dashboard-card-content d-flex justify-content-between align-items-center gap-2">
                    <div className={`dashboard-count-description d-flex flex-column ${props.loading?'placeholder-glow':''}`}>
                        <h2 className={`fw-bold m-0 mb-1 ${props.loading?'placeholder':''}`}>{props.count}</h2>
                        <small className={props.loading?'placeholder':''}>{props.description}</small>
                    </div>
                    {/* Icon  */}
                    <div className='dashboard-card-icon'>
                        <i className={`fa ${props.icon}`}></i>
                    </div>
                </div>
            </div>
            </Link>
    )
}
export default DashboardCard;