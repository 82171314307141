import { Fragment, useContext, useState } from 'react';
import {  useLocation, useMatch } from 'react-router-dom';
import BreadCrumb from '../../../components/common/BreadCrumb';
import commonArray from '../../../components/common/commonArray';
import Errormessage from '../../../components/common/errormessage';
import Inputs, { SaveCancelButton } from '../../../components/common/Inputs';
import Spinner from '../../../components/common/spinner';
import { GlobalContext, handleError } from '../../../context/Provider';
import henceforthApi from '../../../utils/henceforthApi';
import { MultipleSelect } from '../productInterface';

const EdiSpecification = () => {
    const location = useLocation()
    const match = useMatch("product/:id/specification/:specification_id/edit")
    const {language_data, authState, toastMessage,authDispatch } = useContext(GlobalContext);
    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' }, 
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_list}`, url: `/products/1`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_deatil}`, url: `/product/${match?.params.id}`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_edit} ${language_data?.[authState.lang]?.common_specifications}`, url: ``, active: 'not-allowed' }
    ]
    
    const newParam = new URLSearchParams(location.search);
    const [editSpecs, setEditSpecs] = useState({
        key: newParam.get("key"),
        value: newParam.get("value")
    } as MultipleSelect)
    const [keyErr, setKeyErr] = useState<string>('')
    const [valueErr, setValueErr] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const edit = async () => {
        const items = {
            _id: match?.params.specification_id,
            product_id: match?.params.id,
            key:editSpecs. key,
            value: editSpecs.value,
            language: "ENGLISH"
        }
        if(!editSpecs.key && !editSpecs.value){ 
            setKeyErr(`${language_data?.[authState.lang]?.common_key}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setValueErr(`${language_data?.[authState.lang]?.common_value}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`) 
            return
        }
        if(!editSpecs.key) return setKeyErr(`${language_data?.[authState.lang]?.common_key}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
        if(!editSpecs.key.trim()) return setKeyErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_write}${language_data?.[authState.lang]?.common_something}${language_data?.[authState.lang]?.common_in}${language_data?.[authState.lang]?.common_key}`)
        if(!editSpecs.value) return setValueErr(`${language_data?.[authState.lang]?.common_value}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
        if(!editSpecs.value.trim()) return setValueErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_write}${language_data?.[authState.lang]?.common_something}${language_data?.[authState.lang]?.common_in}${language_data?.[authState.lang]?.common_value}`)
        setLoading(true)
        try {
            const apiRes = await henceforthApi.Product.editProductDetailsDynamically(commonArray.productOtherDetails[1],items)
            toastMessage("Specifications Edited Successfully")
            window.history.back()
        } catch (error) {
            handleError(error,'active',authDispatch);
        } finally {
            setLoading(false)
        }

    }
    const handleChange = (e: any) => {
        let name = e.target.name
        let val = e.target.value
        if(name==="key") setKeyErr('')
        if(name==="value") setValueErr('')
        setEditSpecs({
            ...editSpecs,
            [name]: val
        })
    }
    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            <div className='page-spacing'>
                <section className='edit-profile'>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-sm-10 col-md-7 col-lg-6 col-xl-5 col-xxl-3">
                                {/* title  */}
                                <div className="common-card">
                                    <div className="common-card-title">
                                        <h5>{language_data?.[authState.lang]?.common_edit} {language_data?.[authState.lang]?.common_specifications}</h5>
                                    </div>
                                    {/* form  */}
                                    <div className="common-card-content">
                                        <form onSubmit={(e) => { e.preventDefault(); edit() }}>
                                            {/* Key */}
                                            <Inputs.Input type="text" error={keyErr} value={editSpecs.key} placeholder={`${language_data?.[authState.lang]?.common_enter}${language_data?.[authState.lang]?.common_your}${language_data?.[authState.lang]?.common_key}`} name='key' handleChange={handleChange} />
                                            {/* Value */}
                                            <Inputs.Input type="text" error={valueErr} value={editSpecs.value} placeholder={`${language_data?.[authState.lang]?.common_enter}${language_data?.[authState.lang]?.common_your}${language_data?.[authState.lang]?.common_value}`} name='value' handleChange={handleChange} />
                                            {/* Submit Button  */}
                                            <SaveCancelButton loading={loading} color="text-white" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}
export default EdiSpecification;