import profile_img from '../../assets/images/pages/defaultImage.jpg';
import '../../assets/styles/pages.scss'
import { Link } from 'react-router-dom';
import React, { Fragment, useContext } from 'react';
import { GlobalContext, handleError } from '../../context/Provider';
import henceforthApi from '../../utils/henceforthApi';
import VerifyOtp from '../../components/modals/VerifyEmailOtp';
import authAction from '../../context/authAction';
import { toast } from 'react-toastify';
const Profile = () => {
    const { language_data, authState, authDispatch, loading, setLoading } = useContext(GlobalContext)
    const [openEmailVerification, setOpenEmailVerification] = React.useState(false)

    const initialiseProfile = async (data: any) => {
        try {
            let apiRes = await henceforthApi.Auth.emailVerifyOtp({ otp: data, language: 'ENGLISH' })
            authAction.initialiseProfile(apiRes.data)(authDispatch);
            setOpenEmailVerification(false)
        } catch (err) {
            handleError(err, 'active', authDispatch)
        }
    }
    const resendEmailOtp = async () => {
        if (loading) return
        setLoading(true)
        try {
            let items = {
                email: authState.email,
                language: "ENGLISH"
            }
            let apiRes = await henceforthApi.Auth.emailVerifySendOtp(items)
            // toast.success(apiRes.data)
            // setOpenEmailVerification(true)
            return apiRes.data
        } catch (error: any) {
            // if (error.response && error.response.body) {
            //     toast.error(error.response.body.error_description)
            // }
            handleError(error, 'active', authDispatch)
        } finally {
            setLoading(false)
        }
    }

    return (<Fragment>
        {/* breadcrum  */}
        <section className="breadcrum-box">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h2>{language_data?.[authState.lang]?.common_profile}</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/">{language_data?.[authState.lang]?.common_home}</Link></li>
                                <li className="breadcrumb-item active fw-bold">{language_data?.[authState.lang]?.common_profile}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        {/* page  */}
        <div className='page-spacing'>
            <section className='seller-profile'>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-sm-9 col-md-5 col-lg-4 col-xl-4 col-xxl-3 px-0">
                            {/* Title  */}
                            <div className="common-card h-100">
                                <div className="common-card-title">
                                    <h5>{language_data?.[authState.lang]?.common_my}{language_data?.[authState.lang]?.common_profile}</h5>
                                </div>
                                {/* Profile  */}
                                <div className="common-card-content">
                                    {/* Profile image  */}
                                    <div className="profile-image">
                                        <img src={authState?.image == null ? profile_img : authState?.image?.endsWith('.webp') ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${authState?.image}` : !authState?.image?.endsWith('.webp') ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState?.image}` : profile_img} alt={authState.image} className='' />

                                    </div>
                                    {/* Profile Detail  */}
                                    <div className="profile-image my-4">
                                        <h5 className='mb-3'>{authState.name}</h5>
                                        <p className="d-flex align-items-center mb-1" title={authState.email}><i className='fa fa-envelope me-2 fs-5'></i>{authState.email.length > 20  ? authState.email.slice(0, 17) + '...' : authState.email } &nbsp;{authState.email_verified ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-exclamation-triangle text-danger" aria-hidden="true" onClick={() => setOpenEmailVerification(true)}></i>}</p>
                                        <p className="d-flex align-items-center mt-2"><i className='fa fa-phone-square me-2  fs-5'></i>+{authState.country_code} {authState.phone_number} </p>
                                        <p title={authState.full_address} className="d-flex align-items-start mt-2">{authState.full_address ? <i className='fa fa-map-marker me-2  fs-5'></i> : ''}{authState.full_address} </p></div>
                                    {/* button  */}
                                    <div className="profile-button">
                                        <Link to="/profile/edit" className='btn btn-theme w-100'>{language_data?.[authState.lang]?.common_edit} {language_data?.[authState.lang]?.common_profile}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {openEmailVerification &&
                            <VerifyOtp verifyCallback={initialiseProfile} resendOtpCallback={resendEmailOtp} onCloseModal={setOpenEmailVerification} />
                        }
                    </div>
                </div>
            </section>
        </div>
    </Fragment>
    )
}

export default Profile;