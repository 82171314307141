import { Fragment, useContext, useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import BreadCrumb from "../../components/common/BreadCrumb";
import CustomerInvoicePdf from "../../components/customer-invoice/customer-invoice-pdf";
import { GlobalContext, handleError } from "../../context/Provider";
import henceforthApi from "../../utils/henceforthApi";
import { orderInvoice } from "./orderInterface";

const CustomerInvoice = () => {
    const match = useMatch('/order/:id/invoice')
    const {language_data, loading, setLoading, authState, authDispatch } = useContext(GlobalContext)
    let breadCrumbPath = [
        { name: ` ${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: ` ${language_data?.[authState.lang]?.common_order} ${language_data?.[authState.lang]?.common_list}`, url: `/orders/1`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_order} ${language_data?.[authState.lang]?.common_deatil}`, url: `/order/${match?.params.id}`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_download_seller_invoice}`, url: ``, active: 'not-allowed' },
    ]
    
    const [orderD, setOrderD] = useState({} as orderInvoice)
    const orderListing = async () => {
        setLoading(true)
        try {
            let res = (await henceforthApi.Order.getOrderInvoiceDetails(match?.params.id)).data
            setOrderD(res)
        } catch (err: any) {
            handleError(err, '', authDispatch);
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        orderListing()
    }, [])
    
    return (<Fragment>
        {/* breadcrum  */}
        <BreadCrumb pathNameDeclare={breadCrumbPath} />
        {/* Page  */}
        {!loading &&
            <div className=' page-spacing'>
                <CustomerInvoicePdf dataHolder={orderD} authState={authState} language_data={language_data}/>
            </div>}
    </Fragment>
    );
}
export default CustomerInvoice;