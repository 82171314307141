import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../assets/styles/pages.scss';
import DashboardCard from '../components/dashboard-card';
import Graph from '../components/graph';
import { GlobalContext, handleError } from '../context/Provider';
import henceforthApi from '../utils/henceforthApi';
import { numberWithCommas } from '../utils/validations';
const Sales = 'sales'
const Product = 'product'
interface counts {
  total_products: string,
  total_orders: string,
  alert_of_stock: string,
  out_of_stock: string,
  total_earnings: string,
  total_ratings: string,
  total_reviews: string,
}

const Home = () => {
  const { authState, loading, setLoading, language_data, authDispatch } = useContext(GlobalContext);
  const location = useLocation()
  const navigate = useNavigate()
  let newParam = new URLSearchParams(location.search)
  let ProductMatch = newParam.get(Product) as string | null
  let SalesMatch = newParam.get(Sales) as string | null

  const [count, setCount] = useState({
    total_products: "",
    total_orders: "",
    alert_of_stock: "",
    out_of_stock: "",
    total_earnings: "",
    total_ratings: "",
    total_reviews: "",
  } as counts)
  const [loadingG, setLoadingG] = useState({
    product: false,
    sales: false
  })
  const [productX, setProductX] = useState<string>('')
  const [salesX, setSalesX] = useState<string>('')
  const [productGraph, setProductGraph] = useState([] as any)
  const [salesGraph, setSalesGraph] = useState([] as any)
  const dashboardDetails = async () => {
    setLoading(true)
    try {
      let res = (await henceforthApi.Dashboard.getTotalOrderCount()).data;
      setCount(res)
    } catch (error: any) {
      handleError(error, '', authDispatch);
    } finally {
      setLoading(false)
    }
  }
  const dashboadGraph = async (graph: string, type: string) => {
    if (graph && type) {
      newParam.set(graph, type)
    }
    let types = type === "DAILY" ? 'hour' : type === "WEEKLY" ? 'day' : type === "MONTHLY" ? 'date' : type === "YEARLY" ? 'month' : ''
    if (graph === Product) setProductX(types)
    if (graph === Sales) setSalesX(types)
    setLoadingG({
      ...loadingG,
      [graph]: true
    })
    try {
      let res = (await henceforthApi.Dashboard.getGraph(graph, type)).data.data;
      if (graph === 'product') {
        setProductGraph(res)
      } else {
        setSalesGraph(res)
      }
      navigate({ search: newParam.toString() })
    } catch (error: any) {
      handleError(error, '', authDispatch);


    } finally {
      setLoading(false)
      setLoadingG({
        ...loadingG,
        product: false,
        sales: false
      })
    }
  }
  useEffect(() => {
    dashboardDetails()
  }, [])
  useEffect(() => {
    dashboadGraph('product', 'DAILY')
    dashboadGraph('sales', 'DAILY')
  }, []);
  const dashboardCardArray = [
    { url: '/products/1', title: language_data?.[authState.lang]?.common_product, description: `${language_data?.[authState.lang]?.common_total} ${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_count}`, count: count.total_products ? count.total_products : '0', icon: "fa-laptop" },
    { url: '/orders/1', title: language_data?.[authState.lang]?.common_order, description: `${language_data?.[authState.lang]?.common_total} ${language_data?.[authState.lang]?.common_order} ${language_data?.[authState.lang]?.common_count}`, count: count.total_orders ? count.total_orders : '0', icon: 'fa-shopping-cart' },
    { url: `/products/1?out_of_stock=true`, title: language_data?.[authState.lang]?.common_out_of_stock, description: `${language_data?.[authState.lang]?.common_total} ${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.home_that_are} ${language_data?.[authState.lang]?.common_out_of_stock}`, count: count.out_of_stock ? count.out_of_stock : '0', icon: "fa-home" },
    { url: '/products/1?qty=5', title: language_data?.[authState.lang]?.common_alert_of_stock, description: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.home_that_will_soon_go_out_of_that_ie_quantity_less_then} ${language_data?.[authState.lang]?._5} `, count: count.alert_of_stock ? count.alert_of_stock : '0', icon: 'fa-bell' },
    { url: '/ratings/1?order_status=DELIVERED', title: language_data?.[authState.lang]?.common_rating_reviews, description: `${language_data?.[authState.lang]?.common_total} ${language_data?.[authState.lang]?.common_rating} ${language_data?.[authState.lang]?.common_count}`, count: count.total_ratings || count.total_reviews ? `${count.total_ratings} & ${count.total_reviews}` : "0", icon: 'fa-star' },
    { url: '/earnings/1', title: language_data?.[authState.lang]?.common_earning, description: `${language_data?.[authState.lang]?.common_total} ${language_data?.[authState.lang]?.common_earning} ${language_data?.[authState.lang]?.home_addition_of_all_order_pricing}`, count: count.total_earnings ? numberWithCommas(Number(count.total_earnings)) : "0", icon: 'fa-dollar' }
  ]

  return (
    <div className='page-spacing'>
      {/****************** Dashboard-Graph  *******************/}
      <section className='dashboard-graph'>
        <div className="container-fluid">
          <div className="row">
            {/* Product Graph  */}
            <Graph heading={language_data?.[authState.lang]?.common_product_graph} Match={ProductMatch} run={Product} data={productGraph} xAxis={productX} yAxis={language_data?.[authState.lang]?.home_total_product} dashboadGraph={dashboadGraph} loading={loadingG.product} />
            {/* Sales Graph  */}
            <Graph heading={language_data?.[authState.lang]?.common_sales_graph} Match={SalesMatch} run={Sales} data={salesGraph} xAxis={salesX} yAxis={language_data?.[authState.lang]?.home_total_orders} dashboadGraph={dashboadGraph} loading={loadingG.sales} />
          </div>
        </div>
      </section>
      {/****************** Dashboard-Stats  *******************/}
      <section className='dashboard-stats'>
        <div className="container-fluid">
          <div className="row gy-4">
            {dashboardCardArray.map((res, index: number) =>
              <div className="col-md-6 col-lg-6 col-xl-4 col-xxl-4 custom-dashboard-card px-0 px-md-2" key={index}>
                <DashboardCard url={res.url} title={res.title} loading={loading} description={res.description} count={res.count} icon={res.icon} />
              </div>)}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
