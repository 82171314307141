import {  useMatch } from 'react-router-dom';
import { Fragment, useContext, useState } from 'react';
import { GlobalContext, handleError } from '../../../context/Provider';
import henceforthApi from '../../../utils/henceforthApi';
import Spinner from '../../../components/common/spinner';
import Errormessage from '../../../components/common/errormessage';
import BreadCrumb from '../../../components/common/BreadCrumb';
import { MultipleSelect } from '../productInterface';
import Inputs, { SaveCancelButton } from '../../../components/common/Inputs';
import commonArray from '../../../components/common/commonArray';

const AddSpecification = () => {
    const match = useMatch('/product/:id/specification/add')
    const {language_data, authState,toastMessage,authDispatch } = useContext(GlobalContext)
    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' }, 
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_list}`, url: `/products/1`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_deatil}`, url: `/product/${match?.params.id}`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_add} ${language_data?.[authState.lang]?.common_specifications}`, url: ``, active: 'not-allowed' }
    ]
    
    const [keyErr,setKeyErr]=useState<string>('')
    const [valueErr,setValueErr]=useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [addSpecs, setAddSpecs] = useState({
        key: "",
        value: ""
    } as MultipleSelect)
    const handleChange = (e: any) => {
        let name = e.target.name
        let val = e.target.value
        if(name==="key") setKeyErr('')
        if(name==="value") setValueErr('')
        setAddSpecs({
            ...addSpecs,
            [name]: val
        })
    }
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if(!addSpecs.key && !addSpecs.value){ 
            setKeyErr(`${language_data?.[authState.lang]?.common_key}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setValueErr(`${language_data?.[authState.lang]?.common_value}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`) 
            return
        }
        if(!addSpecs.key) return setKeyErr(`${language_data?.[authState.lang]?.common_key}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
        if(!addSpecs.key.trim()) return setKeyErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_write}${language_data?.[authState.lang]?.common_something}${language_data?.[authState.lang]?.common_in}${language_data?.[authState.lang]?.common_key}`)
        if(!addSpecs.value) return setValueErr(`${language_data?.[authState.lang]?.common_value}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
        if(!addSpecs.value.trim()) return setValueErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_write}${language_data?.[authState.lang]?.common_something}${language_data?.[authState.lang]?.common_in}${language_data?.[authState.lang]?.common_value}`)
        let items = {
            product_id: match?.params.id,
            product_details: [addSpecs],
            language: "ENGLISH"
        }
        setLoading(true)
        try {
            const apiRes = await henceforthApi.Product.addProductDetailsDynamically(commonArray.productOtherDetails[1],items)
            toastMessage(apiRes.message)
            window.history.back()
        } catch (error) {
            handleError(error,'active',authDispatch);
        } finally {
            setLoading(false)
        }
    }
    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            <div className='page-spacing'>
                <section className='edit-profile'>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-sm-10 col-md-7 col-lg-6 col-xl-5 col-xxl-3">
                                {/* title  */}
                                <div className="common-card">
                                    <div className="common-card-title">
                                        <h5>{language_data?.[authState.lang]?.common_add} {language_data?.[authState.lang]?.common_specifications}</h5>
                                    </div>
                                    {/* form  */}
                                    <div className="common-card-content">
                                        <form onSubmit={handleSubmit}>
                                            {/* Key */}
                                            <Inputs.Input type="text" error={keyErr} value={addSpecs.key} placeholder={`${language_data?.[authState.lang]?.common_enter_your} ${language_data?.[authState.lang]?.common_key}`} name='key' handleChange={handleChange} />
                                            {/* Value */}
                                            <Inputs.Input type="text" error={valueErr} value={addSpecs.value} placeholder={`${language_data?.[authState.lang]?.common_enter_your} ${language_data?.[authState.lang]?.common_value}`} name='value' handleChange={handleChange} />
                                            {/* Submit Button  */}
                                            <SaveCancelButton loading={loading} color="text-white" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default AddSpecification;