var EmailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
var UserNameRegEx = /^[a-zA-Z ]{0,60}$/;
var NumericNumberRegEx = /^[0-9]{0,20}$/;
var NumericResultRegEx = /^(\d{0,2}(\.\d{1,2})?|100(\.00?)?)$/;
var NameRegEx = /^[a-zA-Z0-9 \s()-]{2,60}$/;
var NumberRegEx = /^[0]?[789]\d{9}$/;
var IndNumberRegEx = /^((\+91)?|91)?[789][0-9]{9}/;
var FoodLicenseRegEx = /^[0-9]{5,10}$/;
var DrugLicenseRegEx = /^[0-9]{5,10}$/;
var PincodeRegEx = /^\d{6}$/;
var AddressRegEx = /^[a-zA-Z0-9\s,'-]*$/;
var LatLngRegEx = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,6}/;
var GstRegEx = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/;
var CountryRegEx = "India";
var UUIDRegEx =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/;
var strongPassword =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,16}$/;
var characterNumber = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/g;
export const validateCharNumber = (charNum: string) => {
  return characterNumber.test(charNum);
};
export const validateEmail = (email: string) => {
  return EmailRegEx.test(String(email).toLowerCase());
};
export const numberDataTypeValidation = (str: string) => {
  return typeof str === "number";
};
export const nameValidation = (str: string) => {
  return NameRegEx.test(String(str).trim());
};
export const UserNameValidation = (str: string) => {
  return UserNameRegEx.test(String(str).trim());
};
export const MobileNumberValidation = (str: string) => {
  return NumberRegEx.test(str);
};
export const NumberValidation = (str: string) => {
  return NumericNumberRegEx.test(str);
};
export const NumberValidationWithReturn = (str: string) => {
  return NumericNumberRegEx.test(str) ? str : "";
};
export const ResultValidation = (str: string) => {
  return NumericResultRegEx.test(str);
};
export const MobileNumberWithInValidation = (str: string) => {
  return IndNumberRegEx.test(str);
};
export const FoodLicenseValidation = (str: string) => {
  // return FoodLicenseRegEx.test(str);
  return str;
};
export const DrugLicenseValidation = (str: string) => {
  // return DrugLicenseRegEx.test(str);
  return str;
};
export const GstValidation = (str: string) => {
  return GstRegEx.test(str);
};
export const AddressValidation = (str: string) => {
  // return (string(str).length > 10) ? AddressRegEx.test(str) : false
  return String(str).trim().length > 10 ? str : false;
};
export const PincodeValidation = (str: string) => {
  return PincodeRegEx.test(str);
};
export const LatLngValidation = (str: string) => {
  return LatLngRegEx.test(str);
};
export const CountryValidation = (str: string) => {
  return str === CountryRegEx;
};
export const UuidValidation = (str: string) => {
  return UUIDRegEx.test(str);
};

export const StringValidation = (str: string) => {
  return typeof str === "undefined"
    ? false
    : String(str).trim().length >= 3
      ? nameValidation(str)
      : false;
};
export const ObjectValidation = (str: string) => {
  return typeof str === "object";
};
export const LengthValidation = (str: string, length: number) => {
  return String(str).trim().length > length;
};
export const capitalizeFirstLetter = (string: string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return `_`;
  }
};
export const strongPasswordValidation = (str: string) => {
  return strongPassword.test(str);
};
export const numberWithCommas = (x: number) => {
  var parts = x.toFixed(2).toString()
  parts = parts.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return parts
}
export const capitalise = (str: string | null) => {
  let value = String(str).split("_").join(" ").toLowerCase().replace(/^(.)|\s+(.)/g, c => c.toUpperCase())
  return value
}
export const sliceStr = (str: string, count: number) => {
  return str.length > count ? `${str.slice(0, count)}....${str.slice(str.length - count, str.length)}` : str
}
export const documentId = (id: string) => {
  document.getElementById(id)?.focus()
}
export const formValidate = (name: string, value: string) => {
  if (name === 'price' && String(value).startsWith('0')) return false
  if (name === 'quantity' && String(value).startsWith('0')) return false
  if (name === "price" && !NumberValidation(value) && !value.includes('.')) return false
  if (name === 'tax_percentage' && String(value).startsWith('0')) return false
  if (name === 'discount_percantage' && String(value).startsWith('0')) return false
  if (name === "tax_percentage" && Number(value) > 100) return false
  if (name === 'discount_percantage' && Number(value) > 100) return false
  if (name === "tax_percentage" && !NumberValidation(value) && !value.includes('.')) return false
  if (name === "tax_percentage" && isNaN(Number(value))) return false
  if (name === 'discount_percantage' && !NumberValidation(value) && !value.includes('.')) return false
  if (name === "discount_percantage" && isNaN(Number(value))) return false
  if (name === "quantity" && !NumberValidation(value)) return false
  if (name === "wholesale_quntity" && !NumberValidation(value)) return false
  if (name === "wholesale_price" && !NumberValidation(value)) return false
  if (name === "campaign_quantity" && !NumberValidation(value)) return false

}
