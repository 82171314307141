import '../../../assets/styles/pages.scss'
import { useMatch } from 'react-router-dom'
import { Fragment, useContext, useEffect, useState } from 'react';
import TextEditor from '../../../components/text-editor';
import { GlobalContext, handleError } from '../../../context/Provider';
import henceforthApi from '../../../utils/henceforthApi';
import Spinner from '../../../components/common/spinner';
import Errormessage from '../../../components/common/errormessage';
import Svg from '../../../components/common/error-svg';
import BreadCrumb from '../../../components/common/BreadCrumb';
import { editFaqType } from '../productInterface';
import Inputs, { SaveCancelButton } from '../../../components/common/Inputs';
import commonArray from '../../../components/common/commonArray';
const EditProductFaq = () => {
    const match = useMatch('/product/:product_id/faq/edit/:faq_id')
    let breadCrumbPath = [
        { name: 'Home', url: `/`, active: '' },
        { name: 'Product List', url: `/products/1`, active: '' },
        { name: 'Product detail', url: `/product/${match?.params.product_id}`, active: '' },
        { name: 'Edit Product FAQ', url: ``, active: 'not-allowed' }
    ]
    const { authState, loading, setLoading, authDispatch, language_data, toastMessage } = useContext(GlobalContext)

    const [getLoading, setGetLoading] = useState(false)
    const [answerErr, setAnswerErr] = useState<string>('')
    const [questionErr, setQuestionErr] = useState<string>('')
    const [editFaq, setEditFaq] = useState<any>({
        _id: match?.params.faq_id,
        product_id: match?.params.product_id,
        question: "",
        answer: "",
        language: "ENGLISH",
    } as editFaqType)

    const handleChange = (e: any) => {
        let name = e?.target?.name ?? 'answer';
        let value = e?.target?.value ?? e;
        if (name === "question") setQuestionErr('')
        if (name === "answer") setAnswerErr('')
        setEditFaq({
            ...editFaq,
            [name]: value,
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (!editFaq.answer && !editFaq.question) {
            setQuestionErr(`${language_data?.[authState.lang]?.common_question}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setAnswerErr(`${language_data?.[authState.lang]?.common_answer}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            return
        }
        if (editFaq.answer.trim() === '<p><br></p>' && !editFaq.question) {
            setQuestionErr(`${language_data?.[authState.lang]?.common_question}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            setAnswerErr(`${language_data?.[authState.lang]?.common_answer}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
            return
        }
        if (!editFaq.question) return setQuestionErr(`${language_data?.[authState.lang]?.common_question}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
        if (editFaq.answer.trim() === '<p><br></p>') return setAnswerErr(`${language_data?.[authState.lang]?.common_answer}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
        if ((editFaq.answer.trim().length < 7) || editFaq.answer.trim() === '<p></p>') return setAnswerErr(`${language_data?.[authState.lang]?.common_answer}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
        if (!editFaq.question.trim()) return setQuestionErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_write}${language_data?.[authState.lang]?.common_something}${language_data?.[authState.lang]?.common_in}${language_data?.[authState.lang]?.common_question}`)
        if (!editFaq.answer.trim()) return setAnswerErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_write}${language_data?.[authState.lang]?.common_something}${language_data?.[authState.lang]?.common_in}${language_data?.[authState.lang]?.common_answer}`)
        setLoading(true)
        try {
            let editFaqRes = await henceforthApi.Product.editProductDetailsDynamically(commonArray.productOtherDetails[3], editFaq)
            toastMessage("FAQ Edited Successfully")
            window.history.back()
        } catch (err) {
            handleError(err, 'active', authDispatch);
        } finally {
            setLoading(false)
        }
    }
    const productFaq = async () => {
        setGetLoading(true)
        try {
            let faqRes = (await henceforthApi.Product.getProductDetailsAllDynamics(commonArray.productOtherDetails[3], match?.params.faq_id, '')).data.data[0]
            setEditFaq({
                ...editFaq,
                question: faqRes.question,
                answer: faqRes.answer,
            })
            setGetLoading(false)
        } catch (err: any) {
            setGetLoading(false)
            handleError(err, '', authDispatch);
        }
    }
    useEffect(() => {
        productFaq()
    }, [])
    return (
        <Fragment>
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            <div className='page-spacing'>
                {getLoading ?
                    <div className='vh-100 d-flex justify-content-center py-5'>
                        <Spinner color={"text-success"} />
                    </div> : <section className='product-detail'>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-10 col-md-12">
                                    {/* Title  */}
                                    <div className="common-card">
                                        <div className="common-card-title">
                                            <h5>{language_data?.[authState.lang]?.common_edit} {language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_faq}</h5>
                                        </div>
                                        {/* Profile  */}
                                        <div className="common-card-content">
                                            <form onSubmit={handleSubmit}>
                                                {/* Question  */}
                                                <Inputs.Input type="text" label="Question" error={questionErr} value={editFaq.question} placeholder={`${language_data?.[authState.lang]?.common_add}${language_data?.[authState.lang]?.common_your}${language_data?.[authState.lang]?.common_question}${language_data?.[authState.lang]?.common_here}`} name="question" handleChange={handleChange} />
                                                {/* Answer  */}
                                                <div className='text-editor mb-4'>
                                                    <label className="mb-2 fw-bolder">{language_data?.[authState.lang]?.common_answer}</label>
                                                    <div className={`quill ${answerErr ? 'border border-danger mb-2 position-relative' : ''}`}>
                                                        <TextEditor value={editFaq.answer} onChange={handleChange} />
                                                        {answerErr ? <span className='svg-question'>
                                                            <Svg /></span> : ''}
                                                    </div>
                                                    <Errormessage phone={answerErr} error={answerErr} />
                                                    {/* Button  */}
                                                    <SaveCancelButton loading={loading} color="text-white" />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>}
            </div>
        </Fragment>
    )
}
export default EditProductFaq;