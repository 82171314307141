import '../../assets/styles/auth.scss'
import '../../assets/styles/pages.scss'
import defaultImage from '../../assets/images/pages/defaultImage.jpg'
import { Link, useLocation, useMatch } from 'react-router-dom'
import DownloadFileModal from '../../components/common/download-file-modal'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import PaginationLayout from '../../components/common/PaginationLayout'
import { GlobalContext, handleError } from '../../context/Provider'
import henceforthApi from '../../utils/henceforthApi'
import Spinner from '../../components/common/spinner'
import moment from 'moment'
import BreadCrumb from '../../components/common/BreadCrumb'
import AllFilter from '../../components/common/AllFilter'
import { toast } from 'react-toastify'
import NODATA from '../../assets/images/no-data-found.svg'
import { ForumListingInterface } from './forumInterface'

const ForumListing = () => {
    let Limit = 10
    const location = useLocation()
    const { authState, loading, setLoading, language_data, authDispatch } = useContext(GlobalContext)
    const match = useMatch('/forums/:page')
    let breadCrumbPath = [
        { name: ` ${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: ` ${language_data?.[authState.lang]?.common_forum} ${language_data?.[authState.lang]?.common_list}`, url: ``, active: 'not-allowed' }
    ]
    const newParam = new URLSearchParams(location.search);
    const [setforumList, setForumListing] = useState({
        total_count: 0,
        data: []
    } as ForumListingInterface)
    const forumListing = async () => {
        setLoading(true);
        try {
            let res = (await henceforthApi.Forum.getForumList(Number(match?.params.page) - 1, Limit, newParam.toString()))?.data
            setForumListing({
                total_count: res?.total_count,
                data: res?.data
            })
        } catch (err: any) {
            setForumListing({
                total_count: 0,
                data: []
            })
            handleError(err, 'active', authDispatch);
        } finally {
            setLoading(false)
        }
    }
    const copyText = (id: string, name: string) => {
        if (id) {
            navigator?.clipboard?.writeText(id)
            toast.success(`${name} ${language_data?.[authState.lang]?.common_copy} ${language_data?.[authState.lang]?.common_successfull}`)
        }
    }
    const exportData = async (startDate: number, endDate: number) => {
        try {
            const apiRes = await henceforthApi.Forum.exportForum(startDate, endDate)
            const data = apiRes.data.data
            const rows = [
                [
                    "Sr.No.",
                    "Group Name",
                    "Group Organizer Name",
                    "Group Visibility",
                    "No. Of Members",
                ],
            ];
            if (Array.isArray(data)) {
                data.map((res: any, index: any) => {
                    return (
                        rows.push([
                            index,
                            res?.group_name,
                            res?.created_by_details?.name,
                            res?.group_type,
                            res?.group_members_count,
                        ]))
                })
            }
            let csvContent = "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `forum_${moment().valueOf()}.csv`);
            document.body.appendChild(link); // Required for FF
            link.click(); // This will download the data file named "my_data.csv".
            let closeModal = document.getElementById("closeModal");
            if (closeModal) {
                closeModal.click();
            }
        } catch (err: any) {
            handleError(err, 'active', authDispatch);
        }
    }
    useEffect(() => {
        forumListing()
    }, [match?.params.page, newParam.get("search"), newParam.get("stock"), newParam.get("start_date"), newParam.get("end_date"), newParam.get('payment_status'), newParam.get("order_status"), newParam.get("product_id"), newParam.get("min_price"), newParam.get("max_price")])
    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* Page  */}
            <div className=' page-spacing'>
                <section className='products'>
                    <div className='product-detail-box'>
                        <AllFilter />
                        {/* orderStatus="active" refund="active" date="active" filters="active" */}
                        <div className="common-card">
                            <div className="common-card-title">
                                <h5>{language_data?.[authState.lang]?.common_forum} {language_data?.[authState.lang]?.common_listing} {language_data?.[authState.lang]?.common_deatil}</h5>
                            </div>
                            <div className="common-card-content">
                                {/* table */}
                                <div className='data-list-table table-responsive mb-4 text-center'>
                                    {loading ? <Spinner color={'text-success'} /> : <table className="table table-striped align-middle">
                                        <thead className=''>
                                            <tr>
                                                <th>{language_data?.[authState.lang]?.common_sr_no}</th>
                                                <th>{language_data?.[authState.lang]?.common_group} {language_data?.[authState.lang]?.common_name}</th>
                                                <th>{language_data?.[authState.lang]?.common_group} {language_data?.[authState.lang]?.common_creater}</th>
                                                <th>{language_data?.[authState.lang]?.common_group} {language_data?.[authState.lang]?.common_visibility}</th>
                                                <th>{language_data?.[authState.lang]?.common_members}</th>
                                                <th>{language_data?.[authState.lang]?.common_action}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(setforumList.data) && setforumList.data.length ? setforumList?.data?.map((res: any, index: number) => {
                                                return (
                                                    <tr key={res?._id}>
                                                        <td>{match?.params.page === "0" ? index + 1 : (Number(match?.params.page) - 1) * Limit + (index + 1)}</td>
                                                        <td>{res.group_name ? res.group_name : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td className='product-image-table'>
                                                            <img src={res?.created_by_details?.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.created_by_details?.profile_pic}` : defaultImage} alt="img" className='rounded-circle me-2' />
                                                            {
                                                                res?.is_default? <span>Default Group</span>:
                                                                <span>{res?.created_by_details?.name ? res?.created_by_details?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span>
                                                            }
                                                        </td>
                                                        <td className={res.group_type === "PRIVATE" ? 'text-danger' : 'text-success'}>{res?.group_type ? res?.group_type : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        {/* <td>{res?.created_at ? moment(Number(res?.created_at)).format('DD MMM YYYY') : ''}</td> */}
                                                        <td>{res?.group_members_count}</td>

                                                        <td>
                                                            <div className="btn-group gap-2">
                                                                <Link className="btn btn-white btn-sm" to={`/forum/${res?._id}`}> <i className="fa fa-eye me-1"></i>{language_data?.[authState.lang]?.common_view}</Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr><td className='text-center py-3' colSpan={10}><img src={NODATA} width="100" /><p className='text-center mt-3'>{language_data?.[authState.lang]?.common_no_data_found}</p></td></tr>
                                            }
                                        </tbody>
                                    </table>}
                                </div>
                                {/* pagination  */}
                                <PaginationLayout
                                    data={setforumList.data}
                                    count={setforumList.total_count}
                                    limit={Number(Limit)}
                                    page={Number(match?.params.page)}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <DownloadFileModal exportData={useMemo(() => exportData, [])} />
        </Fragment>
    )
}
export default ForumListing;