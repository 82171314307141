import '../../assets/styles/auth.scss'
import logo from '../../assets/images/logo/logo.png'
import { useNavigate } from 'react-router-dom';
import { Fragment, useContext, useRef, useState } from 'react';
import henceforthApi from '../../utils/henceforthApi';
import { strongPasswordValidation } from '../../utils/validations';
import { GlobalContext, handleError } from '../../context/Provider';
import Spinner from '../../components/common/spinner';
import Errormessage from '../../components/common/errormessage';
const SetPassword = () => {
  const navigate = useNavigate();
  const {language_data,authState, loading, setLoading, toastMessage,authDispatch } = useContext(GlobalContext)
  const [firstEye, setFirstEye] = useState(true);
  const [newPassErr, setNewPassErr] = useState('');
  const [confirmPassErr, setConfirmPassErr] = useState('');
  const [secondEye, setSecondEye] = useState<boolean>(true);
  const passwordMatch = useRef(null) as React.MutableRefObject<any>
  const passwordErr = useRef(null) as React.MutableRefObject<any>
  const [state, setState] = useState({
    newPass: '',
    confirmPass: '',
    language: 'ENGLISH'
  })
  const handleChange = (e: any) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (name === 'newPass') {
      setNewPassErr('')
  }
  if (name === 'confirmPass') {
      setConfirmPassErr('')
  }
    setState({
      ...state,
      [name]: value
    })
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const queryParams = new URLSearchParams(window.location.search)
    const term = queryParams.get("unicode")
    if (!state.newPass && !state.confirmPass){
      setNewPassErr(`${language_data?.[authState.lang]?.common_new} ${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
      setConfirmPassErr(`${language_data?.[authState.lang]?.common_confirmed} ${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
    return
    }
    if (!state.newPass.trim()) return setNewPassErr(`${language_data?.[authState.lang]?.common_new} ${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`);
    if (!state.confirmPass.trim()) return setConfirmPassErr(`${language_data?.[authState.lang]?.common_confirmed} ${language_data?.[authState.lang]?.common_password}${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`);
    if (state.newPass !== state.confirmPass) return setConfirmPassErr(`${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_does_not} ${language_data?.[authState.lang]?.common_match}`)
    else if (!state.newPass.trim() || !strongPasswordValidation(state.newPass)) { return setNewPassErr(`${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_must_be_atleast} ${language_data?.[authState.lang]?._8} ${language_data?.[authState.lang]?.common_characters} ${language_data?.[authState.lang]?.common_long} ${language_data?.[authState.lang]?.common_and} ${language_data?.[authState.lang]?.common_contain} ${language_data?.[authState.lang]?.common_one} ${language_data?.[authState.lang]?.common_uppercase} ${language_data?.[authState.lang]?.common_and} ${language_data?.[authState.lang]?.common_one} ${language_data?.[authState.lang]?.common_lowecase} ${language_data?.[authState.lang]?.common_characters} ${language_data?.[authState.lang]?.common_with} ${language_data?.[authState.lang]?.common_special} ${language_data?.[authState.lang]?.common_characters} ${language_data?.[authState.lang]?.common_and} ${language_data?.[authState.lang]?.common_numbers}`);}
    setLoading(true)
    try {
      let pwdRes = await henceforthApi.Auth.resetPassword({
        unique_code: term,
        password: state.newPass,
        language: "ENGLISH"
      })
      toastMessage(pwdRes.data.message)
      navigate('/')
    } catch (err) {
      handleError(err,'active',authDispatch);

    } finally {
      setLoading(false)
    }

  }
  const passwordCheck = () => {
    if (state.newPass.length < 7) {
      passwordErr.current.innerHTML = `${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_must_be_atleast} ${language_data?.[authState.lang]?._8} ${language_data?.[authState.lang]?.common_characters} ${language_data?.[authState.lang]?.common_long} ${language_data?.[authState.lang]?.common_and} ${language_data?.[authState.lang]?.common_contain} ${language_data?.[authState.lang]?.common_one} ${language_data?.[authState.lang]?.common_uppercase} ${language_data?.[authState.lang]?.common_and} ${language_data?.[authState.lang]?.common_one} ${language_data?.[authState.lang]?.common_lowecase} ${language_data?.[authState.lang]?.common_characters} ${language_data?.[authState.lang]?.common_with} ${language_data?.[authState.lang]?.common_special} ${language_data?.[authState.lang]?.common_characters} ${language_data?.[authState.lang]?.common_and} ${language_data?.[authState.lang]?.common_numbers}`;
    } else {
      passwordErr.current.innerHTML = "";
    }
  }
  const passwordSame = (e: any) => {
    if (state.newPass !== e) {
      passwordMatch.current.innerHTML =`${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_must_be} ${language_data?.[authState.lang]?.common_same}`
    } else {
      passwordMatch.current.innerHTML = ""

    }
  }
  return (
    <Fragment>
      <section className='auth-pages'>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-7 col-sm-10">
              <div className="auth-page-card mb-4">
                {/* logo */}
                <div className='auth-logo-box text-center'>
                <h2 className='fw-bold'>Quantity Savers</h2>
                  {/* <img src={logo} alt="img" /> */}
                </div>
                {/* title  */}
                <div className='auth-page-card-title text-center my-4'>
                  <h3 className='mb-2'>{language_data?.[authState.lang]?.common_reset} {language_data?.[authState.lang]?.common_password}.</h3>
                </div>
                {/* form  */}
                <form onSubmit={handleSubmit}>
                  {/* Password  */}
                  <div className='form-fields-box mb-3'>
                    <div className="input-group mb-3">
                      <input
                        type={firstEye ? "password" : "text"}
                        className={`form-control rounded-0 ${newPassErr ? 'is-invalid' : ''}`}
                        name="newPass"
                        onChange={(e: any) => { handleChange(e); passwordCheck(); }}
                        value={state.newPass}
                        // placeholder={`${language_data?.[authState.lang]?.common_new} ${language_data?.[authState.lang]?.common_password}`}
                        placeholder='New password'
                      />
                      <div className="input-group-append">
                        <span className={`input-group-text group-btn-span ${newPassErr ? 'border border-danger' : ''}`} onClick={() => setFirstEye(!firstEye)}> <i
                          className={`fa ${firstEye ? `fa-eye ${newPassErr ? 'text-danger' : 'text-secondary'}` : `fa-eye-slash ${newPassErr ? 'text-danger' : 'text-secondary'}`}`}
                          aria-hidden="true"
                        ></i></span>
                      </div>
                      <Errormessage class={newPassErr} error={newPassErr} />
                    </div>


                  </div>
                  {newPassErr ? "" : <p ref={passwordErr} className="text-danger ps-1"></p>}
                  {/* Confirm Password  */}
                  <div className='form-fields-box mb-3'>
                    <div className="input-group mb-3">
                      <input
                        type={secondEye ? "password" : "text"}
                        name="confirmPass"
                        onChange={(e: any) => { handleChange(e); passwordSame(e.target.value) }}
                        value={state.confirmPass}
                        className={`form-control rounded-0 ${confirmPassErr ? 'is-invalid' : ''}`}
                        // placeholder={`${language_data?.[authState.lang]?.common_confirmed} ${language_data?.[authState.lang]?.common_password}`}
                        placeholder='Confirm password'
                      />
                      <div className="input-group-append">
                        <span className={`input-group-text group-btn-span ${confirmPassErr ? 'border border-danger' : ''}`} onClick={() => setSecondEye(!secondEye)}> <i
                          className={`fa ${secondEye ? `fa-eye ${confirmPassErr ? 'text-danger' : 'text-secondary'}` : `fa-eye-slash ${confirmPassErr ? 'text-danger' : 'text-secondary'}`}`}

                          aria-hidden="true"
                        ></i></span>
                      </div>
                      <Errormessage class={confirmPassErr} error={confirmPassErr} />
                    </div>
                    {confirmPassErr ? "" : <p ref={passwordMatch} className="text-danger ps-1"></p>}
                  </div>
                  {/* Submit button  */}
                  <div className='form-fields-box'>
                    <button type='submit' className='btn btn-theme w-100' disabled={loading}>
                      {loading ? <Spinner color={"text-white"} /> :`Submit`}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div >
      </section >
    </Fragment>
  );
};

export default SetPassword;
