import "../../../assets/styles/pages.scss";
import TextEditor from "../../../components/text-editor";
import { useMatch } from "react-router-dom";
import { Fragment, useContext, useState } from "react";
import henceforthApi from "../../../utils/henceforthApi";
import { GlobalContext, handleError } from "../../../context/Provider";
import Spinner from "../../../components/common/spinner";
import Errormessage from "../../../components/common/errormessage";
import Svg from "../../../components/common/error-svg";
import BreadCrumb from "../../../components/common/BreadCrumb";
import { faqType } from "../productInterface";
import Inputs, { SaveCancelButton } from "../../../components/common/Inputs";
import commonArray from "../../../components/common/commonArray";

const AddProductFaq = () => {
  const match = useMatch("/product/:product_id/faq/add");
  const {
    language_data,
    authState,
    loading,
    setLoading,
    authDispatch,
    toastMessage,
  } = useContext(GlobalContext);
  let breadCrumbPath = [
    {
      name: `${language_data?.[authState.lang]?.common_home}`,
      url: `/`,
      active: "",
    },
    {
      name: `${language_data?.[authState.lang]?.common_product} ${
        language_data?.[authState.lang]?.common_list
      }`,
      url: `/products/1`,
      active: "",
    },
    {
      name: `${language_data?.[authState.lang]?.common_product} ${
        language_data?.[authState.lang]?.common_deatil
      }`,
      url: `/product/${match?.params.product_id}`,
      active: "",
    },
    {
      name: `${language_data?.[authState.lang]?.common_add} ${
        language_data?.[authState.lang]?.common_product
      } ${language_data?.[authState.lang]?.common_faq}`,
      url: ``,
      active: "not-allowed",
    },
  ];

  const [answerErr, setAnswerErr] = useState<string>("");
  const [questionErr, setQuestionErr] = useState<string>("");
  const [addFaq, setAddFaq] = useState<any>({
    product_id: match?.params?.product_id,
    question: "",
    answer: "",
  } as faqType);
  const handleChange = (e: any) => {
    let name = e?.target?.name ?? "answer";
    let value = e?.target?.value ?? e;
    if (name === "question") setQuestionErr("");
    if (name === "answer") setAnswerErr("");
    setAddFaq({
      ...addFaq,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!addFaq.answer && !addFaq.question) {
      setQuestionErr(
        `${language_data?.[authState.lang]?.common_question}${
          language_data?.[authState.lang]?.common_is
        }${language_data?.[authState.lang]?.common_required}`
      );
      setAnswerErr(
        `${language_data?.[authState.lang]?.common_answer}${
          language_data?.[authState.lang]?.common_is
        }${language_data?.[authState.lang]?.common_required}`
      );
      return;
    }
    if (addFaq.answer.trim() === "<p><br></p>" && !addFaq.question) {
      setQuestionErr(
        `${language_data?.[authState.lang]?.common_question}${
          language_data?.[authState.lang]?.common_is
        }${language_data?.[authState.lang]?.common_required}`
      );
      setAnswerErr(
        `${language_data?.[authState.lang]?.common_answer}${
          language_data?.[authState.lang]?.common_is
        }${language_data?.[authState.lang]?.common_required}`
      );
      return;
    }
    if (!addFaq.question)
      return setQuestionErr(
        `${language_data?.[authState.lang]?.common_question}${
          language_data?.[authState.lang]?.common_is
        }${language_data?.[authState.lang]?.common_required}`
      );
    if (!addFaq.question.trim())
      return setQuestionErr(
        `${language_data?.[authState.lang]?.common_please}${
          language_data?.[authState.lang]?.common_write
        }${language_data?.[authState.lang]?.common_something}${
          language_data?.[authState.lang]?.common_in
        }${language_data?.[authState.lang]?.common_question}`
      );
    if (addFaq.answer.trim() === "<p><br></p>")
      return setAnswerErr(
        `${language_data?.[authState.lang]?.common_answer}${
          language_data?.[authState.lang]?.common_is
        }${language_data?.[authState.lang]?.common_required}`
      );
    if (addFaq.answer.trim().length < 7 || addFaq.answer.trim() === "<p></p>")
      return setAnswerErr(
        `${language_data?.[authState.lang]?.common_answer}${
          language_data?.[authState.lang]?.common_is
        }${language_data?.[authState.lang]?.common_required}`
      );
    if (!addFaq.answer.trim())
      return setAnswerErr(
        `${language_data?.[authState.lang]?.common_please}${
          language_data?.[authState.lang]?.common_write
        }${language_data?.[authState.lang]?.common_something}${
          language_data?.[authState.lang]?.common_in
        }${language_data?.[authState.lang]?.common_answer}`
      );
    setLoading(true);
    try {
      let res = await henceforthApi.Product.addProductDetailsDynamically(
        commonArray.productOtherDetails[3],
        addFaq
      );
      toastMessage("FAQ Added Successfully");
      window.history.back();
    } catch (err) {
      handleError(err, "active", authDispatch);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <BreadCrumb pathNameDeclare={breadCrumbPath} />
      {/* page  */}
      <div className="page-spacing">
        <section className="product-detail">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-10 col-md-12">
                {/* Title  */}
                <div className="common-card">
                  <div className="common-card-title">
                    <h5>
                      {language_data?.[authState.lang]?.common_add}{" "}
                      {language_data?.[authState.lang]?.common_product}{" "}
                      {language_data?.[authState.lang]?.common_faq}
                    </h5>
                  </div>
                  {/* Profile  */}
                  <div className="common-card-content">
                    <form onSubmit={handleSubmit}>
                      {/* Question  */}
                      <Inputs.Input
                        type="text"
                        label={`${
                          language_data?.[authState.lang]?.common_question
                        }`}
                        error={questionErr}
                        value={addFaq.question}
                        placeholder={`${
                          language_data?.[authState.lang]?.common_add
                        } ${language_data?.[authState.lang]?.common_your} ${
                          language_data?.[authState.lang]?.common_question
                        } ${language_data?.[authState.lang]?.common_here}`}
                        name="question"
                        handleChange={handleChange}
                      />
                      {/* Answer  */}
                      <div className="text-editor mb-4">
                        <label className="mb-2 fw-bolder">
                          {language_data?.[authState.lang]?.common_answer}
                        </label>
                        <div
                          className={`quill ${
                            answerErr
                              ? "border border-danger mb-2 position-relative"
                              : ""
                          }`}
                        >
                          <TextEditor
                            value={addFaq.answer}
                            onChange={handleChange}
                          />
                          {answerErr ? (
                            <span className="svg-question">
                              <Svg />
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <Errormessage phone={answerErr} error={answerErr} />
                        {/* Button  */}
                        <SaveCancelButton
                          loading={loading}
                          color="text-white"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};
export default AddProductFaq;
