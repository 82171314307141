import '../../assets/styles/auth.scss'
import '../../assets/styles/pages.scss'
import { Link, useLocation, useMatch } from 'react-router-dom'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import DownloadFileModal from '../../components/common/download-file-modal'
import { GlobalContext, handleError } from '../../context/Provider'
import henceforthApi from '../../utils/henceforthApi'
import PaginationLayout from '../../components/common/PaginationLayout'
import Spinner from '../../components/common/spinner'
import moment from 'moment'
import BreadCrumb from '../../components/common/BreadCrumb'
import { numberWithCommas } from '../../utils/validations'
import { toast } from 'react-toastify'
import AllFilter from '../../components/common/AllFilter'
import COPY from "../../../src/assets/images/copy.png"
import { initLangData } from '../../utils/henceforthLanguage'
import AddCampaign from '../../components/common/AddCampaign'
import NODATA from '../../assets/images/no-data-found.svg'

const Campaign = () => {
    const match: any = useMatch('/campaign/:page');
    console.log(match,"match")
    const { authState, loading, setLoading, authDispatch, toastMessage, language_data } = useContext(GlobalContext)
    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_campaign} ${language_data?.[authState.lang]?.common_list}`, url: ``, active: 'not-allowed' }
    ]
    let Limit = 10;
    const location = useLocation();
    const newParam = new URLSearchParams(location.search);
    const [campaignList, setCampaignList] = useState({
        totalCount: 0,
        listing: [],
    } as any)

    const CampaignDuration = async (info: any) => {
        debugger
        if(!info)return toast.warning("Please enter minimum one day")
        if(info > 7) return toast.warning("Maximum days should not be more than 7")
        try {
            let payload = {
                max_campaign_duration: info
            }
            const Data = await henceforthApi.Campaign.CampaignDuration(payload)
            campaignListing();
            toastMessage('Duration Added Successfully');
            
        }
        catch (error) {
        }
    }

    const exportData = async (startDate: number, endDate: number) => {
        try {
            const apiRes = await henceforthApi.Campaign.CampaignExport(startDate, endDate)
            const data = apiRes.data.data
            const rows = [
                [
                    "Sr.",
                    "Campaign Id",
                    "Campaign Name",
                    "Campaign Status",
                    "User Joined",
                    "Quantity",
                    "Campaign Price",
                ],
            ];            
            if (Array.isArray(data)) {
                data.map((res: any, index: any) => {
                    return rows.push([
                        index + 1,
                        res?._id,
                        res?.campaign_name,
                        res?.status,
                        res?.user_joined,
                        res?.quantity,
                        `$${res?.total_price}`,
                    ])
                })
            }
            let csvContent = "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `campaign_${moment().valueOf()}.csv`);
            document.body.appendChild(link); // Required for FF
            link.click(); // This will download the data file named "my_data.csv".
            let closeModal = document.getElementById("closeModal");
            if (closeModal) {
                closeModal.click();
            }
        } catch (error) {
            handleError(error, 'active', authDispatch);
        }

    }
    const copyText = (id: string) => {
        if (id) {
            navigator?.clipboard?.writeText(id)
            toast.success(`${language_data?.[authState.lang]?.common_id} ${language_data?.[authState.lang]?.common_copy} ${language_data?.[authState.lang]?.common_successfull}`)
        }
    }
    const campaignListing = async () => {
        setLoading(true)
        try {
            let res = (await henceforthApi.Campaign.getCampaignList(Number(match?.params.page) - 1, Limit, newParam.toString())).data
            setCampaignList({
                ...campaignList,
                totalCount: res.count,
                listing: res.data,
            })
        } catch (err: any) {
            if (newParam.has('product_id')) {
                toast.warn(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_search} ${language_data?.[authState.lang]?.common_proper} ${language_data?.[authState.lang]?.common_id}`)
                setCampaignList({
                    ...campaignList,
                    listing: [],
                })
            }
            handleError(err, '', authDispatch);
        } finally {
            setLoading(false);
        }
    }
    const initLang = async () => {
        let langData = await initLangData('64466a7579e24437cade01c0', 'ENGLISH')
    }
    useEffect(() => {
        initLang()
    }, [])
    useEffect(() => {
        campaignListing();
    }, [match?.params.page, newParam.get("search"), newParam.get("product_id"), newParam.get("min_price"), newParam.get("max_price")])
    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* Page  */}
            <div className='page-spacing'>
                <section className='products'>
                    <div className='product-detail-box'>
                        <AllFilter />
                        <div className="common-card">
                            <div className="common-card-title d-flex justify-content-between align-items-center gap-2 flex-column flex-sm-row">
                                <h5>{language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_listing} {language_data?.[authState.lang]?.common_deatil}</h5>
                                <div>
                                    {/* <button className="btn btn-white btn-sm" type="button" data-bs-toggle="modal" data-bs-target="#compaignModal"> <i className="fa fa-plus me-1"></i>{language_data?.[authState.lang]?.common_add}</button> */}
                                    <button className="btn btn-white" type="button" data-bs-toggle="modal" data-bs-target="#AddDurationModal"> <i className='fa fa-plus me-2'></i>{language_data?.[authState.lang]?.common_add} {language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_duration}</button>
                                </div>
                            </div>
                            <div className="common-card-content">
                                {/* table */}
                                <div className='data-list-table table-responsive mb-3 text-center'>
                                    {loading ? <Spinner color={"text-success"} />
                                        :
                                        <table className="table table-striped align-middle text-center">
                                            <thead className=''>
                                                <tr>
                                                    <th>{language_data?.[authState.lang]?.common_sr_no}</th>
                                                    <th>{language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_id} </th>
                                                    <th>{language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_name}</th>
                                                    <th>{language_data?.[authState.lang]?.common_price}</th>
                                                    <th>{language_data?.[authState.lang]?.common_quantity} ({language_data?.[authState.lang]?.common_sold}/{language_data?.[authState.lang]?.common_available})</th>
                                                    <th>{language_data?.[authState.lang]?.common_group} {language_data?.[authState.lang]?.common_name}</th>
                                                    <th>{language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_duration}</th>
                                                    <th>{language_data?.[authState.lang]?.common_total} {language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_quantity}</th>
                                                    <th>{language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_status} </th>
                                                    <th>{language_data?.[authState.lang]?.common_user} {language_data?.[authState.lang]?.common_joined} </th>
                                                    <th>{language_data?.[authState.lang]?.common_maximum} {language_data?.[authState.lang]?.common_days}</th>
                                                    <th>{language_data?.[authState.lang]?.common_action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(campaignList.listing) && campaignList.listing.length ? campaignList.listing.map((res: any, index: number) =>
                                                    <tr key={res?._id}>
                                                        <td> {match?.params.page === "0" ? index + 1 : (Number(match?.params.page) - 1) * Limit + (index + 1)}</td>
                                                        <td>{res?._id ? res?._id : `${language_data?.[authState.lang]?.common_not_available}`} <img src={COPY} style={{ width: 15 }} onClick={() => copyText(res._id)} role="button" data-toggle="tooltip" title={`${res._id}`} /></td>
                                                        <td>{res?.campaign_name ? res?.campaign_name : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td><b>&#36;</b> {res?.total_price ? numberWithCommas(res?.total_price) : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td>{res?.total_quantity ? res?.total_quantity - res.quantity : `${language_data?.[authState.lang]?.common_not_available}`}/{res?.quantity ? res?.quantity : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td>{res?.group_id?.group_name ? res?.group_id?.group_name : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td>({res?.start_date ? moment(res?.start_date).format(`DD/MMM/YYYY`) : `${language_data?.[authState.lang]?.common_not_available}`} - {res?.end_date ? moment(res?.end_date).format(`DD/MMM/YYYY`) : `${language_data?.[authState.lang]?.common_not_available}`})</td>
                                                        <td>{res?.total_quantity !== 0 ? res?.total_quantity : `${language_data?.[authState.lang]?.common_out_of_stock}`}</td>
                                                        <td className={res.status === 'ONGOING' ? 'text-success' : 'text-danger'}>{res?.status !== 0 ? res?.status : `${language_data?.[authState.lang]?.common_out_of_stock}`}</td>
                                                        <td>{res?.user_joined !== 0 ? res?.user_joined : `${language_data?.[authState.lang]?.common_out_of_stock}`}</td>
                                                        <td>{res?.seller_id?.max_campaign_duration ? `${res?.seller_id?.max_campaign_duration} ${language_data?.[authState.lang]?.common_days}` : `${language_data?.[authState.lang]?.common_out_of_stock}`}</td>

                                                        <td>
                                                            <div className="btn-group gap-2">
                                                                <Link className="btn btn-white btn-sm" to={`/campaign/${res?._id}/view`}> <i className="fa fa-eye me-1"></i>{language_data?.[authState.lang]?.common_view}</Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) :
                                                    <tr><td className='text-center py-3' colSpan={12}><img src={NODATA} width="100" /><p className='text-center mt-3'>{language_data?.[authState.lang]?.common_no_data_found}</p></td></tr>
                                                }
                                            </tbody>
                                        </table>}
                                </div>
                                {/* pagination  */}
                                <div className='dashboad-pagination-box'>
                                    <PaginationLayout
                                        data={campaignList.listing}
                                        count={campaignList.totalCount}
                                        limit={Number(Limit)}
                                        page={Number(match?.params.page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AddCampaign AddDurationModal={useMemo(() => CampaignDuration, [])} />
            <DownloadFileModal exportData={useMemo(() => exportData, [])} />
        </Fragment>
    )
}
export default Campaign;
