import { Image } from "antd"
import Bannerimg from '../assets/images/pages/product-3.jpg'
import { Link } from "react-router-dom"

const TermsActivity = () => {

    return (
        <>
            <div className=' page-spacing'>
                <section className='terms'>
                    <div className="common-card">
                        <div className="common-card-title">
                            <h2>Terms </h2>
                        </div>

                        <div className="common-card-content">
                            <div className="terms-banner mb-3">
                                <Image src={Bannerimg} alt="img" />
                            </div>
                            <div className="mb-3">
                                <h5 className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus maiores odit optio earum in architecto vero magnam aliquam veniam laudantium?</h5>
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempore ducimus neque optio nemo inventore facilis porro quam. Maxime aliquam voluptates explicabo, cumque dignissimos optio amet itaque reprehenderit quis eveniet nulla pariatur accusantium culpa vero velit consequuntur dicta expedita corrupti! Vero perspiciatis expedita repudiandae sit aliquam repellendus, libero dolor omnis dolore!</p>
                            </div>
                            <div className="mb-3">
                                <h5 className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus maiores odit optio earum in architecto vero magnam aliquam veniam laudantium?</h5>
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempore ducimus neque optio nemo inventore facilis porro quam. Maxime aliquam voluptates explicabo, cumque dignissimos optio amet itaque reprehenderit quis eveniet nulla pariatur accusantium culpa vero velit consequuntur dicta expedita corrupti! Vero perspiciatis expedita repudiandae sit aliquam repellendus, libero dolor omnis dolore!</p>
                            </div>
                            <div className="mb-3">
                                <h5 className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus maiores odit optio earum in architecto vero magnam aliquam veniam laudantium?</h5>
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempore ducimus neque optio nemo inventore facilis porro quam. Maxime aliquam voluptates explicabo, cumque dignissimos optio amet itaque reprehenderit quis eveniet nulla pariatur accusantium culpa vero velit consequuntur dicta expedita corrupti! Vero perspiciatis expedita repudiandae sit aliquam repellendus, libero dolor omnis dolore!</p>
                            </div>
                        </div>

                    </div>
                </section>
            </div>



        </>
    )
}

export default TermsActivity