import React, { Fragment, useEffect } from "react"
import { useMatch, useNavigate } from "react-router-dom"
import loginSuccess from "../context/actions/auth/loginSuccess"
import { GlobalContext, handleError } from "../context/Provider"
import { requestNotification } from "../utils/firebase"
import henceforthApi from "../utils/henceforthApi"
import henceofrthEnums from "../utils/henceofrthEnums"

export default () => {
    const navigate = useNavigate()
    const match = useMatch("login-with-user/:code/:access_token")

    const { authDispatch } = React.useContext(GlobalContext);

    const loginAsUser = async () => {
        if (match?.params.code) {
            const fcmid = await requestNotification()
            const items = {
                _id: match?.params.code,
                language: "ENGLISH"
            } as any
            if (fcmid) items['fcm_token'] = fcmid

            henceforthApi.setToken(match.params.access_token)
            try {
                const apiRes = await henceforthApi.Auth.loginAsUser(items)
                loginSuccess({ ...apiRes.data, lang: henceofrthEnums.Language.ENGLISH })(authDispatch);
                henceforthApi.setToken(apiRes.data.access_token)
                henceforthApi.languageChange(henceofrthEnums.Language.ENGLISH)
                navigate('/')
            } catch (err) {
                handleError(err, 'active', authDispatch);
            }
        }
    }

    useEffect(() => {
        loginAsUser()
    }, [match?.params.code])

    return <Fragment></Fragment>
}