import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { Fragment, useContext } from 'react';
import { GlobalContext } from '../../context/Provider';
import { dataType } from '../../pages/order/orderInterface';



Font.register({
    family: 'bold',
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
    fontWeight: 'bold'
});

Font.register({
    family: 'italic',
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-mediumitalic-webfont.ttf",
});
Font.register({
    family: 'italic-regular',
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf",
});

Font.register({
    family: 'medium',
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});


// Create styles
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    w_100: {
        width: '100%',
    },

});
const InvoiceTable = ({ dataHolder,authState, language_data }: dataType) => {
    

    return (
        <Fragment>
            {/* Table Heading  */}
            <View style={styles.container}>
                {/* 1  */}
                <View style={{ width: '18%' }}>
                    <Text style={{ fontFamily: 'bold' }}>{language_data?.[authState.lang]?.common_product}</Text>
                </View>
                {/* 2  */}
                <View style={{ width: '27%' }}>
                    <Text style={{ fontFamily: 'bold' }}>{language_data?.[authState.lang]?.common_title}</Text>
                </View>
                {/* 3  */}
                <View style={{ width: '10%' }}>
                    <Text style={{ fontFamily: 'bold' }}>{language_data?.[authState.lang]?.common_qty}</Text>
                </View>
                {/* 4  */}
                <View style={{ width: '10%' }}>
                    <Text style={{ fontFamily: 'bold' }}>{language_data?.[authState.lang]?.common_gross}</Text>
                    <Text style={{ fontFamily: 'bold' }}>{language_data?.[authState.lang]?.common_amount} $</Text>
                </View>
                {/* 5  */}
                <View style={{ width: '10%' }}>
                    <Text style={{ fontFamily: 'bold' }}>{language_data?.[authState.lang]?.common_discount}</Text>
                    <Text style={{ fontFamily: 'bold' }}>/{language_data?.[authState.lang]?.common_coupons} $</Text>
                </View>
                {/* 6  */}
                <View style={{ width: '10%' }}>
                    <Text style={{ fontFamily: 'bold' }}>{language_data?.[authState.lang]?.common_taxable}</Text>
                    <Text style={{ fontFamily: 'bold' }}>{language_data?.[authState.lang]?.common_value} $</Text>
                </View>
                {/* 7  */}
                <View style={{ width: '10%' }}>
                    <Text style={{ fontFamily: 'bold' }}>{language_data?.[authState.lang]?.common_tax} $</Text>
                </View>
                {/* 8  */}
                <View style={{ width: '5%' }}>
                    <Text style={{ fontFamily: 'bold', textAlign: 'right' }}>{language_data?.[authState.lang]?.common_total}</Text>
                    <Text style={{ fontFamily: 'bold', textAlign: 'right' }}>{language_data?.[authState.lang]?.common_earning} $</Text>
                </View>
            </View>

            <View style={{ borderBottom: '1px solid #000', margin: '10px 0 10px' }}></View>

            {/* Order Price  */}
            <View style={styles.container}>
                {/* 1  */}
                <View style={{ width: '18%' }}>
                    <Text>{dataHolder?.product_id?.subcategory?.name ? dataHolder?.product_id?.subcategory?.name : `${language_data?.[authState.lang]?.common_not_available}`}</Text>
                    <Text>{dataHolder?.product_id?.prod_id ? dataHolder?.product_id?.prod_id : `${language_data?.[authState.lang]?.common_not_available}`}</Text>
                </View>
                {/* 2  */}
                <View style={{ width: '27%' }}>
                    <Text style={{ fontFamily: 'bold' }}>{dataHolder?.product_id?.name ? dataHolder?.product_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</Text>
                    {dataHolder?.product_id?.services.map((res) => <Text style={{}} key={res?._id}>{res?.content}</Text>)}
                    <View style={[styles.container, { marginTop: 4 }]}>
                        <Text style={{ fontFamily: 'bold', marginRight: 3 }}>{language_data?.[authState.lang]?.common_tax}:</Text>
                        <Text style={{}}>{dataHolder?.tax_percantage ? `${dataHolder?.tax_percantage}%` : `${language_data?.[authState.lang]?.common_appicable}`}</Text>
                    </View>
                </View>
                {/* 3  */}
                <View style={{ width: '10%' }}>
                    <Text style={{}}>{dataHolder?.quantity ? dataHolder?.quantity : `${language_data?.[authState.lang]?.common_out_of_stock}`}</Text>
                </View>
                {/* 4  */}
                <View style={{ width: '10%' }}>
                    <Text style={{}}>{dataHolder?.total_price ? dataHolder?.total_price : '0'}</Text>
                </View>
                {/* 5  */}
                <View style={{ width: '10%' }}>
                    <Text style={{}}>{dataHolder?.coupon_discount ? -dataHolder?.coupon_discount : '0'}</Text>
                </View>
                {/* 6  */}
                <View style={{ width: '10%' }}>
                    <Text style={{}}>{(dataHolder?.total_price - dataHolder?.tax_amount)?.toFixed(2)}</Text>
                </View>
                {/* 7  */}
                <View style={{ width: '10%' }}>
                    <Text style={{}}>{dataHolder?.tax_amount?.toFixed(2)}</Text>
                </View>
                {/* 8  */}
                <View style={{ width: '5%' }}>
                    <Text style={{ textAlign: 'right' }}>{dataHolder?.your_earning ? dataHolder?.your_earning : '0'}</Text>
                </View>
            </View>

            {/* Shipping And Convenience Charges */}
            <View style={[styles.container, { margin: '10px 0' }]}>
                {/* 1  */}
                <View style={{ width: '18%' }}></View>
                {/* 2  */}
                <View style={{ width: '27%' }}>
                    <Text style={{ fontFamily: 'bold' }}>{language_data?.[authState.lang]?.common_shopping} {language_data?.[authState.lang]?.common_and} {language_data?.[authState.lang]?.common_convenience} {language_data?.[authState.lang]?.common_charges}</Text>
                </View>
                {/* 3  */}
                <View style={{ width: '10%' }}>
                    <Text style={{}}>{dataHolder?.quantity ? dataHolder?.quantity : `${language_data?.[authState.lang]?.common_not_available}`}</Text>
                </View>
                {/* 4  */}
                <View style={{ width: '10%' }}>
                    <Text style={{}}>{dataHolder?.delivery_price ? dataHolder?.delivery_price : '0'}</Text>
                </View>
            </View>



            <View style={{ borderBottom: '1px solid #000', margin: '5px 0 10px' }}></View>

            <View style={styles.container}>
                <Text style={{ width: '18%' }}></Text>
                <Text style={{ width: '27%', fontFamily: 'bold' }}>{language_data?.[authState.lang]?.common_total}</Text>
                <Text style={{ width: '10%', fontFamily: 'bold' }}>{dataHolder?.quantity ? dataHolder?.quantity : `${language_data?.[authState.lang]?.common_not_available}`}</Text>
                <Text style={{ width: '10%', fontFamily: 'bold' }}>{dataHolder?.total_price ? dataHolder?.total_price + dataHolder?.delivery_price : '0'}</Text>
                <Text style={{ width: '10%', fontFamily: 'bold' }}>{dataHolder?.coupon_discount ? -dataHolder?.coupon_discount : '0'}</Text>
                <Text style={{ width: '10%', fontFamily: 'bold' }}>{(dataHolder?.total_price - dataHolder?.tax_amount)?.toFixed(2)}</Text>
                <Text style={{ width: '10%', fontFamily: 'bold' }}>{dataHolder?.tax_amount?.toFixed(2)}</Text>
                <Text style={{ width: '5%', fontFamily: 'bold', textAlign: 'right' }}>{dataHolder?.your_earning}</Text>
            </View>

            <View style={{ borderBottom: '1px solid #000', margin: '12px 0 12px' }}></View>
            <View style={[styles.container, {}]}>
                <View style={{ width: "75%" }}></View>
                <View style={{ width: "25%" }}>
                    <View style={[styles.container, {}]}>
                        <Text style={{ fontSize: 10, width: '50%', fontFamily: 'medium' }}>{language_data?.[authState.lang]?.common_grand} {language_data?.[authState.lang]?.common_total} {language_data?.[authState.lang]?.common_earning}</Text>
                        <Text style={{ fontSize: 10, width: '50%', textAlign: 'right', fontFamily: 'bold' }}>$ {dataHolder?.your_earning}</Text>
                    </View>
                    <View style={[styles.container, {}]}>
                        <Text style={{ fontSize: 10, width: '50%', fontFamily: 'medium' }}>{language_data?.[authState.lang]?.common_grand} {language_data?.[authState.lang]?.common_total} {language_data?.[authState.lang]?.common_price}</Text>
                        <Text style={{ fontSize: 10, width: '50%', textAlign: 'right', fontFamily: 'bold' }}>$ {dataHolder?.total_price + dataHolder?.delivery_price - dataHolder?.coupon_discount}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.container}>
                <Text style={[styles.w_100, { textAlign: 'right', fontFamily: 'medium', marginTop: 5 }]}>{dataHolder?.seller_id?.company ? dataHolder?.seller_id?.company : `${language_data?.[authState.lang]?.common_not_available}`}</Text>
            </View>
        </Fragment>
    )
}








export default InvoiceTable;