import { Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { Fragment, useContext } from 'react';
import logo from '../../assets/images/logo/logo.png'
import { GlobalContext } from '../../context/Provider';

Font.register({
    family: 'bold',
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
    fontWeight: 'bold'
});

Font.register({
    family: 'medium',
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});


// Create styles
const styles = StyleSheet.create({

    container: {
        flexDirection: 'row',
    },

    w_100: {
        width: '100%',
    },
    logo: {
        maxWidth: '100px',
        // height: '100%',
        objectFit: 'contain',
    }
});
const InvoiceFooter = ({ data }: any) => {
    const { authState, language_data } = useContext(GlobalContext)

    return (
        <Fragment>
            <View style={styles.container}>
                <Text style={[styles.w_100, { textAlign: 'right', margin: '20px 0 0' }]}>{language_data?.[authState.lang]?.common_authorized} {language_data?.[authState.lang]?.common_signatory}</Text>
            </View>
            <View style={{ borderBottom: '1px solid #000', margin: '5px 0 10px' }}></View>

            <View style={[styles.container, { margin: '40px 0' }]}>
                <View style={{ width: '80%',margin:'auto' }}>
                    <Image src={logo} style={styles.logo}></Image>
                    <Text style={{ marginTop: 5 }}>{language_data?.[authState.lang]?.common_contact} {language_data?.[authState.lang]?.common_henceforth} {language_data?.[authState.lang]?.common_commerce}: {language_data?.[authState.lang]?.common_18002002009000_} || www.quantitysavers.com</Text>
                </View>
                <View style={{ width: '20%' }}>
                    <Text style={{ textAlign: 'right', fontSize: 13 }}>{language_data?.[authState.lang]?.common_not_more_than} </Text>
                    <Text style={{ textAlign: 'right', marginTop: 5 }}>{language_data?.[authState.lang]?.common_for} {language_data?.[authState.lang]?.common_shopping} {language_data?.[authState.lang]?.common_with} {language_data?.[authState.lang]?.common_us}</Text>
                </View>
            </View>
        </Fragment>
    )
}








export default InvoiceFooter;