import { useContext } from "react";
import { GlobalContext } from "../../context/Provider";
import Errormessage from "./errormessage";

export default (props: any) => {
    const {language_data, authState, } = useContext(GlobalContext);
    return <div className="form-select-box mb-3">
        <label htmlFor={props.id} className='fw-bolder mb-1'>{props.label}</label>
        <select className={`form-select shadow-none${props.error ? ' is-invalid' : ''}`} aria-label=".form-select-sm example" name={props.name}
            onChange={(e: any) => {props?.handleChange(e) }} value={props.value} style={{color:'inherit'}} disabled={props.disabled} >
            <option value="">{language_data?.[authState.lang]?.common_select} {language_data?.[authState.lang]?.common_your} {props.firstSelect}</option>
            {Array.isArray(props.Array) && [...props.Array].map((res:any, index: number) => {
                return (
                    <option key={index} value={res?._id}>{res?.name}</option>
                )
            })}
        </select>
      {props.error && <Errormessage class={props.error} error={props.error} />}
    </div>
}