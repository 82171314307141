import profile_img1 from '../../assets/images/pages/laptop.jpg';
import profile_img from '../../assets/images/pages/defaultImage.jpg';
import '../../assets/styles/pages.scss'
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom'
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { GlobalContext, handleError } from '../../context/Provider';
import henceforthApi from '../../utils/henceforthApi';
import Spinner from '../../components/common/spinner';
import Swal from 'sweetalert2';
import BreadCrumb from '../../components/common/BreadCrumb';
import { numberWithCommas } from '../../utils/validations';
import henceofrthEnums from '../../utils/henceofrthEnums';
import OrderStatus from '../../components/order/OrderStatus';
import { toast } from 'react-toastify';
import OrderStatusAction from '../../components/order/OrderStatusAction';
import Ratings from '../../components/common/Ratings';

const CampaignRequestDetail = () => {
    const { language_data, loading, setLoading, authState, authDispatch } = React.useContext(GlobalContext)
    const match = useMatch('/campaign-requests/:id/view')
    const location = useLocation()
    const newParam = new URLSearchParams(location.search)
    let breadCrumbPath = [
        { name: ` ${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: newParam.has('transaction') ? `${language_data?.[authState.lang]?.common_all} ${language_data?.[authState.lang]?.common_transaction}` : `${language_data?.[authState.lang]?.common_campaign} ${language_data?.[authState.lang]?.common_request} ${language_data?.[authState.lang]?.common_list}`, url: newParam.has('transaction') ? '/earnings/1' : `/orders/1`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_order} ${language_data?.[authState.lang]?.common_deatil}`, url: ``, active: 'not-allowed' },
    ]
    const naviagte = useNavigate();
    const [tracking, setTracking] = React.useState("")
    const [requestD, setRequestD] = useState({} as any)
    const [showMore, setShowMore] = useState(false)
    const requestDetail = async () => {
        setLoading(true)
        try {
            let res = (await henceforthApi.Campaign.getRequestDetails(match?.params.id)).data
            setRequestD(res)
        } catch (err: any) {
            handleError(err, '', authDispatch);
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        requestDetail()
    }, [match?.params.id])

    const acceptRejectRequest = (type: string) => {
        Swal.fire({
            title: `${language_data?.[authState.lang]?.common_are_you_sure}`,
            text: `${language_data?.[authState.lang]?.common_are_sure_want_to} ${type === 'ACCEPTED' ? `${language_data?.[authState.lang]?.common_accept}` : `${language_data?.[authState.lang]?.common_reject}`} ${language_data?.[authState.lang]?.common_this} ${language_data?.[authState.lang]?.common_request}!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: `${language_data?.[authState.lang]?.common_cancel}`,
            confirmButtonText: `${language_data?.[authState.lang]?.common_yes} ${type === 'ACCEPTED' ? `${language_data?.[authState.lang]?.common_accept}` : `${language_data?.[authState.lang]?.common_reject}`} ${language_data?.[authState.lang]?.common_it}`
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = {
                        type: type
                    }
                    await henceforthApi.Campaign.AcceptRejectRequest(
                        match?.params.id,
                        data
                    )
                    requestDetail()
                    Swal.fire({
                        icon: 'success',
                        title: `${type === 'ACCEPTED' ? `${language_data?.[authState.lang]?.common_accept}` : `${language_data?.[authState.lang]?.common_reject}`} ${language_data?.[authState.lang]?.common_request}`,
                        text: `${type === 'ACCEPTED' ? `${language_data?.[authState.lang]?.common_accept}` : `${language_data?.[authState.lang]?.common_reject}`} ${language_data?.[authState.lang]?.common_request}.`,
                        timer: 2000,
                        showCancelButton: false,
                        showConfirmButton: false
                    }
                    )
                    naviagte('/campaign-requests/1')
                } catch (err: any) {
                    handleError(err, 'active', authDispatch);
                }
            }
        })
    }

    return (
        <Fragment>
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            {loading ? <div className='vh-100 d-flex justify-content-center py-5'>
                <Spinner color={'text-success'} />
            </div> : <div className='page-spacing'>
                <section className='product-detail'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                {/* Title  */}
                                <div className="common-card">
                                    <div className="common-card-title">
                                        <div className='d-flex mb-2 flex-column flex-md-row product-detail-params align-items-center justify-content-md-between'>
                                            <h5 className='mb-2 m-0 mb-md-0'>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_deatil}</h5>
                                            <div className="d-flex gap-2">
                                                <button type="button" onClick={() => acceptRejectRequest('ACCEPTED')} className="btn btn-success btn-sm"> <i className="me-1"></i>{language_data?.[authState.lang]?.common_request} {language_data?.[authState.lang]?.common_accept}</button>
                                                <button type="button" onClick={() => acceptRejectRequest('REJECTED')} className="btn btn-danger btn-sm"> <i className="me-1"></i>{language_data?.[authState.lang]?.common_request} {language_data?.[authState.lang]?.common_reject}</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Profile  */}
                                    <div className="common-card-content">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                                                    <div className="carousel-indicators">
                                                        {Array.isArray(requestD?.product_id?.images) && requestD?.product_id?.images?.map((res: string, index: number) => {
                                                            return (
                                                                <button type="button" key={index} data-bs-target='#carouselExampleIndicators' data-bs-slide-to={index} className={`${index === 0 ? "active" : ""} ${requestD?.product_id?.images.length === 1 ? "d-none" : ""}`} aria-current="true" aria-label={`Slide ${index + 1}`}></button>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="carousel-inner product-images">
                                                        {Array.isArray(requestD?.product_id?.images) && requestD?.product_id?.images?.map((res: string, index: number) => {
                                                            return (
                                                                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                                                                    <img src={res ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${res}` : profile_img1} className="d-block w-100" alt="img" />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                        <span className="visually-hidden">{language_data?.[authState.lang]?.common_previous}</span>
                                                    </button>
                                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                        <span className="visually-hidden">{language_data?.[authState.lang]?.common_next}</span>
                                                    </button>
                                                </div>
                                            </div>
                                            {/* Product detail  */}
                                            <div className="col-md-7">
                                                <div className="product-detail-box">
                                                    <Link className='text-black text-decoration-none text-capitalize' to={`/product/${requestD?.product_id?._id}`}> <h2 className='fw-bolder'>{requestD?.product_id?.name ? requestD?.product_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</h2></Link>
                                                    <div className='d-flex align-items-center gap-1'>
                                                        <h2 className='fw-semibold m-0'>&#36; {requestD?.product_id?.price ? numberWithCommas(requestD?.product_id?.price) : `${language_data?.[authState.lang]?.common_not_available}`}</h2><small> {language_data?.[authState.lang]?.common_including_tax}</small>
                                                    </div>
                                                    <div className="divider my-3"></div>
                                                    <div className='mb-3'>
                                                        <h4 className='fw-bolder'>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_description}</h4>
                                                        <p className='fw-semibold text-capitalize'>{requestD?.product_id?.description ? `${requestD?.product_id?.description.length > 1000 ? !showMore ? `${requestD?.product_id?.description?.slice(1000)}` : requestD?.product_id?.description : requestD?.product_id?.description}` : `${language_data?.[authState.lang]?.common_not_available}`}</p>
                                                        {requestD?.product_id?.description?.length > 1000 ? <p className='ReadMore' role="button" onClick={() => setShowMore(!showMore)}>{!showMore ? `${language_data?.[authState.lang]?.common_read_more}` : `${language_data?.[authState.lang]?.common_read_less}`} </p> : ''} </div>
                                                    <ul className='list-unstyled product-detail-list'>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_id}:</b><span>{requestD?.product_id?.prodct_id ? requestD?.product_id?.prodct_id : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_name}:</b><span>{requestD?.product_id?.name ? requestD?.product_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_price}:</b><span>&#36; {requestD?.product_id?.price ? numberWithCommas(requestD?.product_id?.price) : `${language_data?.[authState.lang]?._0}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_brand} :</b><span>{requestD?.product_id?.brand_id?.name ? requestD?.product_id?.brand_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_category}:</b><span>{requestD?.product_id?.category_id?.name ? requestD?.product_id?.category_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_sub_category}:</b><span>{requestD?.product_id?.subcategory_id?.name ? requestD?.product_id?.subcategory_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_sub_sub_category}:</b><span>{requestD?.product_id?.sub_subcategory_id?.name ? requestD?.product_id?.sub_subcategory_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_wholesale} {language_data?.[authState.lang]?.common_quantity} </b><span>{requestD?.product_id?.wholesale_quntity ? requestD?.product_id?.wholesale_quntity : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_wholesale} {language_data?.[authState.lang]?.common_price} </b><span>{requestD?.product_id?.wholesale_price ? requestD?.product_id?.wholesale_price : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_quantity} </b><span>{requestD?.product_id?.campaign_quantity ? requestD?.product_id?.campaign_quantity : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex mb-2 flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_create} {language_data?.[authState.lang]?.common_type} </b><span>{requestD?.product_id?.campaign_create_type ? requestD?.product_id?.campaign_create_type : `${language_data?.[authState.lang]?.common_not_available}`}</span></li>
                                                        <li className="d-flex flex-column flex-sm-row product-detail-params"><b>{language_data?.[authState.lang]?.common_tax} {language_data?.[authState.lang]?.common_percentage}</b><span>{requestD?.product_id?.tax_percentage ? requestD?.product_id?.tax_percentage : `${language_data?.[authState.lang]?._0}`}%</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>}
        </Fragment>
    )
}
export default CampaignRequestDetail;