import { SetStateAction } from "react"

type ToastFunction = (msg: string) => void
type handleFunction = (name: string, value: string) => void
type paginationChange = (page: number) => void
type onFilterPriceHandler = (min_price: string, max_price: string) => void
export interface LanguageKeys {
    _0: string,
    _1: string,
    _2: string,
    _3: string,
    _4: string,
    _5: string,
    _6: string,
    _7: string,
    _8: string,
    _9: string,
    header_welcome_to_henceForth_ecommerce_seller_panel: string,
    sidebar_order_allorders: string,
    sidebar_order_campaignorders: string,
    common_dashboard: string,
    sidebar_order_order_cancellation_request: string,
    common_product: string,
    common_order: string,
    common_rating_reviews: string,
    common_campaign: string,
    common_group: string,
    common_created_at: string,
    common_members: string,
    common_visibility: string,
    common_joined: string,
    common_duration: string,
    common_country: string,
    common_world: string,
    common_over: string,
    common_within: string
    common_coupons: string,
    common_earning: string,
    common_logout: string,
    common_profile: string,
    common_change_password: string,
    common_mark_all_as_read: string,
    common_clear: string,
    common_nothing_new_for_you: string,
    common_product_graph: string,
    common_sales_graph: string,
    common_daily: string,
    common_count: string,
    common_out_of_stock: string,
    common_alert_of_stock: string,
    common_total: string,
    common_rating: string,
    home_that_are: string,
    home_that_will_soon_go_out_of_that_ie_quantity_less_then: string,
    home_addition_of_all_order_pricing: string,
    home_total_product: string,
    home_total_orders: string,
    common_monthly: string,
    common_creater: string
    common_weekly: string,
    common_yearly: string,
    common_search: string,
    common_listing: string,
    common_deatil: string,
    common_sr_no: string,
    common_id: string,
    common_category_level: string,
    common_brand: string,
    common_price: string,
    common_action: string,
    common_quantity: string,
    common_wholesale: string
    common_organiser: string
    common_add: string,
    common_filter: string
    common_filters: string
    common_by: string,
    common_min: string,
    common_max: string,
    common_submit: string,
    common_all: string,
    common_status: string,
    commono_start_to_end_date: string,
    common_export_file: string,
    common_date: string,
    common_clone: string,
    common_delete: string,
    common_edit: string,
    common_previous: string,
    common_next: string,
    common_description: string,
    common_including_tax: string,
    common_not_available: string,
    common_read_more: string,
    common_read_less: string,
    common_discount: string,
    common_tax: string,
    common_highlights: string,
    common_specifications: string,
    common_variations: string,
    common_images: string,
    common_name: string,
    common_services: string,
    common_forum: string,
    common_delivery: string,
    common_radius: string,
    common_time: string,
    common_location: string,
    common_faq: string,
    common_customer: string,
    common_coupon: string,
    common_home: string,
    common_list: string,
    common_note: string,
    common_please_upload_only_jpg_and_png_format_only: string,
    common_percentage: string,
    common_enter_your: string,
    common_enter: string,
    common_key: string,
    common_value: string,
    common_select: string,
    common_your: string,
    common_role: string,
    common_transaction: string,
    common_download_seller_invoice: string,
    common_canceled: string,
    common_accept_cancellation_request: string,
    common_cancel: string,
    common_user: string,
    common_reason: string,
    common_accept: string,
    common_breakdown: string,
    common_final: string,
    common_platform: string,
    common_cancellation: string,
    common_view: string,
    common_no_data_found: string,
    common_coupon_valid_for_selected_product: string,
    common_type: string,
    common_sub_type: string,
    common_start_date: string,
    common_end_date: string,
    common_one: string,
    common_fixed: string,
    common_save: string,
    common_not_required: string,
    common_amount: string,
    common_stock: string,
    common_of: string,
    common_alert: string,
    common_confirmed: string,
    common_cancelled: string,
    common_delivered: string,
    common_shipped: string,
    common_refund: string,
    common_refunded: string,
    common_in_progress: string,
    common_answer: string,
    common_question: string,
    common_here: string,
    common_variant: string,
    common_only_one_variant_can_be_added: string,
    common_not_more_than: string,
    common_are_you_sure: string,
    common_yes_delete_it: string,
    common_want_to_delete_this: string,
    common_want_to: string,
    common_are_sure_want_to: string,
    common_this: string,
    common_questionmark: string,
    common_deleted: string,
    common_your_coupon_has_been: string,
    common_success: string,
    common_yes: string,
    common_no: string,
    common_you_want_to: string,
    common_your_delivery_step_has_been_changed_into: string,
    common_request: string,
    common_you_have_accepted: string,
    common_change_in: string,
    common_it: string,
    common_has_been_deleted: string,
    common_comment: string,
    common_for: string,
    common_should_be_greater_than: string,
    common_password: string,
    common_changed: string,
    common_fees: string,
    common_successfully: string,
    common_old: string,
    common_new: string,
    common_login: string,
    common_singup: string,
    common_register: string,
    common_to: string,
    common_create_account_to_see_it_in_action: string,
    common_email: string,
    common_agree: string,
    common_the: string,
    common_terms: string,
    common_and: string,
    common_condition: string,
    common_already_have_an_account: string,
    common_signin: string,
    common_henceforth: string,
    common_verify: string,
    common_didnot_receive_any_code: string,
    common_resend: string,
    common_code: string,
    common_already_: string,
    common_added_: string,
    common_please_enter_tracking_id_or_link: string,
    common_successfull: string,
    common_copy: string,
    common_please: string,
    common_proper: string,
    common_you_have_selected: string,
    common_review: string,
    common_my: string,
    common_copyright: string,
    common_ecommerce_seller_panel: string,
    common_all_rights_reserved: string,
    common_proceed_to_delivered: string,
    common_proceed_to_shipped: string,
    common_see_it_in_action: string,
    common_forget: string,
    common_do_not_have_an_account: string,
    common_required: string,
    common_is: string,
    common_please_enter_a_valid_password: string,
    common_must_be_atleast: string,
    common_characters: string,
    common_long: string,
    common_contain: string,
    common_uppercase: string,
    common_and_: string,
    common_lowecase: string,
    common_with: string,
    common_special: string,
    common_numbers: string,
    common_does_not: string,
    common_match: string,
    common_must_be: string,
    common_different: string,
    common_from: string,
    common_city: string,
    common_phone: string,
    common_company: string,
    common_pin_code: string,
    common_full: string,
    common_address: string,
    common_state: string,
    common_provide: string,
    common_valid: string,
    common_fill: string,
    common_verification: string,
    common_otp: string,
    common_did_not: string,
    common_recieve: string,
    common_same: string,
    common_reset: string,
    common_image: string,
    common_category: string,
    common_sub_category: string,
    common_only: string,
    common_use: string,
    common_number: string,
    common_in: string,
    common_highlight: string,
    common_atleast: string,
    common_write: string,
    common_something: string,
    common_linking: string,
    common_now: string,
    common_notification: string,
    common_comments: string,
    common_please_add_delivery_if_you_want_to_show_your_product_in_marketplace: string,
    common_nearest: string,
    common_place: string,
    common_again: string,
    common_Apartment: string,
    common_download: string,
    common_records: string,
    common_loading: string,
    common_you: string,
    common_invoice: string,
    common_bill: string,
    common_ship: string,
    common_manufacturer: string,
    common_box: string,
    common_warranty: string,
    common_purpose: string,
    common_item: string,
    common_available: string
    common_keep: string,
    common_contact: string,
    common_commerce: string,
    common_18002002009000_: string,
    common_thank_you: string,
    common_shopping: string,
    common_us: string,
    common_authorized: string,
    common_signatory: string,
    common_sold: string,
    common_title: string,
    common_qty: string,
    common_gross: string,
    common_convenience: string,
    common_charges: string,
    common_taxable: string,
    common_appicable: string,
    common_shipping: string,
    common_grand: string,
    common_mainactivity: string,
    common_create: string
    common_maximum: string,
    common_days: string,
    common_reject: string,
    common_pending:string
    common_sub_sub_category: string
    common_delivery_all_over_world: string,
}
export interface CommonContextType {
    loading: boolean,
    language_data: { [keys: string | 'ENGLISH' | 'ARABIC']: LanguageKeys },
    setLoading: React.Dispatch<SetStateAction<boolean>>,
    authState: { access_token: string, lang: string, name: string, email: string, phone_number: any, image: string, country_code: string, company: string, state: string, city: string, pin_code: string, apartment_number: string, full_address: string, email_verified: boolean },
    authDispatch: any,
    logOutNow: Function,
    toastMessage: ToastFunction,
    handleSearch: handleFunction,
    onChangePagination: paginationChange,
    onFilterPriceHandler: onFilterPriceHandler,
}
export const language = {
    ENGLISH: {
        _0: "0",
        _1: "1",
        _2: "2",
        _3: "3",
        _4: "4",
        _5: "5",
        _6: "6",
        _7: '7',
        _8: '8',
        _9: '9',
        //***** Header******
        header_welcome_to_henceForth_ecommerce_seller_panel: "Welcome to Quantity Savers Seller Panel",
        //***** Sidebar******
        sidebar_order_allorders: "All Orders",
        sidebar_order_campaignorders: "Campaign Orders",
        sidebar_order_order_cancellation_request: "Order Cancellation Request",
        //***** Home******
        home_that_are: "That Are",
        home_that_will_soon_go_out_of_that_ie_quantity_less_then: "That will soon go out of that ie quantity less then",
        home_addition_of_all_order_pricing: "Addition of all order pricing",
        home_total_product: "Total_product",
        home_total_orders: "Total_product",
        //***** Common******
        common_dashboard: "Dashboard",
        common_sub_sub_category: "SubSubcategory",
        common_product: "Product",
        common_order: "Order",
        common_pending:"Pending",
        common_rating_reviews: "Ratings & Reviews",
        common_campaign: "Campaign",
        common_members: "Members",
        common_group: "Group",
        common_created_at: "Created At",
        common_visibility: "Visibility",
        common_joined: "Joined",
        common_duration: "Duration",
        common_reject: 'Reject',
        common_coupons: "Coupons",
        common_earning: "Earning",
        common_fees: "Fee",
        common_logout: "Log Out",
        common_profile: "Profile",
        common_change_password: "Change Password",
        common_mark_all_as_read: "Mark all as read",
        common_clear: "Clear",
        common_nothing_new_for_you: "Nothing new for you",
        common_product_graph: "Product graph",
        common_sales_graph: "Sales graph",
        common_daily: "Daily",
        common_total: "Total",
        common_count: "Count",
        common_country: "Country",
        common_world: "world",
        common_over: "over",
        common_within: "With In",
        common_out_of_stock: "Out Of Stock",
        common_alert_of_stock: "Alert Of Stock",
        common_rating: "Rating",
        common_creater: "Creator",
        common_maximum: "Maximum",
        common_monthly: "Monthly",
        common_weekly: "Weekly",
        common_yearly: "Yearly",
        common_search: "Search",
        common_listing: "Listing",
        common_deatil: "Detail",
        common_sr_no: "Sr.No.",
        common_id: "Id",
        common_category_level: "Category Level",
        common_brand: "Brand",
        common_price: "Price",
        common_quantity: "Quantity",
        common_wholesale: "Wholesale",
        common_organiser: "Organiser",
        common_action: "Action",
        common_add: "Add",
        common_filter: "Filter",
        common_filters: "Filters",
        common_by: "by",
        common_min: "Min",
        common_max: "Max",
        common_submit: "Submit",
        common_all: "All",
        common_status: "Status",
        commono_start_to_end_date: "Start to End Date",
        common_export_file: "Export File",
        common_date: "Date",
        common_clone: "Clone",
        common_delete: "Delete",
        common_edit: "Edit",
        common_previous: "Previous",
        common_next: "Next",
        common_description: "Description",
        common_including_tax: "Including Tax",
        common_not_available: "Not Available",
        common_read_more: "Read More",
        common_read_less: "Read Less",
        common_discount: "Discount",
        common_tax: "Tax",
        common_highlights: "Highlights",
        common_specifications: "Specifications",
        common_variations: "Variations",
        common_images: "Images",
        common_name: "Name",
        common_services: "Services",
        common_delivery: "Delivery",
        common_forum: "Forum",
        common_radius: "Radius",
        common_location: "Location",
        common_time: "Days",
        common_faq: "FAQ",
        common_customer: "Customer",
        common_coupon: "Coupon",
        common_home: "Home",
        common_list: "List",
        common_note: "Note",
        common_please_upload_only_jpg_and_png_format_only: "Please upload only .jpg and .png format only.",
        common_percentage: "Percentage",
        common_enter_your: "Enter your",
        common_enter: "Enter",
        common_key: "Key",
        common_value: "Value",
        common_select: "Select",
        common_your: "Your",
        common_role: "Role",
        common_transaction: "Transaction",
        common_download_seller_invoice: "Download Seller Invoice",
        common_canceled: "Cancelled",
        common_accept_cancellation_request: "Accept Cancellation Request",
        common_cancel: "Cancel",
        common_user: "User",
        common_reason: "Reason",
        common_accept: "Accept",
        common_breakdown: "Breakdown",
        common_final: "Final",
        common_platform: "Platform",
        common_cancellation: "Cancellation",
        common_view: "View",
        common_no_data_found: "No Data Found",
        common_coupon_valid_for_selected_product: "Coupon valid for selected product",
        common_type: "Type",
        common_sub_type: "Sub Type",
        common_start_date: "Start Date",
        common_end_date: "End Date",
        common_one: "One",
        common_fixed: "Fixed",
        common_save: "Save",
        common_not_required: "Not required",
        common_amount: "Amount",
        common_stock: "Stock",
        common_of: "Of",
        common_alert: "Alert",
        common_confirmed: "Confirm",
        common_cancelled: "Cancelled",
        common_delivered: "Delivered",
        common_shipped: "Shipped",
        common_refund: "Refund",
        common_refunded: "Refunded",
        common_in_progress: "In Progress",
        common_answer: "Answer",
        common_question: "Question",
        common_here: "here",
        common_variant: 'Variant',
        common_only_one_variant_can_be_added: "only one variant can be added",
        common_not_more_than: "not more than 10 days",
        common_are_you_sure: "Are you sure?",
        common_yes_delete_it: "Yes, delete it",
        common_want_to_delete_this: " want to delete this",
        common_want_to: "want to",
        common_are_sure_want_to: "Are you sure want to",
        common_this: "this",
        common_questionmark: "?",
        common_deleted: "Deleted",
        common_your_coupon_has_been: "Your coupon has been",
        common_success: "success",
        common_yes: "Yes",
        common_no: "No",
        common_you_want_to: 'You want to',
        common_your_delivery_step_has_been_changed_into: "Your delivery step has changed into",
        common_request: "Request",
        common_you_have_accepted: "You have accepted",
        common_change_in: "change in",
        common_it: "it",
        common_has_been_deleted: "has been deleted",
        common_comment: "comment",
        common_for: "for",
        common_should_be_greater_than: "should be greater than",
        common_password: "password",
        common_changed: "changed",
        common_successfully: "successfully",
        common_old: "Old",
        common_new: "New",
        common_login: "Login",
        common_singup: "SignUp",
        common_register: "Register",
        common_to: "To",
        common_create_account_to_see_it_in_action: "Create account to see it in action",
        common_email: "Email",
        common_agree: "Agree",
        common_the: "the",
        common_terms: "Terms",
        common_and: "and",
        common_condition: "Condition",
        common_already_have_an_account: "Already have an account",
        common_signin: "Sign In",
        common_henceforth: "Quantity Savers",
        common_verify: "Verify",
        common_didnot_receive_any_code: "Didn't receive any code",
        common_resend: "Resend",
        common_code: "Code",
        common_already_: "Already",
        common_added_: "added",
        common_please_enter_tracking_id_or_link: "please enter tracking id or link",
        common_successfull: "successfull",
        common_copy: "copy",
        common_please: "Please",
        common_proper: "Proper",
        common_you_have_selected: "you have selected",
        common_review: "Review",
        common_my: 'My',
        common_copyright: "Copyright © 2023 ",
        common_ecommerce_seller_panel: "Seller Panel",
        common_all_rights_reserved: "All Rights Reserved",
        common_proceed_to_shipped: "Proceed to shipped",
        common_proceed_to_delivered: "Proceed to Delivered",
        common_see_it_in_action: "see it in action",
        common_forget: "Forget",
        common_do_not_have_an_account: "Don't have an account",
        common_required: "required",
        common_is: "is",
        common_please_enter_a_valid_password: "Please enter a valid password",
        common_must_be_atleast: "must be atleast",
        common_characters: "characters",
        common_long: "long",
        common_days: "Days",
        common_contain: "contain",
        common_uppercase: "uppercase",
        common_and_: "&",
        common_lowecase: "lowercase",
        common_with: "with",
        common_special: "special",
        common_numbers: "numbers",
        common_does_not: "doesn't",
        common_match: "match",
        common_must_be: "must be",
        common_different: "different",
        common_from: "From",
        common_city: "City",
        common_phone: "Phone",
        common_company: "Company",
        common_pin_code: "Pin Code",
        common_full: "Full",
        common_address: "Address",
        common_state: "State",
        common_provide: "provide",
        common_valid: "valid",
        common_fill: "fill",
        common_otp: "OTP",
        common_verification: "Verification",
        common_did_not: "didn't",
        common_recieve: "Recieve",
        common_same: "same",
        common_reset: "Reset",
        common_image: "Image",
        common_category: "Category",
        common_sub_category: "Subcategory",
        common_only: "only",
        common_use: "use",
        common_number: "number",
        common_in: "in",
        common_highlight: "Highlight",
        common_atleast: "Atleast",
        common_write: "Write",
        common_something: "Something",
        common_linking: "linking",
        common_now: "Now",
        common_notification: "Notification",
        common_comments: "Comments",
        common_please_add_delivery_if_you_want_to_show_your_product_in_marketplace: "Please Add delivery if you want to show your product in marketplace",
        common_nearest: "nearest",
        common_place: "place",
        common_again: "again",
        common_Apartment: "Apartment",
        common_download: "Download",
        common_records: "Records",
        common_loading: "Loading",
        common_you: "you",
        common_invoice: "invoice",
        common_bill: "Bill",
        common_ship: "Ship",
        common_manufacturer: "Manufacturer",
        common_box: "Box",
        common_warranty: "warranty",
        common_purpose: "purpose",
        common_item: "item",
        common_available: 'Available',
        common_keep: "Keep",
        common_contact: "Contact",
        common_commerce: "E-commerce",
        common_18002002009000_: "1800 200 2009000",
        common_thank_you: "Thank You!",
        common_shopping: "SHopping",
        common_us: "Us",
        common_authorized: "Authorized",
        common_signatory: "Signatory",
        common_sold: "Sold",
        common_title: "Title",
        common_qty: "Qty",
        common_gross: "Gross",
        common_convenience: "Convenience",
        common_charges: "Charges",
        common_taxable: "Taxable",
        common_appicable: "Not Applicable",
        common_shipping: "Shipping",
        common_grand: "Grand",
        common_mainactivity: "MainActivity",
        common_create: 'Create',
        common_delivery_all_over_world: "Delivery all over world",

    },
    ARABIC: {
        _0: "٠",
        _1: "١",
        _2: "٢",
        _3: '٣',
        _4: "٤",
        _5: '٥',
        _6: "٦",
        _7: "٧",
        _8: "٨",
        _9: "٩",
        //***** Header******
        header_welcome_to_henceForth_ecommerce_seller_panel: "مرحبًا بكم في لوحة البائع لتوفير الكمية ",
        //***** Sidebar******
        sidebar_order_allorders: "جميع الطلبات",

        sidebar_order_campaignorders: "جميع الطلبات",
        sidebar_order_order_cancellation_request: "طلبات إلغاء الطلب",
        //***** Home******
        home_that_are: "هذا",
        home_that_will_soon_go_out_of_that_ie_quantity_less_then: "سيخرج ذلك قريبًا من تلك الكمية أي أقل من ذلك الحين",
        home_addition_of_all_order_pricing: "إضافة كل أوامر التسعير",
        home_total_product: "إجمالي المنتج",
        home_total_orders: "إجمالي المنتج",

        //***** Common******
        common_dashboard: "لوحة القياد",
        common_product: "منتج",
        common_order: "طلب",
        common_rating_reviews: "التقييمات والمراجعات",
        common_campaign: "حملة",
        common_members: "",
        common_group: "مجموعة",
        common_created_at: "أنشئت في",
        common_visibility: "الرؤية",
        common_joined: "انضم",
        common_country: "دولة",
        common_world: "عالم",
        common_over: "زيادة",
        common_within: "داخل",
        common_duration: "مدة",
        common_reject: 'يرفض',
        common_coupons: "كوبونات",
        common_earning: "كسب",
        common_fees: "كسب",
        common_logout: "تسجيل خروج",
        common_profile: "حساب تعريفي",
        common_change_password: "تغيير كلمة المرور",
        common_mark_all_as_read: "اشر عليها بانها قرات",
        common_clear: "واضح",
        common_nothing_new_for_you: "لا شيء جديد بالنسبة لك",
        common_product_graph: "الرسم البياني للمنتج",
        common_sales_graph: "الرسم البياني للمبيعات",
        common_daily: "يوميًا",
        common_total: "المجموع",
        common_count: "عدد",
        common_out_of_stock: "إنتهى من المخزن",
        common_alert_of_stock: "تنبيه المخزون",
        common_rating: "تقييم",
        common_monthly: "شهريا",
        common_creater: "المنشئ",
        common_days: "أيام",
        common_maximum: "أقصى",
        common_weekly: "أسبوعي",
        common_yearly: "سنوي",
        common_search: "يبحث",
        common_listing: "قائمة",
        common_deatil: "التفاصيل",
        common_sr_no: "الأب رقم.",
        common_id: "بطاقة تعريف",
        common_category_level: "مستوى الفئة",
        common_brand: "ماركة",
        common_price: "سعر",
        common_quantity: "كمية",
        common_wholesale: "بالجملة",
        common_organiser: "منظم",
        common_action: "فعل",
        common_add: "يضيف",
        common_filter: "منقي",
        common_filters: "المرشحات",
        common_by: "بواسطة",
        common_min: "دقيقة",
        common_max: "الأعلى",
        common_submit: "يُقدِّم",
        common_all: "الجميع",
        common_status: "حالة",
        commono_start_to_end_date: "تاريخ البدء حتى الانتهاء",
        common_export_file: "ملف التصدير",
        common_date: "تاريخ",
        common_clone: "استنساخ",
        common_delete: "يمسح",
        common_edit: "يحرر",
        common_previous: "سابق",
        common_next: "التالي",
        common_description: "وصف",
        common_including_tax: "متضمن للضريبة",
        common_not_available: "غير متاح",
        common_read_more: "اقرأ أكثر",
        common_read_less: "أقرأ أقل",
        common_discount: "تخفيض",
        common_tax: "ضريبة",
        common_highlights: "ضريبة",
        common_specifications: "تحديد",
        common_variations: "الاختلافات",
        common_images: "الصور",
        common_name: "اسم",
        common_services: "خدمات",
        common_delivery: "توصيل",
        common_forum: "توصيل",
        common_radius: "نصف القطر",
        common_location: "موقع",
        common_time: "وقت",
        common_faq: "التعليمات",
        common_customer: "عميل",
        common_coupon: "قسيمة",
        common_home: "بيت",
        common_list: "قائمة",
        common_note: "ملحوظة",
        common_please_upload_only_jpg_and_png_format_only: "يرجى تحميل تنسيق .jpg و .png فقط.",
        common_percentage: "نسبة مئوية",
        common_enter_your: "ادخل",
        common_enter: "يدخل",
        common_key: "مفتاح",
        common_value: "قيمة",
        common_select: "يختار",
        common_your: "لك",
        common_role: "دور",
        common_transaction: "عملية",
        common_download_seller_invoice: "تحميل فاتورة البائع",
        common_canceled: "ألغيت",
        common_accept_cancellation_request: "قبول طلب الإلغاء",
        common_cancel: "يلغي",
        common_user: "مستخدم",
        common_reason: "سبب",
        common_accept: "يقبل",
        common_breakdown: "انفصال",
        common_final: "أخير",
        common_platform: "منصة",
        common_cancellation: "إلغاء",
        common_view: "منظر",
        common_no_data_found: "لاتوجد بيانات",
        common_coupon_valid_for_selected_product: "الكوبون صالح للمنتج المحدد",
        common_type: "يكتب",
        common_sub_type: "النوع الفرعي",
        common_start_date: "تاريخ البدء",
        common_end_date: "تاريخ الانتهاء",
        common_one: "واحد",
        common_fixed: "مُثَبَّت",
        common_save: "يحفظ",
        common_not_required: "غير مطلوب",
        common_amount: "كمية",
        common_stock: "مخزون",
        common_of: "ل",
        common_alert: "يُحذًِر",
        common_confirmed: "مؤكد",
        common_cancelled: "ألغيت",
        common_delivered: "تم التوصيل",
        common_shipped: "شحنها",
        common_refund: "استرداد",
        common_refunded: "معاد",
        common_in_progress: "في تَقَدم",
        common_answer: "إجابة",
        common_question: "سؤال",
        common_here: "هنا",
        common_variant: 'متغير',
        common_only_one_variant_can_be_added: "يمكن إضافة متغير واحد فقط",
        common_not_more_than: "لا تزيد عن 10 أيام",
        common_are_you_sure: "هل أنت متأكد؟",
        common_sub_sub_category:"فئة فرعية",
        common_yes_delete_it: "نعم ، احذفها",
        common_want_to_delete_this: "تريد حذف هذا",
        common_want_to: "اريد ان",
        common_are_sure_want_to: "هل أنت متأكد من أنك تريد",
        common_this: "هذا",
        common_questionmark: "؟",
        common_deleted: "تم الحذف",
        common_your_coupon_has_been: "قسيمتك كانت",
        common_success: "نجاح",
        common_yes: "نعم",
        common_no: "لا",
        common_you_want_to: 'اتريد',
        common_your_delivery_step_has_been_changed_into: "لقد تغيرت خطوة التسليم الخاصة بك إلى",
        common_request: "طلب",
        common_you_have_accepted: "لقد قبلت",
        common_change_in: "تغيير في",
        common_it: "هو - هي",
        common_pending:"قيد الانتظار",
        common_has_been_deleted: "قد تم حذف",
        common_comment: "تعليق",
        common_for: "ل",
        common_should_be_greater_than: "يجب أن يكون أكبر من",
        common_password: "كلمة المرور",
        common_changed: "تغير",
        common_successfully: "بنجاح",
        common_old: "قديم",
        common_new: "جديد",
        common_login: "تسجيل الدخول",
        common_singup: "اشتراك",
        common_register: "يسجل",
        common_to: "ل",
        common_create_account_to_see_it_in_action: "إنشاء حساب لرؤيته في العمل",
        common_email: "بريد إلكتروني",
        common_agree: "يوافق",
        common_the: "ال",
        common_terms: "شروط",
        common_and: "و",
        common_condition: "حالة",
        common_already_have_an_account: "هل لديك حساب",
        common_signin: "تسجيل الدخول",
        common_henceforth: "مدخرات الكمية",
        common_verify: "يؤكد",
        common_didnot_receive_any_code: "لم أتلق أي كود",
        common_resend: "إعادة إرسال",
        common_code: "شفرة",
        common_already_: "بالفعل",
        common_added_: "مضاف",
        common_please_enter_tracking_id_or_link: "الرجاء إدخال معرف التتبع أو الارتباط",
        common_successfull: "ناجح",
        common_copy: "ينسخ",
        common_please: "لو سمحت",
        common_proper: "سليم",
        common_you_have_selected: "قمت بتحديدها",
        common_review: "مراجعة",
        common_my: 'مراجعة',
        common_copyright: "ألفين وثلاثة وعشرون",
        common_ecommerce_seller_panel: "لوحة البائع",
        common_all_rights_reserved: "مراجعةمراجعةمراجعة",
        common_proceed_to_shipped: "مراجعةمراجعةمراجعة",
        common_proceed_to_delivered: "مراجعةمراجعةمراجعة",
        common_see_it_in_action: "مراجعةمراجعةمراجعة",
        common_forget: "مراجعة",
        common_do_not_have_an_account: "مراجعةمراجعةمراجعة ",
        common_required: "مراجعة",
        common_is: "مراجعة",
        common_please_enter_a_valid_password: "مراجعةمراجعةمراجعة",
        common_must_be_atleast: "مراجعةمراجعةمراجعة",
        common_characters: "مراجعةمراجعةمراجعة",
        common_long: "مراجعةمراجعةمراجعة",
        common_contain: "مراجعةمراجعةمراجعة",
        common_uppercase: "مراجعةمراجعةمراجعة",
        common_and_: "مراجعة",
        common_lowecase: "مراجعة",
        common_with: "مراجعة",
        common_special: "مراجعة",
        common_numbers: "مراجعة",
        common_does_not: "مراجعة",
        common_match: "مراجعة",
        common_must_be: "مراجعة",
        common_different: "مراجعة",
        common_from: "مراجعة",
        common_city: "مراجعة",
        common_phone: "مراجعة",
        common_company: "مراجعة",
        common_pin_code: "مراجعة",
        common_full: "مراجعة",
        common_address: "مراجعة",
        common_state: "مراجعة",
        common_provide: "مراجعة",
        common_valid: "مراجعة",
        common_fill: "مراجعة",
        common_otp: "مراجعة",
        common_verification: "مراجعة",
        common_did_not: "مراجعة't",
        common_recieve: "مراجعة",
        common_same: "مراجعة",
        common_reset: "مراجعة",
        common_image: "مراجعة",
        common_category: "مراجعة",
        common_sub_category: "مراجعة",
        common_only: "مراجعة",
        common_use: "مراجعة",
        common_number: "مراجعة",
        common_in: "مراجعة",
        common_highlight: "مراجعة",
        common_atleast: "مراجعة",
        common_write: "مراجعة",
        common_something: "مراجعة",
        common_linking: "مراجعة",
        common_now: "مراجعة",
        common_notification: "مراجعة",
        common_comments: "مراجعة",
        common_please_add_delivery_if_you_want_to_show_your_product_in_marketplace: "مراجعةمراجعةمراجعةمراجعةمراجعةمراجعةمراجعةمراجعةمراجعة",
        common_nearest: "مراجعة",
        common_place: "مراجعة",
        common_again: "مراجعة",
        common_Apartment: "مراجعة",
        common_download: "مراجعة",
        common_records: "مراجعة",
        common_loading: "مراجعة",
        common_you: "مراجعة",
        common_invoice: "مراجعة",
        common_bill: "مراجعة",
        common_ship: "مراجعة",
        common_manufacturer: "مراجعة",
        common_box: "مراجعة",
        common_warranty: "مراجعة",
        common_purpose: "مراجعة",
        common_item: "مراجعة",
        common_keep: "مراجعة",
        common_available: 'متاح',
        common_contact: "مراجعة",
        common_commerce: "مراجعة",
        common_18002002009000_: "مراجعةمراجعة",
        common_thank_you: "مراجعة",
        common_shopping: "مراجعة",
        common_us: "مراجعة",
        common_authorized: "مراجعة",
        common_signatory: "مراجعة",
        common_sold: "مراجعة",
        common_title: "مراجعة",
        common_qty: "مراجعة",
        common_gross: "مراجعة",
        common_convenience: "مراجعة",
        common_charges: "مراجعة",
        common_taxable: "مراجعة",
        common_appicable: "مراجعة",
        common_shipping: "مراجعة",
        common_grand: "مراجعة",
        common_mainactivity: "مراجعة",
        common_create: 'يخلق',
        common_delivery_all_over_world: "مراجعةمراجعةمراجعةمراجعة"
    }
}