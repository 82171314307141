import '../../assets/styles/auth.scss'
import '../../assets/styles/pages.scss'
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { GlobalContext, handleError } from '../../context/Provider'
import henceforthApi from '../../utils/henceforthApi'
import PaginationLayout from '../../components/common/PaginationLayout'
import Spinner from '../../components/common/spinner'
import BreadCrumb from '../../components/common/BreadCrumb'
import { toast } from 'react-toastify'
import { initLangData } from '../../utils/henceforthLanguage'
import NODATA from '../../assets/images/no-data-found.svg'
import defaultImage from '../../assets/images/pages/defaultImage.jpg'
import AllFilter from '../../components/common/AllFilter'
import OrderStatus from '../../components/order/OrderStatus'

const CampaignRequest = () => {
    const match: any = useMatch('/campaign-requests/:page')
    const { authState, loading, setLoading, authDispatch, toastMessage, language_data } = useContext(GlobalContext)
    const navigate = useNavigate();
    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_campaign} ${language_data?.[authState.lang]?.common_request} ${language_data?.[authState.lang]?.common_list}`, url: ``, active: 'not-allowed' }
    ]
    let Limit = 10;
    const location = useLocation();
    const newParam = new URLSearchParams(location.search);
    const [requestList, setRequestList] = useState({
        totalCount: 0,
        listing: [],
    } as any)

    const copyText = (id: string) => {
        if (id) {
            navigator?.clipboard?.writeText(id)
            toast.success(`${language_data?.[authState.lang]?.common_id} ${language_data?.[authState.lang]?.common_copy} ${language_data?.[authState.lang]?.common_successfull}`)
        }
    }

    const campaignRequests = async () => {
        setLoading(true)
        try {
            let res = (await henceforthApi.Campaign.getCampaignRequests(Number(match?.params.page) - 1, Limit, newParam.toString())).data
            setRequestList({
                ...requestList,
                totalCount: res.count,
                listing: res.data,
            })
        } catch (err: any) {
            if (newParam.has('product_id')) {
                toast.warn(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_search} ${language_data?.[authState.lang]?.common_proper} ${language_data?.[authState.lang]?.common_id}`)
                setRequestList({
                    ...requestList,
                    listing: [],
                })
            }
            handleError(err, '', authDispatch);
        } finally {
            setLoading(false);
        }
    }
    const onChangeFilter = (type: any) => {
        const newParam = new URLSearchParams()
        if (type) {
            newParam.set("filter", type)
        }
        navigate({ search: newParam.toString() })
    }
    const initLang = async () => {
        let langData = await initLangData('64466a7579e24437cade01c0', 'ENGLISH')
    }
    useEffect(() => {
        initLang()
    }, [])
    useEffect(() => {
        campaignRequests();
    }, [match?.params.page, newParam.get("search"), newParam.get('filter')])
    return (
        <Fragment>
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            <div className='page-spacing'>
                <section className='products'>
                    <div className='product-detail-box'>
                        {/* <AllFilter Request="active" refundMarging="true" filters="active" /> */}
                        <div className="common-card">
                            <div className="common-card-title d-flex justify-content-between align-items-center gap-2 flex-column flex-sm-row">
                                <h5>{language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_request} {language_data?.[authState.lang]?.common_list}</h5>
                                <div className="col-md-2">
                                    <div className='form-select-box'>
                                        <label className='mb-1 form-label fw-semibold'>Filter</label>
                                        <select className="form-select shadow-none" aria-label="Default select example"
                                            onChange={(e) => onChangeFilter(String(e.target.value))} value={String(newParam.get("filter")).toUpperCase()}>
                                            <option value="">ALL</option>
                                            <option value="ACCEPTED">ACCEPTED</option>
                                            <option value="REJECTED">REJECTED</option>
                                            <option value="PENDING">PENDING</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="common-card-content">
                                <div className='data-list-table table-responsive mb-3 text-center'>
                                    {loading ? <Spinner color={"text-success"} />
                                        :
                                        <table className="table table-striped align-middle text-center">
                                            <thead className=''>
                                                <tr>
                                                    <th>{language_data?.[authState.lang]?.common_sr_no}</th>
                                                    <th>{language_data?.[authState.lang]?.common_user} {language_data?.[authState.lang]?.common_name} </th>
                                                    <th>{language_data?.[authState.lang]?.common_image}</th>
                                                    <th>{language_data?.[authState.lang]?.common_description}</th>
                                                    <th>{language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_status} </th>
                                                    <th>{language_data?.[authState.lang]?.common_action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(requestList.listing) && requestList.listing.length ? requestList.listing.map((res: any, index: number) =>
                                                    <tr key={res?._id}>
                                                        <td> {match?.params.page === "0" ? index + 1 : (Number(match?.params.page) - 1) * Limit + (index + 1)}</td>
                                                        <td title={res?.created_by?.name ? res?.created_by?.name : `${language_data?.[authState.lang]?.common_not_available}`} className='product-image-table'>
                                                            <img className='me-2' src={res?.created_by?.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${res?.created_by?.profile_pic}` : defaultImage} alt={res?.created_by?.profile_pic} />
                                                            {res?.created_by?.name ? res?.created_by?.name : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td className='product-image-table'>
                                                            <img src={res?.image ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.image}` : defaultImage} alt="img" className='me-2' />
                                                        </td>
                                                        <td title={res.description?.replace(/<\/?[^>]+(>|$)/g, '')}><p className='text-wrap' dangerouslySetInnerHTML={{ __html: res?.description ? res?.description?.slice(0, 100) : `${language_data?.[authState.lang]?.common_not_available}` }}></p></td>
                                                        <td className={res.status === "ACCEPTED" ? 'text-success' : 'text-danger' && res.status === "PANDING" ? 'text-warning' : 'text-danger' }>{res?.status !== 0 ? res?.status : `${language_data?.[authState.lang]?.common_out_of_stock}`}</td>
                                                        <td>
                                                            <div className="btn-group gap-2">
                                                                <Link className="btn btn-white btn-sm" to={`/campaign-requests/${res?._id}/view`}> <i className="fa fa-eye me-1"></i>{language_data?.[authState.lang]?.common_view}</Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) :
                                                    <tr><td className='text-center py-3' colSpan={6}><img src={NODATA} width="100" /><p className='text-center mt-3'>{language_data?.[authState.lang]?.common_no_data_found}</p></td></tr>
                                                }
                                            </tbody>
                                        </table>}
                                </div>
                                {/* pagination  */}
                                <div className='dashboad-pagination-box'>
                                    <PaginationLayout
                                        data={requestList.listing}
                                        count={requestList.totalCount}
                                        limit={Number(Limit)}
                                        page={Number(match?.params.page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}
export default CampaignRequest;