import '../../assets/styles/pages.scss'
import '../../assets/styles/auth.scss'
import { Link, useMatch } from 'react-router-dom'
import BreadCrumb from '../../components/common/BreadCrumb';
import { Fragment, useContext, useEffect, useState } from 'react';
import PaginationLayout from '../../components/common/PaginationLayout';
import { GlobalContext, handleError } from '../../context/Provider';
import henceforthApi from '../../utils/henceforthApi';
import Spinner from '../../components/common/spinner';
import moment from 'moment';
import Swal from 'sweetalert2';
import NODATA from '../../assets/images/no-data-found.svg'
import COPY from "../../../src/assets/images/copy.png"
import { toast } from 'react-toastify';


const CouponListing = () => {
    const { language_data, loading, setLoading, authState, authDispatch } = useContext(GlobalContext)
    let Limit = 10

    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: ` ${language_data?.[authState.lang]?.common_coupon}  ${language_data?.[authState.lang]?.common_list}`, url: ``, active: 'not-allowed' },
    ]
    const match = useMatch('/coupons/:page')
    const [load, setLoad] = useState(false)
    const [couponList, setCouponList] = useState({
        total_count: 0,
        list: []
    })
    const couponListing = async () => {
        setLoading(true)
        try {
            let res = (await henceforthApi.Coupons.getCouponList(Number(match?.params.page) - 1, Limit, '')).data
            setCouponList({
                ...couponList,
                total_count: res.total_count,
                list: res.data,
            })

        } catch (err) {
            handleError(err, '', authDispatch);

        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        couponListing();
    }, [])
    // const changeDate=(res:string)=>{
    //     let x= String(new Date(res).toLocaleString("en-us",{day:}, { month: "short" }))
    //     return x
    // }
    const deleteCoupon = async (id: string) => {
        Swal.fire({
            title: `${language_data?.[authState.lang]?.common_are_you_sure}`,
            text: `${language_data?.[authState.lang]?.common_are_sure_want_to} ${language_data?.[authState.lang]?.common_delete} ${language_data?.[authState.lang]?.common_this} ${language_data?.[authState.lang]?.common_coupon} ${language_data?.[authState.lang]?.common_questionmark}`,
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            showDenyButton: true,
            denyButtonText: `${language_data?.[authState.lang]?.common_no}`,
            confirmButtonText: `${language_data?.[authState.lang]?.common_yes}`
        }).then(async (result) => {

            if (result.isConfirmed) {
                try {
                    await henceforthApi.Coupons.deleteCoupon(id)
                    couponListing()
                    Swal.fire(
                        `${language_data?.[authState.lang]?.common_deleted}`,
                        `${language_data?.[authState.lang]?.common_your_coupon_has_been} ${language_data?.[authState.lang]?.common_deleted}`,
                        'success')
                } catch (err: any) {
                    handleError(err, 'active', authDispatch);
                }
            }
        })
    }

    const copyText = (id: string) => {
        if (id) {
            navigator?.clipboard?.writeText(id)
            if (load == false) {
                setLoad(true)
                toast.success(`${language_data?.[authState.lang]?.common_coupon} ${language_data?.[authState.lang]?.common_code} ${language_data?.[authState.lang]?.common_copy} ${language_data?.[authState.lang]?.common_successfull}`)
                setTimeout(() => {
                    setLoad(false)
                }, 3000);
            }
        }
    }
    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            <div className='page-spacing'>
                <section className='products'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="common-card">
                                    <div className="d-flex justify-content-between common-card-title">
                                        <h5>{language_data?.[authState.lang]?.common_coupon}</h5>
                                        <div>
                                            <Link className="btn btn-white btn-sm" to="/coupon/add"> <i className="fa fa-plus me-1"></i>{language_data?.[authState.lang]?.common_add}</Link>
                                        </div>
                                    </div>
                                    <div className="common-card-content">
                                        {/* table */}
                                        <div className='data-list-table table-responsive mb-3 text-center'>
                                            {loading ? <Spinner color={"text-success"} /> :
                                                <table className="table table-striped align-middle">
                                                    <thead className=''>
                                                        <tr>
                                                            <th>{language_data?.[authState.lang]?.common_sr_no}</th>
                                                            <th>{language_data?.[authState.lang]?.common_coupon} {language_data?.[authState.lang]?.common_code}</th>
                                                            <th>{language_data?.[authState.lang]?.common_coupon} {language_data?.[authState.lang]?.common_name}</th>
                                                            <th>{language_data?.[authState.lang]?.common_type}</th>
                                                            <th>{language_data?.[authState.lang]?.common_sub_type}</th>
                                                            <th>{language_data?.[authState.lang]?.common_price}</th>
                                                            <th>{language_data?.[authState.lang]?.common_max} {language_data?.[authState.lang]?.common_discount}</th>
                                                            <th>{language_data?.[authState.lang]?.common_percentage}</th>
                                                            <th>{language_data?.[authState.lang]?.common_start_date}</th>
                                                            <th>{language_data?.[authState.lang]?.common_end_date}</th>
                                                            <th>{language_data?.[authState.lang]?.common_action}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(couponList.list) && couponList.list.length ? couponList.list.map((res: any, index) => <tr key={res?._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{res?.code ? res?.code : `${language_data?.[authState.lang]?.common_not_available}`} <img src={COPY} style={{ width: 15 }} onClick={() => copyText(res.code)} role="button" data-toggle="tooltip" title={`${res.common_delete}`} aria-disabled={load} /></td>
                                                            <td>{res?.name ? res?.name : ''}</td>
                                                            <td>{res?.type ? res?.type : ''}</td>
                                                            <td>{res?.sub_type ? res?.sub_type : ''}</td>
                                                            <td> {res?.price ? <><b>&#36;</b>{res?.price}</> : `${language_data?.[authState.lang]?.common_discount}`}</td>
                                                            <td>{res?.max_discount ? <><b>&#36;</b>{res?.max_discount}</> : `${language_data?.[authState.lang]?.common_discount}`}</td>
                                                            <td> {res?.percentage ? <>{res?.percentage}%</> : `${language_data?.[authState.lang]?.common_discount}`}</td>
                                                            <td>{res?.start_date ? moment(res?.start_date).format('DD MMM YYYY') : ''}</td>
                                                            <td>{res?.end_date ? moment(res?.end_date).format('DD MMM YYYY') : ''}</td>
                                                            <td><div className="btn-group gap-2">
                                                                {/* <Link to="/add-coupon" className="btn btn-white btn-sm"> <i className='fa fa-plus me-1'></i>Add</Link> */}
                                                                <Link to={`/coupon/${res?._id}/edit`} className="btn btn-white btn-sm"> <i className='fa fa-pencil me-1'></i>{language_data?.[authState.lang]?.common_edit}</Link>
                                                                <button className="btn btn-white btn-sm" type="button" onClick={() => deleteCoupon(res?._id)}> <i className='fa fa-trash me-1'></i>{language_data?.[authState.lang]?.common_delete}</button>
                                                            </div>
                                                            </td>
                                                        </tr>) :
                                                            <tr><td className='text-center py-3' colSpan={10}><img src={NODATA} width="100" /><p className='text-center mt-3'>{language_data?.[authState.lang]?.common_no_data_found}</p></td></tr>
                                                            // <tr className='text-center'><td colSpan={10}>{language_data?.[authState.lang]?.common_no_data_found}</td></tr>
                                                        }
                                                    </tbody>
                                                </table>}
                                        </div>
                                        {/* pagination  */}
                                        <PaginationLayout
                                            data={couponList.list}
                                            count={couponList.total_count}
                                            limit={Number(Limit)}
                                            page={Number(match?.params.page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}
export default CouponListing;