import { useContext } from "react";
import { GlobalContext } from "../../context/Provider";

interface propsType {
  color: string
}
const Spinner = (props: propsType) => {
  const { authState,language_data} = useContext(GlobalContext)

  return (
    <div className={`spinner-border spinner-border-sm ${props.color}`} role="status">
      <span className="visually-hidden">{language_data?.[authState.lang]?.common_loading}...</span>
    </div>
  )
}

export default Spinner;