import '../../../assets/styles/auth.scss'
import '../../../assets/styles/pages.scss';
import {  useMatch } from 'react-router-dom';
import { Fragment, useContext, useState } from 'react';
import { GlobalContext, handleError } from '../../../context/Provider';
import henceforthApi from '../../../utils/henceforthApi';
import Spinner from '../../../components/common/spinner';
import Errormessage from '../../../components/common/errormessage';
import BreadCrumb from '../../../components/common/BreadCrumb';
import Inputs, { SaveCancelButton } from '../../../components/common/Inputs';
import commonArray from '../../../components/common/commonArray';

const AddService = () => {
    const match=useMatch('/product/:product_id/services/add')
    const {language_data,authState,loading,setLoading,toastMessage,authDispatch}=useContext(GlobalContext)
    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' }, 
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_list}`, url: `/products/1`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_deatil}`, url: `/product/${match?.params.product_id}`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_add} ${language_data?.[authState.lang]?.common_services}`, url: ``, active: 'not-allowed' }
    ]
    
    const [addServices, setAddServices] = useState<string>("")
    const [servicesErr,setServicesErr]=useState<string>('')
    const handleSubmit = async (e:any) => {
        e.preventDefault()
        if(!addServices) return setServicesErr(`${language_data?.[authState.lang]?.common_services}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
        if(!addServices.trim()) return setServicesErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_write}${language_data?.[authState.lang]?.common_something}${language_data?.[authState.lang]?.common_in}${language_data?.[authState.lang]?.common_services}`)
        let items = {
            product_id:match?.params.product_id ,
            services: [
                addServices
            ],
            language: "ENGLISH"
        }
        setLoading(true)
        try {
          let addServiceResp= await henceforthApi.Product.addProductDetailsDynamically(commonArray.productOtherDetails[4],items)
          toastMessage("Service Added Successfully")
          window.history.back()
        setLoading(false)
        } catch (err) {
            handleError(err,'active',authDispatch);
            setLoading(false)
        }
    }
    const handleChange=(e:any)=>{
        setAddServices(e.target.value)
        setServicesErr('')
    }
    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            <div className='page-spacing'>
                <section className='edit-profile'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-9 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
                                {/* title  */}
                                <div className="common-card">
                                    <div className="common-card-title">
                                        <h5>{language_data?.[authState.lang]?.common_add} {language_data?.[authState.lang]?.common_services}</h5>
                                    </div>
                                    {/* form  */}
                                    <div className="common-card-content">
                                        <form onSubmit={handleSubmit}>
                                            {/* Services */}
                                            <Inputs.Input type="text" error={servicesErr} value={addServices} placeholder={`${language_data?.[authState.lang]?.common_enter_your} ${language_data?.[authState.lang]?.common_services}`} handleChange={handleChange} />
                                            {/* Submit Button  */}
                                            <SaveCancelButton loading={loading} color="text-white" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default AddService;