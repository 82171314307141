import { Fragment, useContext } from "react"
import { GlobalContext } from "../../context/Provider"
import { pagination } from "./commonInterface"


export default ({ count, data, page, limit,changePage }: pagination) => {
  const {onChangePagination}=useContext(GlobalContext)
  const onPageChange=(page:number)=>{
    if(typeof changePage==='function'){
      changePage(page)
    }else{
      onChangePagination(page)
    }
  }
  const visited_page_size = (limit * (page - 1)) + data?.length;
 console.log( visited_page_size,"visited_page_size")
  if (count <= limit) {
    return <Fragment>
    </Fragment>
  }
  return <div className="dashboad-pagination-box">

    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-end mb-0">
        <li className="page-item" onClick={() => (page !== 1) ? onPageChange(page - 1) : ""}>
          <button disabled={(page == 1)} className="page-link btn btn-sm btn-white" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {((page - 2) > 0) ? 
          <li className="page-item" onClick={() => onPageChange(page - 2)}><span className="page-link btn btn-sm btn-white rounded-0" >{page - 2}</span></li>
          : <Fragment></Fragment>}
        {((page - 1) > 0) ?
          <li className="page-item" onClick={() => onPageChange(page - 1)}><span className="page-link btn btn-sm btn-white rounded-0" >{page - 1}</span></li>
          : <Fragment></Fragment>}
        <li className="page-item">
          <span className="page-link btn btn-sm btn-white rounded-0 active-btn" >{page}</span>
        </li>
        {(visited_page_size < count) ?
          <li className="page-item" onClick={() => onPageChange(page + 1)}>
            <span className="page-link btn btn-sm btn-white rounded-0">{page + 1}</span>
          </li>
          : <Fragment></Fragment>}
        {((visited_page_size + limit) < count) ?
          <li className="page-item" onClick={() => onPageChange(page + 2)}><span className="page-link btn btn-sm btn-white rounded-0">{page + 2}</span></li>
          : <Fragment></Fragment>}
        <li className="page-item" onClick={() => (visited_page_size < count) ? onPageChange(page + 1) : ""}>
          <button disabled={!(visited_page_size < count)} className="page-link btn btn-sm btn-white" aria-label="Next"><span aria-hidden="true">&raquo;</span></button>
        </li>
      </ul>
    </nav>
  </div>

}