import _superagent from "superagent";
const superagentPromise = require("superagent-promise");
const superagent = superagentPromise(_superagent, global.Promise);


// const IS_STAGING = (window.origin.includes('sharedecommerce') || window.origin.includes('localhost'));
// const LIVE_API_ROOT = "https://demo.ecommerce.henceforthsolutions.com:3006/"; //live
// const STAGING_API_ROOT = "https://sharedecommerce.henceforthsolutions.com:3004/"; //staging
// const STAGING_API_ROOT = "http://18.168.208.123:3001/"; //staging
// export const API_ROOT = IS_STAGING ? STAGING_API_ROOT : LIVE_API_ROOT


export const API_ROOT = "https://quantitysavers.com:3001/"
const API_ROOT_IMAGEUPLOAD = `https://quantitysavers-live.s3.amazonaws.com/quantitysavers-live/`;
const BUCKET_ROOT = `https://quantitysavers-live.s3.amazonaws.com/quantitysavers-live/`;




// export const API_ROOT = `https://staging.quantitysavers.com:3002/`
// const API_ROOT_IMAGEUPLOAD = `https://quantitysavers-stag.s3.amazonaws.com/quantitysavers-stag/`
// const BUCKET_ROOT = `https://quantitysavers-stag.s3.amazonaws.com/quantitysavers-stag/`;

const API_FILE_ROOT_MEDIUM = `${BUCKET_ROOT}medium/`;
const API_FILE_ROOT_ORIGINAL = `${BUCKET_ROOT}original/`;
const API_FILE_ROOT_SMALL = `${BUCKET_ROOT}small/`;
const API_FILE_ROOT_AUDIO = `${BUCKET_ROOT}audio/`;
const API_FILE_ROOT_VIDEO = `${BUCKET_ROOT}video/`;
// const API_FILE_ROOT_DOCUMENTS = `${BUCKET_ROOT}documents/`;

// const encode = encodeURIComponent;
const responseBody = (res: any) => res.body;
let Language = 'ENGLISH';
const languageChange = (req: any) => {
  Language = req
};
let token: any = null;
const tokenPlugin = (req: any) => {
  if (token) {
    req.set("token", `${token}`);
  }
};

const requests = {
  del: (url: string) =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: (url: string) =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url: string, body: any) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  patch: (url: string, body: any) =>
    superagent
      .patch(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url: string, body: any) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  file: (url: string, key: string, file: File) =>
    superagent
      .post(`${API_ROOT}${url}`)
      .attach(key, file)
      .use(tokenPlugin)
      .then(responseBody),
};
// auth apis
const Auth = {
  login: (info: any) => requests.post("Seller/login", info),
  signup: (info: any) => requests.post("Seller/signup", info),
  forgotPassword: (info: any) => requests.post("Seller/forgot_password", info),
  checkOtp: (info: any) =>
    requests.post("Seller/forgot_password/verify_otp", info),
  emailVerifySendOtp: (info: any) => requests.post("Seller/resend_otp", info),
  emailVerifyOtp: (info: any) =>
    requests.post("Seller/email/verification", info),
  resendOtp: (info: any) =>
    requests.post("Seller/forgot_password/resend_otp", info),
  resetPassword: (info: any) =>
    requests.post("Seller/forgot_password/set_password", info),
  changePassword: (info: any) => requests.put("Seller/change_password", info),
  editProfile: (info: any) => requests.put("Seller/edit_profile", info),
  loginAsUser: (info: any) =>
    requests.post("Admin/seller/login_as_seller", info),
};
const Notification = {
  getNotification: (pagination: any, limit: any) => {
    return requests.get(
      `Seller/notifications?language=${Language}&pagination=${pagination}&limit=${limit}`
    );
  },
  markAsRead: (info: any, id: string) => requests.put(`Seller/notification/read/${id}?language=${Language}`, info),
  markAllAsRead: (info: any) => requests.put("Seller/notifications?language=${Language}", info),
  clearAllNotification: (info: any) => requests.put("Seller/notifications/clear?language=${Language}", info)
}
const Dashboard = {
  getTotalOrderCount: () =>
    requests.get(`Seller/dashboard?language=${String("ENGLISH")}`),

  getGraph: (graph: string, type: string) =>
    requests.get(
      `Seller/graph/${graph}?language=${Language}&type=${type}`
    ),
};
const Order = {
  export: (start_date: number, end_date: number, q: any) => requests.get(`Seller/orders?language=${Language}&start_date=${start_date}&end_date=${end_date}${q ? q : ''}`),
  getOrderList: (pagination: any, limit: any, q: any) => {
    return requests.get(
      `Seller/orders?language=${Language}&pagination=${pagination}&limit=${limit}${q ? `&${q}` : ''}`
    );
  },
  getOrderDetails: (id: any) => {
    return requests.get(
      `Seller/orders/${id}?language=${Language}`
    );
  },
  getOrderRatingReview: (pagination: any, limit: any, q: any) => {
    return requests.get(
      `Seller/order/reviews?language=${Language}&pagination=${pagination}&limit=${limit}${q ? `&${q}` : ''}`
    );
  },
  getOrderInvoiceDetails: (id: any) => {
    return requests.get(
      `Seller/orders/invoice/${id}?language=${Language}`
    );
  },
  deleteOrder: (info: any) => requests.put("Seller/orders/cancel", info),
  editCancelOrder: (info: any) =>
    requests.put("Seller/order/cancel/request", info),
};

const Campaign = {
  getCampaignOrderListing: (pagination: any, limit: any, q: any) => {
    return requests.get(`Seller/campaign_orders?language=${Language}&pagination=${pagination}&limit=${limit}${q ? `&${q}` : ''}`);
  },
  getcampaignOrderDetails: (id: any , campaign_id:any) => { return requests.get(`Seller/orders/${id}?campaign_id=${campaign_id}&language=${Language} `) },
  getCampaignList: (pagination: any, limit: any, q: any) => {
    return requests.get(`Seller/campaign/list?language=${Language}&pagination=${pagination}&limit=${limit}${q ? `&${q}` : ''}`);
  },
  getcampaginUserList: (id: any, q: any, pagination: any, limit: any) =>
    requests.get(`Seller/campaign/${id}/members?language=${Language}${q ? `&type=${q}` : ""}&pagination=${pagination}&limit=${limit}`),
  getcampaignDetails: (id: any) => { return requests.get(`Seller/campaign/${id}?language=${Language} `) },
  CampaignDuration: (info: any) => requests.post(`Seller/add_campaign_duration`, info),
  CampaignExport: (start_date: number, end_date: number) => requests.get(`Seller/campaign/list?language=${Language}&start_date=${start_date}&end_date=${end_date}`),
  getSelectedUser: (info: any) => requests.get(`Seller/users/listing?language=${Language}${info ? `&search=${info}` : ''}&pagination=0&limit=10`),
  getRequestDetails: (id: any) => { return requests.get(`Seller/campaign_request/${id}?language=${Language} `) },
  getCampaignRequests: (pagination: any, limit: any, q: any) => {
    return requests.get(`Seller/campaign_request/list?language=${Language}&pagination=${pagination}&limit=${limit}${q ? `&${q}` : ''}`);
  },
  AcceptRejectRequest: (id: any, info: any) => requests.put(`Seller/campaign_request/${id}`, info),
  CampaignOrderExport: (start_date: number, end_date: number) => requests.get(`Seller/campaign_orders?language=${Language}&start_date=${start_date}&end_date=${end_date}`),
}
const Forum = {
  getForumList: (pagination: any, limit: any, q: any) => {
    return requests.get(`Seller/group?language=${Language}&pagination=${pagination}&limit=${limit}${q ? `&${q}` : ''}`);
  },
  getForumDetails: (id: any) => { return requests.get(`Seller/group/${id}`) },
  getMemberList: (id: any, pagination: any, limit: any, q: any) => {
    return requests.get(`Seller/group/${id}/members?language=${Language}&pagination=${pagination}&limit=${limit}${q ? `&${q}` : ''}`);
  },
  exportForum: (start_date: number, end_date: number,) => requests.get(`Seller/group?language=${Language}&start_date=${start_date}&end_date=${end_date}`),
}

const Delivery = {
  changesStatus: (info: any) => requests.put("Seller/orders", info),
  getDeliveryListing: (pagination: any, limit: any, q: any) => {
    return requests.get(`delivery?language=${Language}&pagination=${pagination}&limit=${limit}${q ? `&${q}` : ''}`);
  },
};
const Transaction = {
  export: (start_date: number, end_date: number) => requests.get(`Seller/transactions?language=${Language}&start_date=${start_date}&end_date=${end_date}`),

  getTransactions: (pagination: number, limit: number, q: any) => {
    return requests.get(
      `Seller/transactions?language=${Language}&pagination=${pagination}&limit=${limit}${q ? `&${q}` : ''}`
    );
  },
};
const Product = {
  export: (start_date: number, end_date: number) => requests.get(`Seller/products?language=${Language}&start_date=${start_date}&end_date=${end_date}`),

  getProductList: (search: string, pagination: any, limit: any, q: any) => {
    return requests.get(
      `Seller/products?language=${Language}${search ? `&search=${search}` : ''}${pagination ? `&pagination=${pagination}` : ''}${limit ? `&limit=${limit}` : ''}${q ? `&${q}` : ""}`
    );
  },
  getProductDetails: (id: any) => {
    return requests.get(
      `Seller/products/${id}?language=${Language}`
    );
  },
  getProductDetailsAllDynamics: (
    dynamicUrl: string,
    id: string | undefined,
    product_id: string | undefined
  ) => {
    return requests.get(
      `Seller/products/${dynamicUrl}?${id ? `_id=${id}&` : ""}${product_id ? `product_id=${product_id}&` : ""}language=${Language}`
    );
  },
  add: (info: any) => requests.post("Seller/products", info),
  editProduct: (info: any) => requests.put("Seller/products", info),
  editProductDetailsDynamically: (dynamicUrl: string, info: any) =>
    requests.put(`Seller/products/${dynamicUrl}`, info),
  deleteProductDetailsDynamically: (dynamicUrl: string, id: string) =>
    requests.del(`Seller/products/${dynamicUrl}/${id}`),
  addProductDetailsDynamically: (dynamicUrl: string, info: any) =>
    requests.post(`Seller/products/${dynamicUrl}`, info),
};

const Common = {
  getProductFilter: () => {
    return requests.get(`User/nested`);
  },
  do_spaces_file_upload: (key: string, file: any) => requests.file(`Upload/do_spaces_file_upload`, key, file),
  do_spaces_file_upload_multiple: (key: any, file: any) =>
    requests.file(`Upload/do_spaces_file_upload_multiple`, key, file),
};
const Coupons = {
  addCoupon: (info: any) => requests.post("Seller/coupon", info),
  editCoupon: (info: any) => requests.put("Seller/coupon", info),
  getCoupon: (id: string | undefined) => {
    return requests.get(`Seller/coupon/${id}`);
  },
  deleteCoupon: (id: string) => requests.del(`Seller/coupon/${id}`),
  getCouponList: (pagination: any, limit: any, q: any) => {
    return requests.get(
      `Seller/coupon?language=${Language}${pagination >= 0 ? `&pagination=${pagination}` : ''}${limit ? `&limit=${limit}` : ''}${q ? `&${q}` : ""}`
    );
  },
};
const categorylisting = {
  Brandlisting: (info: any) => requests.get(`Product/brands?limit=10&pagination=0${info ? `&search=${info}` : ''}`),
  parcelListing: () =>
    requests.get(
      `Seller/shippo/parcel?language=${Language}`
    ),
  categorylisting: () =>
    requests.get(
      `Product/categories?language=${Language}`
    ),
  subCategorylisting: (id: any) =>
    requests.get(
      `Product/subcategories?category_id=${id}&language=${Language}`
    ),
  subSubCategorylisting: (id: any) =>
    requests.get(
      `Product/sub_subcategories?subcategory_id=${id}&language=${Language}`
    ),
};
// eslint-disable-next-line
export default {
  token,
  Auth,
  categorylisting,
  Common,
  Coupons,
  Dashboard,
  Delivery,
  Forum,
  Campaign,
  Notification,
  Order,
  Product,
  Transaction,
  API_ROOT,
  API_ROOT_IMAGEUPLOAD,
  API_FILE_ROOT_SMALL,
  API_FILE_ROOT_MEDIUM,
  API_FILE_ROOT_ORIGINAL,
  API_FILE_ROOT_VIDEO,
  languageChange,
  setToken: (_token: any) => {
    token = _token;
  },
};
