enum OrderStatus {
    PLACED = "PLACED",
    SHIPPED = "SHIPPED",
    CONFIRMED = "CONFIRMED",
    CANCELLED = "CANCELLED",
    PAID = "PAID",
    DELIVERED = "DELIVERED",
    DISPUTE = "DISPUTE",
    RETURNED = "RETURNED",
    REFUND_IN_PROGESS = "REFUND_IN_PROGESS",
    REFUNDED = "REFUNDED",
    PENDING_CANCELLATION = "PENDING_CANCELLATION",
    SUCCESS = "SUCCESS",
}
enum Language {
    ENGLISH = "ENGLISH",
    ARABIC = "ARABIC"
}
export default {
    OrderStatus,
    Language,
}