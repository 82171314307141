import { Link, useLocation, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { Fragment } from 'react'
import { GlobalContext } from '../../context/Provider';
import henceforthApi from '../../utils/henceforthApi';
import { menuMapList } from './commonInterface';
import profile_img from '../../assets/images/pages/defaultImage.jpg';
import Swal from 'sweetalert2';


const TheSideBar = (props: any) => {
    const { logOutNow, authState, language_data } = useContext(GlobalContext)
    const location = useLocation()
    const { page } = useParams()
    const menuMap = [
        { pathname: '/inActive', path: '/?product=DAILY&sales=DAILY', fa: 'fa-th-large', name: language_data?.[authState.lang]?.common_dashboard, nested: [], onClick: 'on' },
        { pathname: '/product', path: '/products/1', fa: 'fa-laptop', name: language_data?.[authState.lang]?.common_product, nested: [], onClick: 'on' },
        {
            pathname: '/order', path: '/orders/1', fa: 'fa-shopping-cart', name: language_data?.[authState.lang]?.common_order, nested: [
                { pathname: `/orders/${page}`, path: '/orders/1', fa: 'fa-shopping-cart', name: language_data?.[authState.lang]?.sidebar_order_allorders },
                { pathname: `/orders/campaign/${page}`, path: '/orders/campaign/1', fa: 'fa-shopping-cart', name: language_data?.[authState.lang]?.sidebar_order_campaignorders },
                { pathname: '/orders/cancellation/request', path: '/orders/cancellation/requests/1?order_status=PENDING_CANCELLATION', fa: 'fa-ban', name: language_data?.[authState.lang]?.sidebar_order_order_cancellation_request }
            ], onClick: 'on'
        },
        {
            pathname: '/campaign', path: '/campaign/1', fa: 'fa fa-bullhorn', name: language_data?.[authState.lang]?.common_campaign, nested: [
                { pathname: `/campaign/${page}`, path: '/campaign/1', fa: 'fa fa-bullhorn', name: language_data?.[authState.lang]?.common_campaign },
                { pathname: `/campaign-requests/${page}`, path: '/campaign-requests/1', fa: 'fa fa-ban', name: `${language_data?.[authState.lang]?.common_campaign} ${language_data?.[authState.lang]?.common_request}` },
            ], onClick: 'on'
        },
        { pathname: `/ratings/${page}`, path: '/ratings/1', fa: 'fa-star', name: language_data?.[authState.lang]?.common_rating_reviews, nested: [], onClick: 'on' },
        { pathname: '/coupon', path: '/coupons/1', fa: 'fa-gift', name: language_data?.[authState.lang]?.common_coupons, nested: [], onClick: 'on' },
        { pathname: `/delivery/${page}`, path: '/delivery/1', fa: 'fa-truck', name: language_data?.[authState.lang]?.common_delivery, nested: [], onClick: 'on' },
        { pathname: `/forum`, path: '/forums/1', fa: 'fa-comments', name: language_data?.[authState.lang]?.common_forum, nested: [], onClick: 'on' },
        { pathname: `/earnings/${page}`, path: '/earnings/1', fa: 'fa-money', name: language_data?.[authState.lang]?.common_earning, nested: [], onClick: 'on' },
        { pathname: '/inActive', path: `${location.pathname}`, fa: 'fa-sign-out', name: language_data?.[authState.lang]?.common_logout, nested: [], onClick: 'off' },
    ] as Array<menuMapList>
    const logout = (click: string) => {
        if (click === 'off') {
            Swal.fire({
                title: `${language_data?.[authState.lang]?.common_are_you_sure}`,
                text: `${language_data?.[authState.lang]?.common_are_sure_want_to} ${language_data?.[authState.lang]?.common_logout}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: `${language_data?.[authState.lang]?.common_cancel}`,
                confirmButtonText: `${language_data?.[authState.lang]?.common_yes}`,
            }).then(async (result: any) => {
                if (result.isConfirmed) {
                    logOutNow()
                }
            })
        }
    }
    return (
        <Fragment>
            {/* sidebar Header  */}
            <div className="sidebar-header">
                <div className='desktop-screen'>
                    {/* user detail  */}
                    <div className="user-detail-box">
                        <img src={authState?.image ? authState?.image?.endsWith('.webp') ? `${henceforthApi.API_FILE_ROOT_ORIGINAL}${authState?.image}` : `${henceforthApi.API_FILE_ROOT_MEDIUM}${authState?.image}` : profile_img} alt={authState.image} className='rounded-circle' />
                        {/* <p>{authState.name}</p> */}
                    </div >
                    <div className="dropdown mt-1">
                        {props.handled ? <button title={authState?.name} className="btn shadow-none border-0 dropdown-toggle p-0 text-muted" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {authState?.name && authState?.name?.length > 15 ? authState?.name.slice(0,15)+"...":authState?.name}
                        </button> : <span className='text-muted'>{authState.name}</span>}
                        <ul className="dropdown-menu py-0 overflow-hidden">
                            <li className='px-2 pt-2'><Link className="dropdown-item" to="/profile">{language_data?.[authState.lang]?.common_profile}</Link></li>
                            <li className='px-2 pt-2'><Link className="dropdown-item" to="/change-password">{language_data?.[authState.lang]?.common_change_password}</Link></li>
                            <li className='divider'></li>
                            <li className='px-2 py-2'><Link className="dropdown-item" to="" onClick={() => logout('off')}>{language_data?.[authState.lang]?.common_logout}</Link></li>
                        </ul>
                    </div>
                </div >
            </div >
            {/* navigation bar  */}
            <div className='navigation-bar' >
                <div className="accordion" id="Navigation-bar">
                    {menuMap.map((res, index: number) => <div className={`accordion-item rounded-0 ${location.pathname === (res.path === '/?product=DAILY&sales=DAILY' && '/') ? 'link-active' : location.pathname.startsWith(res.pathname) ? 'link-active' : ""}`} key={index}>
                        {!res.nested.length ? <h6 className="accordion-header">
                            <Link to={`${res?.path}`} className="accordion-button shadow-none d-flex align-items-center collapsed text-decoration-none single-link" onClick={() => logout(res?.onClick)}>
                                <i className={`fa ${res?.fa} me-3 fs-5`}></i>{props?.handled && <span>{res?.name}</span>}
                            </Link>
                        </h6> :
                            <h6 className="accordion-header" id={`subCategoriesThree${index}`}>
                                <button className="accordion-button shadow-none d-flex align-items-center collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#subCateThree${index}`} aria-expanded="false" aria-controls={`subCateThree${index}`}>
                                    <i className={`fa ${res.fa} me-3 fs-5`}></i> {props.handled && <span>{res?.name}</span>}
                                </button>
                            </h6>}
                        {res.nested.length ? <div id={`subCateThree${index}`} className="accordion-collapse collapse" aria-labelledby={`subCategoriesThree${index}`} data-bs-parent="#Navigation-bar">
                            <div className={`accordion-body pt-0 ${props?.handled ? "px-4" : "px-2"}`}>
                                <ul className={`list-unstyled ${props?.handled ? "ps-4" : "ps-2"} sidebar-sublink`}>
                                    {res.nested.length && res.nested.map((res, index: number) =>
                                        <li key={index}><Link to={res.path} className="w-100">
                                            <div className={`d-inline-flex w-100 py-2 px-2 ${location.pathname === res.pathname ? 'bg-theme' : location.pathname.startsWith(res.pathname) ? 'bg-theme' : ""}`}>
                                                <i className={`fa ${res?.fa} me-2 fs-5`}></i>
                                                <span>{props?.handled && res.name}</span>
                                            </div>
                                        </Link></li>)}
                                </ul>
                            </div>
                        </div> : ''}
                    </div>)}
                </div>
            </div>
        </Fragment >
    )
}

export default TheSideBar;