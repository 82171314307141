const discount = [
    { name: '10%', _id: `10` },
    { name: '20%', _id: `20` },
    { name: '30%', _id: `30` },
    { name: '40%', _id: `40` },
    { name: '50%', _id: `50` },
    { name: '60%', _id: `60` },
    { name: '70%', _id: `70` },
    { name: '80%', _id: `80` },
    { name: '90%', _id: `90` },
]
const buttonArray = ["Daily", 'Weekly', 'Monthly', 'Yearly']
const productOtherDetails = ['highlights', 'details', 'variations', 'faqs', 'services', 'delivery/location']
// eslint-disable-next-line   
export default{
        discount,
        buttonArray,
        productOtherDetails
    }