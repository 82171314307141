import { Fragment, useContext, useState } from 'react';
import {  useLocation, useMatch } from 'react-router-dom';
import BreadCrumb from '../../../components/common/BreadCrumb';
import commonArray from '../../../components/common/commonArray';
import Errormessage from '../../../components/common/errormessage';
import Inputs, { SaveCancelButton } from '../../../components/common/Inputs';
import Spinner from '../../../components/common/spinner';
import { GlobalContext, handleError } from '../../../context/Provider';
import henceforthApi from '../../../utils/henceforthApi';

const EditHighlight = () => {
    const location = useLocation()
    const match = useMatch("product/:id/highlight/:highlight_id/edit")
    const {language_data, authState,toastMessage,authDispatch } = useContext(GlobalContext);
    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' }, 
        { name:  `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_list}`, url: `/products/1`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_deatil}`, url: `/product/${match?.params.id}`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_edit} ${language_data?.[authState.lang]?.common_highlights}`, url: ``, active: 'not-allowed' }
    ]
    
    const newParam = new URLSearchParams(location.search);
    const [highlights, setHighlights] = useState(newParam.get("content") as string)
    const [loading, setLoading] = useState<boolean>(false)
    const [highlightsErr, setHighlightsErr] = useState<string>("")

    const edit = async () => {
        const items = {
            _id: match?.params.highlight_id,
            product_id: match?.params.id,
            content: highlights,
            language: "ENGLISH"
        }
        if (!highlights) return setHighlightsErr(`${language_data?.[authState.lang]?.common_highlight}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
        if (!highlights.trim()) return setHighlightsErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_write}${language_data?.[authState.lang]?.common_something}${language_data?.[authState.lang]?.common_in}${language_data?.[authState.lang]?.common_highlights}`)
        setLoading(true)
        try {
            const apiRes = await henceforthApi.Product.editProductDetailsDynamically(commonArray.productOtherDetails[0],items)
            toastMessage("Highlight Edited Successfully")
            window.history.back()
        } catch (error) {
            handleError(error,'active',authDispatch);
        } finally {
            setLoading(false)
        }

    }
    const handleChange=(e:any)=>{
        setHighlights(e.target.value)
        setHighlightsErr('')
    }
    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            <div className='page-spacing'>
                <section className='edit-profile'>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-sm-10 col-md-7 col-lg-6 col-xl-5 col-xxl-3">
                                {/* title  */}
                                <div className="common-card">
                                    <div className="common-card-title">
                                        <h5>{language_data?.[authState.lang]?.common_edit} {language_data?.[authState.lang]?.common_highlights}</h5>
                                    </div>
                                    {/* form  */}
                                    <div className="common-card-content">
                                        <form onSubmit={(e) => { e.preventDefault(); edit() }}>
                                            {/* Highlight */}
                                            <Inputs.Input type="text" error={highlightsErr} value={highlights} placeholder={`${language_data?.[authState.lang]?.common_enter_your} ${language_data?.[authState.lang]?.common_highlights}`} handleChange={handleChange} />
                                            {/* Submit Button  */}
                                            <SaveCancelButton loading={loading} color="text-white" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default EditHighlight;