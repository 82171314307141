import '../../../assets/styles/auth.scss'
import '../../../assets/styles/pages.scss';
import {  useLocation, useMatch } from 'react-router-dom';
import henceforthApi from '../../../utils/henceforthApi';
import { Fragment, useContext, useState } from 'react';
import { GlobalContext, handleError } from '../../../context/Provider';
import Spinner from '../../../components/common/spinner';
import Errormessage from '../../../components/common/errormessage';
import BreadCrumb from '../../../components/common/BreadCrumb';
import Inputs, { SaveCancelButton } from '../../../components/common/Inputs';
import commonArray from '../../../components/common/commonArray';

const EditService = () => {
    const match = useMatch(`/product/:product_id/services/edit/:service_id`)
    const {language_data, authState, loading, setLoading,toastMessage,authDispatch} = useContext(GlobalContext)
    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' }, 
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_list}`, url: `/products/1`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_deatil}`, url: `/product/${match?.params.product_id}`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_edit} ${language_data?.[authState.lang]?.common_services}`, url: ``, active: 'not-allowed' }
    ]
    
    const location=useLocation()
    const newParam=new URLSearchParams(location.search)
    const [service, setService] = useState(newParam.get('service') as string)
    const [servicesErr,setServicesErr]=useState<string>('')
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if(!service) return setServicesErr(`${language_data?.[authState.lang]?.common_services}${language_data?.[authState.lang]?.common_is}${language_data?.[authState.lang]?.common_required}`)
        if (!service.trim()) return setServicesErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_write}${language_data?.[authState.lang]?.common_something}${language_data?.[authState.lang]?.common_in}${language_data?.[authState.lang]?.common_services}`)
        let items = {
            _id: match?.params.service_id,
            product_id: match?.params.product_id,
            content: service,
            language: "ENGLISH"
        }
        setLoading(true)
        try {
            let editServiceRes = await henceforthApi.Product.editProductDetailsDynamically(commonArray.productOtherDetails[4],items)
            toastMessage("Service Edited Successfully")
            window.history.back()
        } catch (err) {
            handleError(err,'active',authDispatch);
        }finally{
            setLoading(false)
        }
    }
    const handleChange=(e:any)=>{
        setService(e.target.value)
        setServicesErr('')
    }
    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            <div className='page-spacing'>
                <section className='edit-profile'>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-sm-9 col-md-6 col-lg-5 col-xl-4 col-xxl-3">
                                {/* title  */}
                                <div className="common-card">
                                    <div className="common-card-title">
                                        <h5>{`${language_data?.[authState.lang]?.common_edit} ${language_data?.[authState.lang]?.common_services}`}</h5>
                                    </div>
                                    {/* form  */}
                                    <div className="common-card-content">
                                        <form onSubmit={handleSubmit}>
                                            {/* Service */}
                                            <Inputs.Input type="text" error={servicesErr} value={service} placeholder={`${language_data?.[authState.lang]?.common_enter_your} ${language_data?.[authState.lang]?.common_services}`} handleChange={handleChange} />
                                            {/* Submit Button  */}
                                            <SaveCancelButton loading={loading} color="text-white" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default EditService;