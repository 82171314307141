import { Link } from "react-router-dom"
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import henceforthApi from "../../utils/henceforthApi";
import { strongPasswordValidation } from "../../utils/validations";
import { GlobalContext, handleError } from "../../context/Provider";
import Spinner from "../../components/common/spinner";
import Errormessage from "../../components/common/errormessage";
import BreadCrumb from "../../components/common/BreadCrumb";
import Inputs, { SaveCancelButton } from "../../components/common/Inputs";
type MultipleKeys = {
    oldPassword: string,
    newPassword: string,
    confirmNewPassword: string,
    loading: boolean
}
const ChangePassword = () => {
    const {language_data, authState, loading, setLoading,toastMessage, authDispatch } = useContext(GlobalContext)
    // const { authState, loading, setLoading, toastMessage, authDispatch } = useContext(GlobalContext)
    let breadCrumbPath = [
        { name: ` ${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: ` ${language_data?.[authState.lang]?.common_change_password} `, url: ``, active: 'not-allowed' },
    ]
    
    const [firstEye, setFirstEye] = useState<boolean>(true);
    const [secondEye, setSecondEye] = useState<boolean>(true);
    const [thirdEye, setThirdEye] = useState<boolean>(true);
    const [oldPasswordErr, setOldPasswordErr] = useState('')
    const [newPasswordErr, setNewPasswordErr] = useState('')
    const [confirmPasswordErr, setConfirmPasswordErr] = useState('')
    const [state, setState] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        loading: false,
    } as MultipleKeys);
    const handleInput = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'oldPassword') setOldPasswordErr('')
        if (name === 'newPassword') setNewPasswordErr('')
        if (name === 'confirmNewPassword')setConfirmPasswordErr('')
        setState({
            ...state,
            [name]: value,
        });

    };
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (!state.oldPassword && !state.newPassword && !state.confirmNewPassword) {
            setOldPasswordErr(`${language_data?.[authState.lang]?.common_old} ${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            setNewPasswordErr(`${language_data?.[authState.lang]?.common_new} ${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            setConfirmPasswordErr(`${language_data?.[authState.lang]?.common_confirmed} ${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            return
        }
        if (!state.oldPassword) return setOldPasswordErr(`${language_data?.[authState.lang]?.common_old} ${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
        if (!state.oldPassword.trim()) return setOldPasswordErr(`${language_data?.[authState.lang]?.common_please_enter_a_valid_password}`)
        if (!state.newPassword) return setNewPasswordErr(`${language_data?.[authState.lang]?.common_new} ${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
        if (!state.newPassword.trim()) return setNewPasswordErr(`${language_data?.[authState.lang]?.common_please_enter_a_valid_password}`)
        if (!strongPasswordValidation(state.newPassword)) { return setNewPasswordErr(`${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_must_be_atleast} ${language_data?.[authState.lang]?._8} ${language_data?.[authState.lang]?.common_characters} ${language_data?.[authState.lang]?.common_long} ${language_data?.[authState.lang]?.common_and} ${language_data?.[authState.lang]?.common_contain} ${language_data?.[authState.lang]?.common_one} ${language_data?.[authState.lang]?.common_uppercase} ${language_data?.[authState.lang]?.common_and} ${language_data?.[authState.lang]?.common_one} ${language_data?.[authState.lang]?.common_lowecase} ${language_data?.[authState.lang]?.common_characters} ${language_data?.[authState.lang]?.common_with} ${language_data?.[authState.lang]?.common_special} ${language_data?.[authState.lang]?.common_characters} ${language_data?.[authState.lang]?.common_and} ${language_data?.[authState.lang]?.common_numbers}`) }
        if (state.newPassword !== state.confirmNewPassword) return setConfirmPasswordErr(`${language_data?.[authState.lang]?.common_new} ${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_and} ${language_data?.[authState.lang]?.common_confirmed} ${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_does_not} ${language_data?.[authState.lang]?.common_match}.`)
        else if (state.newPassword === state.oldPassword) return setNewPasswordErr(`${language_data?.[authState.lang]?.common_new} ${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_must_be} ${language_data?.[authState.lang]?.common_different} ${language_data?.[authState.lang]?.common_from} ${language_data?.[authState.lang]?.common_old} ${language_data?.[authState.lang]?.common_password}`)
        let items = {
            old_password: state.oldPassword,
            new_password: state.newPassword,
            language: "ENGLISH"
        }
        setLoading(true)
        try {
            let res = await henceforthApi.Auth.changePassword(items)
            toastMessage(res.data.message ? res.data.message :`${language_data?.[authState.lang]?.common_password} ${language_data?.[authState.lang]?.common_changed} ${language_data?.[authState.lang]?.common_successfully}` )
            window.history.back()
        } catch (err) {
            handleError(err, 'active', authDispatch);

        } finally {
            setLoading(false)
        }
    }
    // const passwordCheck = () => {
        // if (state.newPassword.length < 7) {
        //     passwordErr.current.innerHTML = "Password must be atleast 8 characters long and contain one uppercase and one lowercase character with special character and numbers";
        // } else {
        //     passwordErr.current.innerHTML = "";
        // }
    // }
    // const passwordSame = (e: any) => {
        // if (state.newPassword !== e && state.confirmNewPassword) {
        //     passwordMatch.current.innerHTML = "Password must be same"
        // } else {
        //     passwordMatch.current.innerHTML = ""

        // }
    // }
    const eyeChanger = (selectEye: string) => {
        if (selectEye === "first") setFirstEye(!firstEye)
        else if(selectEye ==="second") setSecondEye(!secondEye)
        else if(selectEye ==="third") setThirdEye(!thirdEye)
    }
    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            <div className='page-spacing' >
                <section className='change-password'>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-sm-10 col-md-7 col-lg-6 col-xl-5 col-xxl-3 px-0">
                                <div className="common-card h-100">
                                    <div className="common-card-title">
                                        <h5>{language_data?.[authState.lang]?.common_change_password}</h5>
                                    </div>
                                    <div className="common-card-content">
                                        <form onSubmit={handleSubmit}>
                                            {/* old Password */}
                                            <Inputs.InputWEye name="oldPassword" placeholder={`${language_data?.[authState.lang]?.common_old} ${language_data?.[authState.lang]?.common_password}`} showHide={firstEye} selectEye="first" handleInput={handleInput} value={state.oldPassword} error={oldPasswordErr} passwordCheck={()=>{}} passwordSame={()=>{}}  onClick={eyeChanger} />
                                            {/* New Password */}
                                            <Inputs.InputWEye name="newPassword" placeholder={`${language_data?.[authState.lang]?.common_new} ${language_data?.[authState.lang]?.common_password}`} showHide={secondEye} selectEye="second" handleInput={handleInput} value={state.newPassword} error={newPasswordErr} onClick={eyeChanger} />
                                            {/* Confirm New Password */}
                                            <Inputs.InputWEye name="confirmNewPassword" placeholder={`${language_data?.[authState.lang]?.common_confirmed} ${language_data?.[authState.lang]?.common_new} ${language_data?.[authState.lang]?.common_password}`} showHide={thirdEye} selectEye="third" handleInput={handleInput} value={state.confirmNewPassword} error={confirmPasswordErr} onClick={eyeChanger} />
                                            {/* Submit Button  */}
                                            <SaveCancelButton loading={loading} color="text-white" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}

export default ChangePassword;