import React from "react";
import { Fragment } from "react";
import { GlobalContext } from "../../context/Provider";

const TheFooter = () => {
    const {language_data, authState, } = React.useContext(GlobalContext)

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 text-center text-md-start">
                        {/* Copy Right Text  */}
                        <p>{language_data?.[authState.lang]?.common_copyright} &copy;  <span>{language_data?.[authState.lang]?.common_henceforth} {language_data?.[authState.lang]?.common_ecommerce_seller_panel}</span>.{language_data?.[authState.lang]?.common_all_rights_reserved}.</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default TheFooter;