import React, { createContext, ReactNode, SetStateAction, useEffect, useReducer, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import auth from './reducers/auth';
import logoutSuccess from "./actions/auth/logoutSuccess";
import henceforthApi from '../utils/henceforthApi';
import { ERROR_UNAUTHORIZED } from './actionTypes';
import { CommonContextType, language } from './providerInterface';

export const GlobalContext = createContext({} as CommonContextType);
export const handleError = (error: any, active: string, authDispatch: any) => {
    if (active === "active") toast.error((typeof error?.response?.body?.error_description === "string") ? error?.response?.body?.error_description : JSON.stringify(error?.response?.body?.error_description))
    if (error?.response?.body?.error === ERROR_UNAUTHORIZED) logoutSuccess({})(authDispatch);
}

export const downloadFile = (file_path: string) => {
    var a: any = document.createElement('a') as HTMLElement;
    a.href = file_path;
    a.target = "_blank";
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

type GlobleContextProviderProps = {
    children: ReactNode;
}

function GlobalProvider(props: GlobleContextProviderProps) {
    const [authState, authDispatch] = useReducer(auth, {}, () => {
        const localAuthState = localStorage.getItem("authState");
        const parsedObject = localAuthState ? JSON.parse(localAuthState as any) : {}
        if (Object?.keys(parsedObject)?.length) {
            henceforthApi?.languageChange(parsedObject?.lang);
            henceforthApi?.setToken(parsedObject?.access_token);
            return parsedObject
        } else {
            henceforthApi?.languageChange('ENGLISH');
            return { lang: 'ENGLISH' }
        }
    })
    const location = useLocation();
    const newParam = new URLSearchParams(location.search);
    
    const [loading, setLoading] = useState(false)
    // nestedData: Array<object>,
    // const [nestedData, setNestedData] = useState([] as Array<object>)

    const scrollToTop = () => {
        if (window) {
            window.scrollTo(0, 0);
        }
    }

    useEffect(scrollToTop, [location.pathname]);
    useEffect(() => {
        localStorage.setItem("authState", JSON.stringify(authState))
    }, [authState]);
    useEffect(() => {
        if (!localStorage.getItem('authState')) {
            logOutNow()
        }
    }, [localStorage.getItem('authState')]);
    const navigate = useNavigate()

    const logOutNow = () => {
        logoutSuccess({})(authDispatch);
        navigate("/", { replace: true });
    };
    const toastMessage = (msg: string) => {
        toast.success(msg)
    }

    const onChangePagination = (page: number) => {
        const x = location.pathname.split('/')
        let s = ''
        x.forEach((res, index) => {
            if (x.length - 1 !== index) {
                s += res + '/'
            }
        })
        navigate({
            pathname: `${s}${page}`,
            search: newParam.toString()
        })
    }
    const handleSearch = (name: string, value: string) => {
        if (value) {
            if (name === "product_id") newParam.delete("search")
            if (name === "search") newParam.delete("product_id")
            if (name === "order_status") newParam.delete("payment_status")
            if (name === "payment_status") newParam.delete("order_status")
            if (name === 'start_date') newParam.delete('end_date')
            if (name === 'stock') newParam.delete('order_status'); newParam.delete('payment_status')
            if (name === 'order_status') newParam.delete('stock'); newParam.delete('payment_status')
            if (name === 'payment_status') newParam.delete('order_status'); newParam.delete('stock')
            newParam.set(name, value)
        } else {
            if (newParam.has(name)) {
                newParam.delete(name)
            }
            if (name === 'start_date') {
                newParam.delete('end_date')
            }

        }
        onChangePagination(1);
    }

    const onFilterPriceHandler = (min_price: string, max_price: string) => {
        if (min_price && max_price) {
            newParam.set("min_price", min_price)
            newParam.set("max_price", max_price)

        } else {
            if (newParam.has("min_price") && newParam.has("max_price")) {
                newParam.delete("min_price")
                newParam.delete("max_price")
            }
        }
        onChangePagination(1)
    }

    const language_data = language
    return (
        <GlobalContext.Provider
            value={{ loading, setLoading, language_data, authState, onFilterPriceHandler, onChangePagination, handleSearch, authDispatch, logOutNow, toastMessage }}>
            {props.children}
            <ToastContainer autoClose={2000} />
        </GlobalContext.Provider>
    )
}

export default GlobalProvider
