import { useEffect, useState } from "react";
import { useDebounce } from "./commonFunction";
import { Select, Spin } from "antd";

const Debounce = (props: any) => {
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [results, setResults] = useState<any[]>([]);
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 1000);

  // Hook
  // T is a generic type for value parameter, our case this will be string
  const searchValue = (value: string) => {
    props?.searchCharacters(value).then((results:any) => {
      setIsSearching(false);
      setResults([...results]);
    });

  }
  // API search results
  // Searching status (whether there is pending API request)
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  // We pass generic type, this case string
  const onChange = (value: string) => {
    setIsSearching(true);
    setSearchTerm(value)
    if (!value) {searchValue('')}
  };
  // Effect for API call

useEffect(()=>{searchValue('')},[])
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        searchValue(debouncedSearchTerm)
        // setResults(results)
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );
  return (
    <Select
      size={props?.size}
      className={`w-100${props?.debounceClass}`}
      labelInValue
      {...props?.others}
      // value={props?.value}
      showSearch
      allowClear
      onSearch={onChange}
      onChange={(e:any) => {
        props?.onChange(e)
      }}
      notFoundContent={isSearching ? <Spin size="small" className="d-block py-2 text-center w-100" /> : <span className="d-block py-2 text-center w-100 text-secondary">No Data Found</span>}
      options={isSearching ? [] : props?.options(results)}
      filterOption={false}
      // onClick={() => searchValue("")}
    />
  )
}

export default Debounce