import '../../assets/styles/auth.scss'
import '../../assets/styles/pages.scss'
import laptop from '../../assets/images/pages/laptop.jpg'
import { Link, useLocation, useMatch } from 'react-router-dom'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import DownloadFileModal from '../../components/common/download-file-modal'
import { GlobalContext, handleError } from '../../context/Provider'
import henceforthApi from '../../utils/henceforthApi'
import PaginationLayout from '../../components/common/PaginationLayout'
import Spinner from '../../components/common/spinner'
import moment from 'moment'
import BreadCrumb from '../../components/common/BreadCrumb'
import { numberWithCommas } from '../../utils/validations'
import { toast } from 'react-toastify'
import AllFilter from '../../components/common/AllFilter'
import COPY from "../../../src/assets/images/copy.png"
import { initLangData } from '../../utils/henceforthLanguage'
import { ProductList } from '../product/productInterface'
import AddCampaign from '../../components/common/AddCampaign'

const Delivery = () => {
    const match: any = useMatch('/delivery/:page')
    const { authState, loading, setLoading, authDispatch, language_data } = useContext(GlobalContext)
    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_delivery} `, url: ``, active: 'not-allowed' }
    ]
    let Limit = 10;
    const location = useLocation();
    const newParam = new URLSearchParams(location.search);

    const [productList, setProductList] = useState({
        totalCount: 0,
        listing: [],
    } as ProductList)
    const exportData = async (startDate: number, endDate: number) => {
        try {
            const apiRes = await henceforthApi.Product.export(startDate, endDate)
            const data = apiRes.data.data
            const rows = [
                [
                    "Sr.",
                    "Product Id",
                    "Category Level 1",
                    "Category level 2",
                    "Brand",
                    "Product Name",
                    "Product Price",
                    "Quantity",
                ],
            ];

            if (Array.isArray(data)) {
                data.map((res: any, index: any) => {
                    return rows.push([
                        index,
                        res?.prodct_id,
                        res?.category_id?.name,
                        res?.subcategory_id?.name,
                        res?.brand_id?.name,
                        res?.name,
                        res?.price,
                        res?.quantity,
                    ])
                })
            }
            let csvContent =
                "data:text/csv;charset=utf-8," +
                rows.map((e) => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `delivery_${moment().valueOf()}.csv`);
            document.body.appendChild(link); // Required for FF
            link.click(); // This will download the data file named "my_data.csv".
            let closeModal = document.getElementById("closeModal");
            if (closeModal) {
                closeModal.click();
            }
        } catch (error) {
            handleError(error, 'active', authDispatch);
        }

    }
    const copyText = (id: string) => {
        if (id) {
            navigator?.clipboard?.writeText(id)
            toast.success(`${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_id} ${language_data?.[authState.lang]?.common_copy} ${language_data?.[authState.lang]?.common_successfull}`)
        }
    }
    const productListing = async () => {
        setLoading(true)
        try {
            let res = (await henceforthApi.Delivery.getDeliveryListing(Number(match?.params.page) - 1, Limit, newParam.toString())).data
            setProductList({
                ...productList,
                totalCount: res.total_count,
                listing: res.data,
            })

        } catch (err: any) {
            if (newParam.has('product_id')) {
                toast.warn(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_search} ${language_data?.[authState.lang]?.common_proper} ${language_data?.[authState.lang]?.common_id}`)
                setProductList({
                    ...productList,
                    listing: [],
                })
            }
            handleError(err, '', authDispatch);
        } finally {
            setLoading(false)
        }
    }
    const initLang = async () => {
        let langData = await initLangData('64466a7579e24437cade01c0', 'ENGLISH')
    }
    useEffect(() => {
        initLang()
    }, [])

    useEffect(() => {
        productListing();
    }, [match?.params.page, newParam.get("search"), newParam.get("product_id"), newParam.get("min_price"), newParam.get("max_price")])

    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* Page  */}
            <div className='page-spacing'>
                <section className='products'>
                    <div className='product-detail-box'>
                        <AllFilter />
                        <div className="common-card">
                            <div className="common-card-title d-flex justify-content-between align-items-center gap-2 flex-column flex-sm-row">
                                <h5>{language_data?.[authState.lang]?.common_within} {language_data?.[authState.lang]?.common_country}</h5>
                                {/* <div>
                                    <button className="btn btn-white btn-sm" type="button" data-bs-toggle="modal" data-bs-target="#compaignModal"> <i className="fa fa-plus me-1"></i>{language_data?.[authState.lang]?.common_add}</button>
                                </div> */}
                            </div>
                            <div className="common-card-content">
                                {/* table */}
                                <div className='data-list-table table-responsive mb-3 text-center'>
                                    {loading ? <Spinner color={"text-success"} />
                                        :
                                        <table className="table table-striped align-middle text-center">
                                            <thead className=''>
                                                <tr>
                                                    <th>{language_data?.[authState.lang]?.common_sr_no}</th>
                                                    <th>{language_data?.[authState.lang]?.common_product}{language_data?.[authState.lang]?.common_id} </th>
                                                    <th>{language_data?.[authState.lang]?.common_category_level} {language_data?.[authState.lang]?._1}</th>
                                                    <th>{language_data?.[authState.lang]?.common_category_level} {language_data?.[authState.lang]?._2}</th>
                                                    <th>{language_data?.[authState.lang]?.common_brand}</th>
                                                    <th>{language_data?.[authState.lang]?.common_product}</th>
                                                    <th>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_price}</th>
                                                    <th>{language_data?.[authState.lang]?.common_quantity}</th>
                                                    <th>{language_data?.[authState.lang]?.common_action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        <div className="btn-group gap-2">
                                                            {/* <Link className="btn btn-white btn-sm" to={`/product/${1212}`}> <i className="fa fa-eye me-1"></i>{language_data?.[authState.lang]?.common_view}</Link> */}
                                                            {/* <Link className="btn btn-white btn-sm" to={`/product/${54848}/edit`}> <i className="fa fa-pencil me-1"></i>{language_data?.[authState.lang]?.common_edit}</Link> */}
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                            {/* <tbody>

                                                {Array.isArray(productList.listing) && productList.listing.length ? productList.listing.map((res, index: number) =>
                                                    <tr key={res?._id}>
                                                        <td> {match?.params.page === "0"
                                                            ? index + 1
                                                            : (Number(match?.params.page) - 1) * Limit + (index + 1)}</td>
                                                        <td>{res?.prodct_id ? res?.prodct_id : `${language_data?.[authState.lang]?.common_not_available}`} <img src={COPY} style={{ width: 15 }} onClick={() => copyText(res.prodct_id)} role="button" data-toggle="tooltip" title={`${res.prodct_id}`} /></td>
                                                        <td>{res?.category_id ? res?.category_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td>{res?.subcategory_id
                                                            ? res?.subcategory_id
                                                                ?.name : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td>{res?.brand_id?.name ? res?.brand_id?.name : ''}</td>
                                                        <td className='product-image-table'>
                                                            <img className='me-2' src={Array.isArray(res?.images) && res?.images?.length ? `${henceforthApi.API_FILE_ROOT_SMALL}${res?.images[0]}` : laptop} alt={res.images[0]} />
                                                            {res?.name?.length > 18 ? <Fragment>{res?.name?.slice(0, 18)}...</Fragment> : res?.name ? res?.name : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td><b>&#36;</b> {res?.price ? numberWithCommas(res?.price) : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td>{res?.quantity !== 0 ? res?.quantity : `${language_data?.[authState.lang]?.common_out_of_stock}`}</td>
                                                        <td>
                                                            <div className="btn-group gap-2">
                                                                <Link className="btn btn-white btn-sm" to={`/product/${res?._id}`}> <i className="fa fa-eye me-1"></i>{language_data?.[authState.lang]?.common_view}</Link>
                                                                <Link className="btn btn-white btn-sm" to={`/product/${res?._id}/edit`}> <i className="fa fa-pencil me-1"></i>{language_data?.[authState.lang]?.common_edit}</Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                                    : <tr className='text-center'><td colSpan={9}>{language_data?.[authState.lang]?.common_no_data_found}</td></tr>}



                                            </tbody> */}
                                        </table>}
                                </div>
                                {/* pagination  */}
                                <div className='dashboad-pagination-box'>
                                    <PaginationLayout
                                        data={productList.listing}
                                        count={productList.totalCount}
                                        limit={Number(Limit)}
                                        page={Number(match?.params.page)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="common-card">
                            <div className="common-card-title d-flex justify-content-between align-items-center gap-2 flex-column flex-sm-row">
                                <h5>{language_data?.[authState.lang]?.common_all} {language_data?.[authState.lang]?.common_over} {language_data?.[authState.lang]?.common_world}</h5>
                                <div>
                                    <button className="btn btn-white btn-sm" type="button" data-bs-toggle="modal" data-bs-target="#compaignModal"> <i className="fa fa-plus me-1"></i>{language_data?.[authState.lang]?.common_add}</button>
                                    {/* <button className="btn btn-white" type="button" data-bs-toggle="modal" data-bs-target="#fileDownloadModal"> <i className='fa fa-cloud-download me-2'></i>.csv</button> */}
                                </div>
                            </div>
                            <div className="common-card-content">
                                {/* table */}
                                <div className='data-list-table table-responsive mb-3 text-center'>
                                    {loading ? <Spinner color={"text-success"} />
                                        :
                                        <table className="table table-striped align-middle text-center">
                                            <thead className=''>
                                                <tr>
                                                    <th>{language_data?.[authState.lang]?.common_sr_no}</th>
                                                    <th>{language_data?.[authState.lang]?.common_product}{language_data?.[authState.lang]?.common_id} </th>
                                                    <th>{language_data?.[authState.lang]?.common_category_level} {language_data?.[authState.lang]?._1}</th>
                                                    <th>{language_data?.[authState.lang]?.common_category_level} {language_data?.[authState.lang]?._2}</th>
                                                    <th>{language_data?.[authState.lang]?.common_brand}</th>
                                                    <th>{language_data?.[authState.lang]?.common_product}</th>
                                                    <th>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_price}</th>
                                                    <th>{language_data?.[authState.lang]?.common_quantity}</th>
                                                    <th>{language_data?.[authState.lang]?.common_action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {Array.isArray(productList.listing) && productList.listing.length ? productList.listing.map((res, index: number) =>
                                                    <tr key={res?._id}>
                                                        <td> {match?.params.page === "0"
                                                            ? index + 1
                                                            : (Number(match?.params.page) - 1) * Limit + (index + 1)}</td>
                                                        <td>{res?.prodct_id ? res?.prodct_id : `${language_data?.[authState.lang]?.common_not_available}`} <img src={COPY} style={{ width: 15 }} onClick={() => copyText(res.prodct_id)} role="button" data-toggle="tooltip" title={`${res.prodct_id}`} /></td>
                                                        <td>{res?.category_id ? res?.category_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td>{res?.subcategory_id
                                                            ? res?.subcategory_id
                                                                ?.name : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td>{res?.brand_id?.name ? res?.brand_id?.name : ''}</td>
                                                        <td className='product-image-table'>
                                                            <img className='me-2' src={Array.isArray(res?.images) && res?.images?.length ? `${henceforthApi.API_FILE_ROOT_SMALL}${res?.images[0]}` : laptop} alt={res.images[0]} />
                                                            {res?.name?.length > 18 ? <Fragment>{res?.name?.slice(0, 18)}...</Fragment> : res?.name ? res?.name : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td><b>&#36;</b> {res?.price ? numberWithCommas(res?.price) : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td>{res?.quantity !== 0 ? res?.quantity : `${language_data?.[authState.lang]?.common_out_of_stock}`}</td>
                                                        <td>
                                                            <div className="btn-group gap-2">
                                                                <Link className="btn btn-white btn-sm" to={`/product/${res?._id}`}> <i className="fa fa-eye me-1"></i>{language_data?.[authState.lang]?.common_view}</Link>
                                                                <Link className="btn btn-white btn-sm" to={`/product/${res?._id}/edit`}> <i className="fa fa-pencil me-1"></i>{language_data?.[authState.lang]?.common_edit}</Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                                    : <tr className='text-center'><td colSpan={9}>{language_data?.[authState.lang]?.common_no_data_found}</td></tr>}



                                            </tbody>
                                        </table>}
                                </div>
                                {/* pagination  */}
                                <div className='dashboad-pagination-box'>
                                    <PaginationLayout
                                        data={productList.listing}
                                        count={productList.totalCount}
                                        limit={Number(Limit)}
                                        page={Number(match?.params.page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <AddCampaign exportData={useMemo(() => exportData, [])} />
            <DownloadFileModal exportData={useMemo(() => exportData, [])} />
        </Fragment>
    )
}
export default Delivery;