import '../../assets/styles/auth.scss'
import '../../assets/styles/pages.scss'
import { Link, useLocation, useMatch, useNavigate, useParams } from 'react-router-dom'
import DownloadFileModal from '../../components/common/download-file-modal'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import profile_placeholder from '../../assets/images/pages/dummy-image.jpg'
import BreadCrumb from '../../components/common/BreadCrumb'
import henceforthApi from '../../utils/henceforthApi'
import { GlobalContext, handleError } from '../../context/Provider'
import AllFilter from '../../components/common/AllFilter'
import PaginationLayout from '../../components/common/PaginationLayout'
import defaultImage from '../../assets/images/pages/defaultImage.jpg'
import moment from 'moment'
import { numberWithCommas } from '../../utils/validations'
import Spinner from '../../components/common/spinner'
import COPY from "../../../src/assets/images/copy.png"
import { toast } from 'react-toastify'
import NODATA from '../../assets/images/no-data-found.svg'
const AllEarnings = () => {
    const { language_data, authState, authDispatch } = useContext(GlobalContext)
    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_all} ${language_data?.[authState.lang]?.common_earning}`, url: ``, active: 'not-allowed' }
    ]
    const limit = 10
    const match = useMatch('/earnings/:page')
    const location = useLocation()
    const newParam = new URLSearchParams(location.search)

    const [transactionList, setTransactionList] = useState({
        total_count: 0,
        data: []
    } as any)
    const [loading, setLoading] = useState(false)
    const transactionListing = async () => {
        setLoading(true)
        try {
            let apiRes = (await henceforthApi.Transaction.getTransactions(Number(match?.params.page) - 1, limit, newParam.toString())).data
            setTransactionList(apiRes)
        } catch (err) {
            handleError(err, '', authDispatch)
        } finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        transactionListing()
    }, [match?.params.page, newParam.get("search"), newParam.get("order_status"), newParam.get("product_id"), newParam.get('payment_status'), newParam.get("min_price"), newParam.get("max_price")])
    const exportData = async (startDate: number, endDate: number) => {
        try {
            const apiRes = await henceforthApi.Transaction.export(startDate, endDate)
            const data = apiRes.data.data
            const rows = [
                [
                    "Sr.",
                    "Order ID",
                    "Transactions ID",
                    "Product ID",
                    "Product Name",
                    "Customer Name",
                    "Date Time",
                    "Order Status",
                    "Order Price",
                    "Earning",
                ],
            ];
            if (Array.isArray(data)) {
                data.map((res: any, index: any) => {
                    return (
                        rows.push([
                            index,
                            res?.order_id,
                            res?.pm_id,
                            res?.product_id?.prodct_id,
                            res?.product_id?.name,
                            res?.user_id?.name,
                            res?.created_at ? moment(Number(res?.created_at)).format('DD MMM YYYY hh:mm:ss') : '',
                            res?.order_status,
                           `$${ numberWithCommas(res?.total_price)}`,
                            `$${res?.total_earnings}`,
                        ]))
                })
            }
            let csvContent =
                "data:text/csv;charset=utf-8," +
                rows.map((e) => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `earnings_${moment().valueOf()}.csv`);
            document.body.appendChild(link); // Required for FF
            link.click(); // This will download the data file named "my_data.csv".
            let closeModal = document.getElementById("closeModal");
            if (closeModal) {
                closeModal.click();
            }
        } catch (err: any) {
            handleError(err, 'active', authDispatch);
        }

    }

    const copyText = (id: string, name: any) => {
        if (id) {
            navigator?.clipboard?.writeText(id)
            toast.success(`${name} ${language_data?.[authState.lang]?.common_copy} ${language_data?.[authState.lang]?.common_successfull}`)
        }
    }
    return (
        <>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* Page  */}
            <div className=' page-spacing'>
                <section className='product-listing'>
                    <div className='product-detail-box'>
                        <AllFilter priceFilter refund="active" refundMarging="true" filters="active" />
                        <div className="common-card">
                            <div className="common-card-title">
                                <h5>{language_data?.[authState.lang]?.common_earning} {language_data?.[authState.lang]?.common_listing}</h5>
                            </div>
                            <div className="common-card-content">

                                {/* <ul className="nav nav-pills mb-3 transactions-tabs" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-completed-tab" data-bs-toggle="pill" data-bs-target="#pills-completed" type="button" role="tab" aria-controls="pills-completed" aria-selected="true">Completed</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-failed-tab" data-bs-toggle="pill" data-bs-target="#pills-failed" type="button" role="tab" aria-controls="pills-failed" aria-selected="false">In Complete</button>
                                    </li>

                                </ul> */}
                                {loading ?
                                    <div className='data-list-table table-responsive mb-3 text-center'>
                                        <Spinner color={"text-success"} />
                                    </div> : <div className='data-list-table table-responsive mb-3'>
                                        {/* table */}
                                        <table className="table table-striped align-middle">
                                            <thead className=''>
                                                <tr>
                                                    <th>{language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_id}</th>
                                                    <th>{language_data?.[authState.lang]?.common_transaction} {language_data?.[authState.lang]?.common_id}</th>
                                                    <th>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_id}</th>
                                                    <th>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_deatil}</th>
                                                    <th>{language_data?.[authState.lang]?.common_customer} {language_data?.[authState.lang]?.common_name}</th>
                                                    <th>{language_data?.[authState.lang]?.common_date} {language_data?.[authState.lang]?.common_time}</th>
                                                    <th>{language_data?.[authState.lang]?.common_status}</th>
                                                    <th>{language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_amount}</th>
                                                    <th>{language_data?.[authState.lang]?.common_earning}</th>
                                                    <th>{language_data?.[authState.lang]?.common_action}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(transactionList.data) && transactionList.data.length ? transactionList.data.map((res: any, index: number) => <tr key={index}>
                                                    <td>{res?.order_id ? res?.order_id : ''} <img src={COPY} style={{ width: 15 }} onClick={() => copyText(res.order_id, "Order Id")} role="button" data-toggle="tooltip" title={res.order_id} /></td>
                                                    <td>{res?.pm_id ? res?.pm_id : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                    <td>{res?.product_id?.prodct_id ? res?.product_id?.prodct_id : `${language_data?.[authState.lang]?.common_not_available}`} <img src={COPY} style={{ width: 15 }} onClick={() => copyText(res.product_id?.prodct_id, "Product Id")} role="button" data-toggle="tooltip" title={res.product_id?.prodct_id} /></td>
                                                    <td className='product-image-table d-flex '>
                                                        <span className=''>
                                                            <img src={res?.product_id?.images[0] ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.product_id.images[0]}` : defaultImage} alt={res.product_id.images[0]} className='me-2' />
                                                        </span>
                                                        <span className='d-flex flex-column justify-content-start'>
                                                            <p>{res?.product_id?.category_id?.name ? res?.product_id?.category_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</p>
                                                            <p>{res?.product_id?.name?.length > 18 ? <Fragment>{res?.product_id?.name?.slice(0, 18)}...</Fragment> : res?.product_id?.name ? res?.product_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</p>
                                                        </span>
                                                    </td>
                                                    <td className='product-image-table'>
                                                        <img src={res?.user_id?.profile_pic ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.user_id?.profile_pic}` : defaultImage} alt="img" className='rounded-circle me-2' />
                                                        <span>{res?.user_id?.name ? res?.user_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span>
                                                    </td>
                                                    {/* <td className='product-image-table'> */}
                                                    <td>{res?.created_at ? moment(Number(res?.created_at)).format('DD MMM YYYY hh:mm:ss') : ''}</td>
                                                    {/* </td> */}
                                                    <td><span className='bg-success badge-width text-white px-2 py-half rounded-half fs-10 fw-semibold'>
                                                        {res?.payment_status ? res?.payment_status : `${language_data?.[authState.lang]?.common_not_available}`}
                                                    </span>
                                                        {/* <span className={`badge-width text-white${res?.payment_status === "PLACED" ? 'text-bg-warning' :
                                                                res?.payment_status === "PENDING_CANCELLATION" ? `text-bg-warning ` :
                                                                    res?.payment_status === "CONFIRMED" ? 'text-bg-success' :
                                                                        res?.payment_status === "DELIVERED" ? 'bg-success' : res?.payment_status === "CANCELLED" ? 'text-bg-danger' :
                                                                            res?.payment_status === "SHIPPED" ? 'text-bg-primary' : ""}  px-2 py-half rounded-half fs-10 fw-semibold`}>
                                                            {res?.payment_status && res?.payment_status === "PENDING_CANCELLATION" ? "PENDING CANCELLATION " : res?.payment_status}</span> */}
                                                    </td>
                                                    <td><b>&#36;</b> {res?.total_price ? numberWithCommas(res?.total_price) : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                    <td><b>&#36;</b> {res?.total_earnings ? numberWithCommas(res?.total_earnings) : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                    <td>
                                                        <div className="btn-group gap-2">
                                                            <Link className="btn btn-white btn-sm" to={`/order/${res?._id}?transaction=true`}> <i className="fa fa-eye me-1"></i>{language_data?.[authState.lang]?.common_view}</Link>
                                                        </div>
                                                    </td>
                                                </tr>) : <tr><td className='text-center py-3' colSpan={10}><img src={NODATA} width="100" /><p className='text-center mt-3'>{language_data?.[authState.lang]?.common_no_data_found}</p></td></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {/* pagination  */}
                                <div className='dashboad-pagination-box'>
                                    <PaginationLayout
                                        count={transactionList.total_count}
                                        data={transactionList.data}
                                        page={Number(match?.params.page)}
                                        limit={Number(limit)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <DownloadFileModal exportData={useMemo(() => exportData, [])} />
        </>
    )
}
export default AllEarnings;