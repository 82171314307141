import '../../assets/styles/auth.scss'
import '../../assets/styles/pages.scss'
import defaultImage from '../../assets/images/pages/defaultImage.jpg'
import { Link, useLocation, useMatch } from 'react-router-dom'
import DownloadFileModal from '../../components/common/download-file-modal'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import PaginationLayout from '../../components/common/PaginationLayout'
import { GlobalContext, handleError } from '../../context/Provider'
import henceforthApi from '../../utils/henceforthApi'
import Spinner from '../../components/common/spinner'
import moment from 'moment'
import BreadCrumb from '../../components/common/BreadCrumb'
import OrderStatus from '../../components/order/OrderStatus'
import AllFilter from '../../components/common/AllFilter'
import COPY from "../../../src/assets/images/copy.png"
import { toast } from 'react-toastify'
import NODATA from '../../assets/images/no-data-found.svg'

const CampaignOrderListing = () => {
    let Limit = 10
    const location = useLocation()
    const { authState, loading, setLoading, language_data, authDispatch } = useContext(GlobalContext)
    const match = useMatch('/orders/campaign/:page')

    let breadCrumbPath = [
        { name: ` ${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: ` ${language_data?.[authState.lang]?.common_campaign} ${language_data?.[authState.lang]?.common_list}`, url: ``, active: 'not-allowed' }
    ]
    const newParam = new URLSearchParams(location.search);
    const [CampaignorderL, setCampaignL] = useState({
        total_count: 0,
        data: []
    } as any)
    const orderListing = async () => {
        setLoading(true)
        try {
            let res = (await henceforthApi.Campaign.getCampaignOrderListing(Number(match?.params.page) - 1, Limit, newParam.toString())).data
            setCampaignL({
                total_count: res.total_count,
                data: res.data
            })
        } catch (err: any) {
            setCampaignL({
                total_count: 0,
                data: []
            })
            handleError(err, 'active', authDispatch);
            // (err)
        } finally {
            setLoading(false)
        }
    }
    const copyText = (id: string, name: string) => {
        if (id) {
            navigator?.clipboard?.writeText(id)
            toast.success(`${name} ${language_data?.[authState.lang]?.common_copy} ${language_data?.[authState.lang]?.common_successfull}`)
        }
    }
    const exportData = async (startDate: number, endDate: number) => {
        try {
            const apiRes = await henceforthApi.Campaign.CampaignOrderExport(startDate, endDate)
            const data = apiRes.data.data
            const rows = [
                [
                    "Sr.No.",
                    "Campaign ID",
                    "Campaign Name",
                    "Organiser Name",
                    "Product ID",
                    "Product Name",
                    "Order Status",
                    "Earning",
                ],
            ];
            if (Array.isArray(data)) {
                data.map((res: any, index: any) => {
                    return (
                        rows.push([
                            index + 1,
                            res?._id,
                            res?.campaign_name,
                            res?.organiser_name,
                            res?.product_detail.prodct_id,
                            res?.product_detail?.name,
                            res?.order_status,
                            res?.total_earnings
                        ]))
                })
            }
            let csvContent =
                "data:text/csv;charset=utf-8," +
                rows.map((e) => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `user_${moment().valueOf()}.csv`);
            document.body.appendChild(link); // Required for FF
            link.click(); // This will download the data file named "my_data.csv".
            let closeModal = document.getElementById("closeModal");
            if (closeModal) {
                closeModal.click();
            }
        } catch (err: any) {
            handleError(err, 'active', authDispatch);
        }
    }

    useEffect(() => {
        orderListing()
    }, [match?.params.page, newParam.get("search"), newParam.get("stock"), newParam.get("start_date"), newParam.get("end_date"), newParam.get('payment_status'), newParam.get("order_status"), newParam.get("product_id"), newParam.get("min_price"), newParam.get("max_price")])
    return (
        <Fragment>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* Page  */}
            <div className=' page-spacing'>
                <section className='products'>
                    <div className='product-detail-box'>
                        <AllFilter stock="active" orderStatus="active" refund="active" date="active" priceFilter filters="active" />
                        <div className="common-card">
                            <div className="common-card-title">
                                <h5>{language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_listing} {language_data?.[authState.lang]?.common_deatil}</h5>
                            </div>
                            <div className="common-card-content">
                                {/* table */}
                                <div className='data-list-table table-responsive mb-4 text-center'>
                                    {loading ? <Spinner color={'text-success'} /> : <table className="table table-striped align-middle">
                                        <thead className=''>
                                            <tr>
                                                <th>{language_data?.[authState.lang]?.common_sr_no}</th>
                                                <th>{language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_id}</th>
                                                <th>{language_data?.[authState.lang]?.common_campaign} {language_data?.[authState.lang]?.common_name}</th>
                                                <th>{language_data?.[authState.lang]?.common_quantity} ({language_data?.[authState.lang]?.common_total}/{language_data?.[authState.lang]?.common_available}) </th>
                                                <th>{language_data?.[authState.lang]?.common_organiser} {language_data?.[authState.lang]?.common_name}</th>
                                                <th>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_name}</th>
                                                <th>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_id}</th>
                                                {/* <th>{language_data?.[authState.lang]?.common_product} {language_data?.[authState.lang]?.common_price}</th> */}
                                                <th>{language_data?.[authState.lang]?.common_order} {language_data?.[authState.lang]?.common_status}</th>
                                                {newParam.has('payment_status') ? <th>{language_data?.[authState.lang]?.common_status}</th> : ''}
                                                <th>{language_data?.[authState.lang]?.common_date}</th>
                                                <th>{language_data?.[authState.lang]?.common_price}</th>
                                                <th>{language_data?.[authState.lang]?.common_earning}</th>
                                                <th>{language_data?.[authState.lang]?.common_action}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(CampaignorderL.data) && CampaignorderL.data.length ? CampaignorderL?.data?.map((res: any, index: number) => {
                                                return (
                                                    <tr key={res._id}>
                                                        <td>{match?.params.page === "0" ? index + 1 : (Number(match?.params.page) - 1) * Limit + (index + 1)}</td>
                                                        <td>{res._id ? res._id : `${language_data?.[authState.lang]?.common_not_available}`} <img src={COPY} style={{ width: 15 }} onClick={() => copyText(res._id, "Campaign Id")} role="button" data-toggle="tooltip" title={res._id} /></td>
                                                        <td className='product-image-table'>
                                                            {/* <img src={res?.image ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.image}` : defaultImage} alt="img" className='rounded-circle me-2' /> */}
                                                            <span>{res?.campaign_name ? res?.campaign_name : `${language_data?.[authState.lang]?.common_not_available}`}</span>
                                                        </td>
                                                        <td>{res?.campaign_total_quantity ? res?.campaign_total_quantity : `${language_data?.[authState.lang]?.common_not_available}`}/{res?.campaign_available_quantity}</td>
                                                        <td className='product-image-table'>
                                                            <img src={res?.organiser_image ? `${henceforthApi.API_FILE_ROOT_SMALL}${res.organiser_image}` : defaultImage} alt="img" className='rounded-circle me-2' />
                                                            <span>{res?.organiser_name ? res?.organiser_name : `${language_data?.[authState.lang]?.common_not_available}`}</span>
                                                        </td>
                                                        <td className='product-image-table'>
                                                            <img src={res?.product_detail.images[0] ? `${henceforthApi.API_FILE_ROOT_SMALL}${res?.product_detail.images[0]}` : defaultImage} alt="img" className='rounded-circle me-2' />
                                                            <span>{res?.product_detail?.name ? res?.product_detail?.name : `${language_data?.[authState.lang]?.common_not_available}`}</span>
                                                        </td>
                                                        <td>{res.product_detail.prodct_id ? res.product_detail.prodct_id : `${language_data?.[authState.lang]?.common_not_available}`} <img src={COPY} style={{ width: 15 }} onClick={() => copyText(res?.product_detail.prodct_id, "Product Id")} role="button" data-toggle="tooltip" title={res?.product_detail.prodct_id} /></td>
                                                        {/* <td><b>&#36;</b> {res?.total_price ? numberWithCommas(res?.total_price) : `${language_data?.[authState.lang]?.common_not_available}`}</td> */}
                                                        <td><OrderStatus {...res} /></td>
                                                        {newParam.has('payment_status') ? <td>{res?.payment_status ? res?.payment_status : `${language_data?.[authState.lang]?.common_out_of_stock}`}</td> : ''}
                                                        <td>{moment(Number(res?.created_at)).format("DD-MM-YYYY")}</td>
                                                        <td><b>&#36;</b>{(res?.total_price)?res?.total_price : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td><b>&#36;</b>{(res?.total_earnings )? res?.total_earnings : `${language_data?.[authState.lang]?.common_not_available}`}</td>
                                                        <td>
                                                            <div className="btn-group gap-2">
                                                                <Link className="btn btn-white btn-sm" to={`/orders/campaign/${res?._id}/${res.campaign_id}/view`}> <i className="fa fa-eye me-1"></i>{language_data?.[authState.lang]?.common_view}</Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr><td className='text-center py-3' colSpan={newParam.has('stock') ? 10 : 9}><img src={NODATA} width="100" /><p className='text-center mt-3'>{language_data?.[authState.lang]?.common_no_data_found}</p></td></tr>
                                            }
                                        </tbody>
                                    </table>}
                                </div>
                                {/* pagination  */}
                                <PaginationLayout
                                    data={CampaignorderL.data}
                                    count={CampaignorderL.total_count}
                                    limit={Number(Limit)}
                                    page={Number(match?.params.page)}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <DownloadFileModal exportData={useMemo(() => exportData, [])} />
        </Fragment>
    )
}
export default CampaignOrderListing;