import moment from "moment"
import { Fragment } from "react"
import henceforthApi from "../../utils/henceforthApi"
import profile_img from '../../assets/images/pages/defaultImage.jpg';
import { innerRating } from "../../pages/product/productInterface";
import { GlobalContext } from "../../context/Provider";
import React from "react";
type ArrayType={
    ratingArray:Array<innerRating>
}
export default (props:ArrayType)=>{
    const {language_data, loading, setLoading, authState, authDispatch } = React.useContext(GlobalContext)
    return  <div className="common-card h-100">
    <div className="common-card-title">
        <h5>{language_data?.[authState.lang]?.common_rating_reviews} </h5>
    </div>
    <div className="common-card-content">
        {/* 1  */}
        {Array.isArray(props?.ratingArray) && props?.ratingArray?.length ? props.ratingArray.map((res, index: number) => {
            let stared = [...Array (Math.round((res?.ratings)))].map((e, i) => <li key={i}><i className="fa fa-star" style={{color:"#315a48"}}></i></li>)
            let staredOff = [...Array(5 - Math.round((res?.ratings)))].map((e, i) => <li key={i}><i className="fa fa-star text-muted"></i></li>)
            return (
                <Fragment key={index}>
                    <div className='rating-box'>
                        <div className="rating-username product-image-table d-flex gap-2 mb-2">
                            <img src={res?.user_id?.profile_pic !== null && !res?.user_id !== null ? `${henceforthApi.API_FILE_ROOT_MEDIUM}${res?.user_id?.profile_pic}` : profile_img} className="border rounded-circle" alt="img" />
                            <div>
                                <p className='fw-bold'>{res?.user_id?.name ? res?.user_id?.name : `${language_data?.[authState.lang]?.common_not_available}`}</p>
                                <ul className='list-unstyled d-flex gap-1 rating-icons m-0'>
                                    {stared}{staredOff}
                                </ul>
                            </div>
                        </div>
                        <div>

                            <p className='fw-bold'>{res?.title ? res?.title : `${language_data?.[authState.lang]?.common_not_available}`}</p>
                            <p className='my-1'> {res?.description ? res?.description : `${language_data?.[authState.lang]?.common_not_available}`} </p>
                            <p className='fw-bold'>{res?.created_at ? moment(Number(res?.created_at)).format('MMMM Do YYYY, h:mm:ss a') : `${language_data?.[authState.lang]?.common_not_available}`}</p>
                        </div>
                    </div>
                    <div className='divider my-3'></div>
                </Fragment>
            )
        }) : <p>{language_data?.[authState.lang]?.common_not_available} </p>}
    </div>
</div>
}