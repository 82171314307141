import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ ...props }) => {
    const handleChange = (e: any) => {
        props.onChange(e)
    }
    return <ReactQuill theme="snow" value={props?.value as any} onChange={handleChange} />;
}

export default TextEditor;