import React, { Fragment, useContext, useRef, useState } from 'react';
import moment from 'moment'
import Spinner from './spinner';
import { GlobalContext } from '../../context/Provider';
const AddDurationModal = ({ AddDurationModal }: any) => {
    const { authState, language_data } = useContext(GlobalContext)
    const [days, setDays] = useState<any>('')
    const [endDate, setEndDate] = useState<number>(moment().toDate().getTime())
    const btnRef = useRef(null) as React.MutableRefObject<any>
    const [loading, setLoading] = useState(false)

    const AddDuration = async () => {
        setLoading(true)
        await AddDurationModal(days)
        setLoading(false)
        if(days > 0 && days <= 7 ){
            btnRef?.current.click()
        }
       
    }

    return (
        <Fragment>
            <div className="modal fade" id="AddDurationModal" tabIndex={-1} aria-labelledby="fileDownloadModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header position-relative">
                            {/* Title  */}
                            <h5 className="modal-title fs-5 d-block w-100 text-center" id="exampleModalLabel">{language_data?.[authState.lang]?.common_add} {language_data?.[authState.lang]?.common_maximum} {language_data?.[authState.lang]?.common_duration}</h5>
                            <div className='close-modal-button'>
                                <button type="button" ref={btnRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <div className="modal-body form-fields-box">
                            <div className="row">
                                {/* from Date  */}
                                <div className="col-md-12">
                                    <label htmlFor="" className='fw-semibold mb-2'>{language_data?.[authState.lang]?.common_maximum} {language_data?.[authState.lang]?.common_days}:</label>
                                    <input type="text" maxLength={1} autoComplete='off' name='start_date' onKeyPress={(event:any)=> {if (!/[1-9]/.test(event.key)) {
                                        event.preventDefault();
                                        }}} placeholder={`${language_data?.[authState.lang]?.common_maximum} ${language_data?.[authState.lang]?.common_days}`}  className='form-control rounded-0' value={days} onChange={(e) =>  setDays(e.target.value)} />
                                </div>
                                {/* To date  */}
                                {/* <div className="col-md-6">
                                    <label htmlFor="" className='fw-semibold'>{language_data?.[authState.lang]?.common_to} {language_data?.[authState.lang]?.common_date}</label>
                                    <input type="date" name='start_date' max={new Date().toISOString().split('T')[0]} className='form-control rounded-0' value={moment(endDate).format('YYYY-MM-DD')} onChange={(e) => setEndDate(e.target.valueAsNumber)} />
                                </div> */}
                            </div>
                        </div>
                        {/* Downoad Button  */}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-white bg-danger text-white m-0 me-3" data-bs-dismiss="modal" disabled={loading}>{language_data?.[authState.lang]?.common_cancel}</button>
                            <button className='btn btn-theme m-0' type='button' onClick={AddDuration} disabled={loading}>{loading ? <Spinner color={"text-white"} /> : `${language_data?.[authState.lang]?.common_add}`}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default React.memo(AddDurationModal);