import '../../../assets/styles/pages.scss'
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { NumberValidation } from '../../../utils/validations';
import Errormessage from '../../../components/common/errormessage';
import BreadCrumb from '../../../components/common/BreadCrumb';
import { useMatch } from 'react-router-dom';
import { deliveryType } from '../productInterface';
import { GlobalContext, handleError } from '../../../context/Provider';
import henceforthApi from '../../../utils/henceforthApi';
import Spinner from '../../../components/common/spinner';
import GoogleMapReact from "google-map-react";
import Inputs, { SaveCancelButton } from '../../../components/common/Inputs';
import commonArray from '../../../components/common/commonArray';
import { toast } from 'react-toastify';

const NEXT_PUBLIC_GOOGLE_API_KEY = "AIzaSyBBZGsH8PUhQwvuwHjl7KOtvNYE_rE00ww"
var userMarker = null as any;
var cityCircle = null as any;
const defaultProps = {
    center: {
        lat: -37.82168417163056,
        lng: 145.05854992101723
    },
    zoom: 12
};

const AddDelivery = () => {
    const match = useMatch('product/:product_id/delivery/add')
    const {language_data, authState, loading, setLoading, authDispatch } = useContext(GlobalContext)
    
    let breadCrumbPath = [
        { name: `${language_data?.[authState.lang]?.common_home}`, url: `/`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_list}`, url: `/products/1`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_product} ${language_data?.[authState.lang]?.common_deatil}`, url: `/product/${match?.params.product_id}`, active: '' },
        { name: `${language_data?.[authState.lang]?.common_add} ${language_data?.[authState.lang]?.common_delivery}`, url: ``, active: 'not-allowed' }
    ]
    const [addDelivery, setAddDelivery] = useState({
        location: '',
        lat: "",
        lng: "",
        deliveryTime: '',
        radius: ''
    } as deliveryType)
    const [radiusErr, setRadiusErr] = useState<string>('')
    const [locationErr, setLocationErr] = useState<string>('')
    const [deliveryErr, setDeliveryErr] = useState<string>('')
    const [onAddressChanged, setOnAddressChanged] = useState(defaultProps)
    const googleMapRef = useRef(null) as React.MutableRefObject<any>
    const placeInputRef = useRef(null) as React.MutableRefObject<any>
    const handleChange = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'radius' && String(value).startsWith('0')) return
        if (name === 'deliveryTime' && String(value).startsWith('0')) return
        if (name === "radius" && !NumberValidation(value) && !value.includes('.')) return 
        if (name === "radius" && Number(value) > 12800) return 
        if (name === "deliveryTime" && !NumberValidation(value)) return 
        if (name === "deliveryTime" && Number(value) > 10) return 
        if (name === 'location') checkLocationEdit()
        else if (name === "radius") setRadiusErr('')
        else if (name === "deliveryTime") setDeliveryErr('')
        setAddDelivery({
            ...addDelivery,
            [name]: value
        })
    }
    const submitHanlder = async (e: any) => {
        e.preventDefault();
        if (!addDelivery.radius && !addDelivery.location.trim() && !addDelivery.deliveryTime.trim()) {
            setRadiusErr(`${language_data?.[authState.lang]?.common_radius} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            setLocationErr(`${language_data?.[authState.lang]?.common_location} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            setDeliveryErr(`${language_data?.[authState.lang]?.common_delivery} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
            return
        }

        if (!addDelivery.location.trim()) return setLocationErr(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_select} ${language_data?.[authState.lang]?.common_nearest} ${language_data?.[authState.lang]?.common_place}`)
        if (locationErr.trim()) return setLocationErr(`${language_data?.[authState.lang]?.common_please} ${language_data?.[authState.lang]?.common_select} ${language_data?.[authState.lang]?.common_location} ${language_data?.[authState.lang]?.common_from} ${language_data?.[authState.lang]?.common_search} ${language_data?.[authState.lang]?.common_list}`)
        if (!addDelivery.radius) return setRadiusErr(`${language_data?.[authState.lang]?.common_radius} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
        if (!addDelivery.deliveryTime) return setDeliveryErr(`${language_data?.[authState.lang]?.common_delivery} ${language_data?.[authState.lang]?.common_time} ${language_data?.[authState.lang]?.common_is} ${language_data?.[authState.lang]?.common_required}`)
        let items = {
            product_id: match?.params.product_id,
            radius: addDelivery.radius,
            address: addDelivery.location,
            lat: addDelivery.lat,
            lng: addDelivery.lng,
            delivery_time: addDelivery.deliveryTime,
            units: "Km",
            language: "ENGLISH"
        }
        setLoading(true)
        try {
            await henceforthApi.Product.addProductDetailsDynamically(commonArray.productOtherDetails[5],items);
            toast.success("Add Delivery Added Successfully")
            window.history.back()
        } catch (err) {
            handleError(err, 'active', authDispatch)

        } finally {
            setLoading(false)
        }
    }
    const onKeyDown = (keyEvent: any) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    const onGoogleApiLoaded = ({ map, maps, ref }: any) => {
        initPlaceAPI()
    }
    const initPlaceAPI = () => {
        if (placeInputRef) {
            let autocomplete = new (window as any).google.maps.places.Autocomplete(
                placeInputRef.current
            );
            new (window as any).google.maps.event.addListener(
                autocomplete,
                "place_changed",
                () => {
                    let place = autocomplete.getPlace();
                    let formatAddress = place.formatted_address
                    const address = place.address_components
                    let items: any = {}
                    if (Array.isArray(address) && address.length > 0) {
                        let zipIndex = address.findIndex(res => res.types.includes("postal_code"))
                        let administrativeAreaIndex = address.findIndex(res => res.types.includes("administrative_area_level_1", "political"))
                        let localityIndex = address.findIndex(res => res.types.includes("locality", "political"))
                        let countryIndex = address.findIndex(res => res.types.includes("country", "political"))
                        let premiseIndex = address.findIndex(res => res.types.includes("premise", "street_number"))

                        if (zipIndex > -1) {
                            items.pin_code = address[zipIndex].long_name
                        }
                        if (administrativeAreaIndex > -1) {
                            items.state = address[administrativeAreaIndex].long_name
                        }
                        if (localityIndex > -1) {
                            items.city = address[localityIndex].long_name
                        }
                        if (countryIndex > -1) {
                            items.country = address[countryIndex].long_name
                        }
                        if (premiseIndex > -1) {
                            items.apartment_number = address[premiseIndex].long_name
                        }
                        items.full_address = formatAddress
                        setAddDelivery((addDelivery) => {
                            return {
                                ...addDelivery,
                                location: items?.full_address ? items?.full_address : `${items.city},${items.state},${items.country}`,
                                lat: place.geometry?.location.lat(),
                                lng: place.geometry?.location.lng(),
                            }
                        })
                        setLocationErr('')
                    }

                }
            );
        }
    }

    const createMerker = (position: any, map: any, icon?: any) => {
        return new (window as any).google.maps.Marker({
            position,
            map,
            draggable: false,
            label: `Delivery area`,
            icon: icon
        });
    }
    const createCircle = (radius: number) => {
        return new (window as any).google.maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map: googleMapRef.current.map_,
            center: userMarker.getPosition(),
            radius,
        });
    }
    const createRadius = async () => {
        const latlng = { lat: Number(addDelivery?.lat), lng: Number(addDelivery?.lng) }
        const position = new (window as any).google.maps.LatLng(latlng)
        if (userMarker) {
            userMarker.setMap(null)
        } if (cityCircle) {
            cityCircle.setMap(null)
        }
        const radius = addDelivery.radius ? Number(addDelivery.radius) * 1000 : 0
        userMarker = createMerker(position, googleMapRef.current.map_)
        cityCircle = createCircle(radius)

        userMarker.addListener('dragend', async (e: any,) => {
            cityCircle.setCenter(userMarker?.getPosition() as any)

        });
        setOnAddressChanged({
            ...onAddressChanged,
            center: latlng,
            zoom: getZoomLevel(cityCircle)
        })
    }
    const getZoomLevel = (circle: any) => {
        let zoomLevel = 0;
        if (circle != null) {
            let radius = circle.getRadius();
            let scale = radius / 500;
            zoomLevel = (16 - Math.log(scale) / Math.log(2));
        }
        return zoomLevel - .4;
    }

    useEffect(() => {
        createRadius()
    }, [addDelivery.lat, addDelivery.lng, addDelivery.radius])

    const checkLocationEdit = () => {
        if (addDelivery.location) {
            setLocationErr(`${language_data?.[authState.lang]?.common_please}${language_data?.[authState.lang]?.common_select}${language_data?.[authState.lang]?.common_your}${language_data?.[authState.lang]?.common_address}${language_data?.[authState.lang]?.common_again}`)
        } else {
            setLocationErr('')
        }
    }
    return (
        <Fragment>
            <BreadCrumb pathNameDeclare={breadCrumbPath} />
            {/* page  */}
            <div className='page-spacing'>
                <section className='product-detail'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4">
                                {/* Title  */}
                                <div className="common-card">
                                    <div className="common-card-title">
                                        <h5>{language_data?.[authState.lang]?.common_add} {language_data?.[authState.lang]?.common_delivery}</h5>
                                    </div>
                                    {/* Profile  */}
                                    <div className="common-card-content">
                                        <form onKeyDown={onKeyDown} onSubmit={submitHanlder}>
                                            {/*  Location  */}
                                            <Inputs.InputRef type="search" error={locationErr} label={`${language_data?.[authState.lang]?.common_location}`} placeholder={`${language_data?.[authState.lang]?.common_search} ${language_data?.[authState.lang]?.common_location}`} name='location' value={addDelivery.location} ref={placeInputRef} handleChange={handleChange} />
                                            {/*  Radius  */}
                                            <Inputs.Input type="text" label={`${language_data?.[authState.lang]?.common_radius}`} error={radiusErr} value={addDelivery.radius} placeholder={`${language_data?.[authState.lang]?.common_radius}`} name='radius' handleChange={handleChange} />
                                            {/* Delivery Time  */}
                                            <Inputs.Input type="text" label={`${language_data?.[authState.lang]?.common_delivery} ${language_data?.[authState.lang]?.common_time} (${language_data?.[authState.lang]?.common_time})`} error={deliveryErr} value={addDelivery.deliveryTime} maxLength={2} placeholder={`${language_data?.[authState.lang]?.common_delivery} ${language_data?.[authState.lang]?.common_time}`} name='deliveryTime' handleChange={handleChange} />
                                            {/* Buttons  */}
                                            <SaveCancelButton loading={loading} color="text-white" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <GoogleMapReact
                                    ref={googleMapRef}
                                    bootstrapURLKeys={{
                                        key: NEXT_PUBLIC_GOOGLE_API_KEY,
                                        libraries: ['places']
                                    }}
                                    defaultCenter={defaultProps.center}
                                    center={onAddressChanged.center}
                                    zoom={onAddressChanged.zoom}
                                    defaultZoom={defaultProps.zoom}
                                    onGoogleApiLoaded={onGoogleApiLoaded}
                                >
                                </GoogleMapReact>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Fragment>
    )
}
export default AddDelivery;