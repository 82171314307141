import React, { Fragment, useContext } from "react";
import { GlobalContext } from "../../context/Provider";
import Errormessage from "./errormessage";
import Spinner from "./spinner";

const Input = (props: any) => {
    const {language_data, authState, } = useContext(GlobalContext);
    return <div className="form-fields-box mb-3 position-relative">
        {props.label ? <label htmlFor={props.id} className='fw-bolder mb-1'>{props.label}</label> : ''}
        <input type={props.type} id={props.id} className={`form-control rounded-0 ${props.error ? 'is-invalid' : ''}`} name={props.name} placeholder={props.placeholder} maxLength={props.maxLength} onKeyPress={props.onKeyPress}  value={props.value} onChange={(e) => {if(props?.handleChange) props?.handleChange(e) }} disabled={props.disabled} />
        {props.email_verified ? <i className="fa fa-check email_verified" aria-hidden="true"></i> : ''}
        {props.error ? <Errormessage class={props.error} error={props.error} /> : ''}
    </div>
}
const InputRef = React.forwardRef((props: any, ref: any) => <div className="form-fields-box mb-3">
    {props.label ? <label htmlFor={props.id} className='fw-bolder mb-1'>{props.label}</label> : ''}
    <input type={props.type} id={props.id} ref={ref} className={`form-control rounded-0 ${props.error ? 'is-invalid' : ''}`} name={props.name} placeholder={props.placeholder} value={props.value} onChange={(e) => {if(props?.handleChange) props?.handleChange(e) }} maxLength={props.maxLength} />
    {props.error ? <Errormessage class={props.error} error={props.error} /> : ''}
</div>
)
const InputWButton = (props: any) => {
    return <Fragment>
        <div className="row">
            <div className="col-md-12">
                <h5 className='fw-bolder mb-3'>{props.label}</h5>
            </div>
        </div>
        <div className="form-fields-box mb-4">
            <form className="form-fields-box d-flex gap-2 mb-3" onSubmit={(e) => { e.preventDefault(); props.onChangeHighlights(null); }}>
                <input id={props.id} type={props.text} className={`form-control rounded-0 ${props.error ? 'is-invalid' : ''}`} placeholder={props.placeholder} name={props.name}
                    value={props.value ? props.value : ''}
                    onChange={({ target }) => { props.onChangeInputHandler(target.name, target.value) }} />
                <button type="submit" className="btn btn-theme" disabled={!props.value.trim()}> <i className='fa fa-plus text-white'></i> </button>
            </form>
            <Errormessage phone={props.error} error={props.error} />
            {/* Added multiplevalues*/}
            <ul className='ps-0 mb-0 list-unstyled add-product-list-spacing mt-3'>
                {[...props.Array].map((res: any, index: any) =>
                    <li key={index} className="d-flex justify-content-between flex-column flex-md-row gap-2">
                        <span className="d-flex gap-1"><i className="fa fa-list fs-5 align-middle me-2 d-none d-sm-inline-flex" aria-hidden="true"></i><span>{res}</span></span>
                        <button className='btn btn-sm action-btn action-delete-button'> <i className='fa fa-trash text-danger fs-6' role="button" onClick={() => props.onChangeHighlights(index)}></i></button>
                    </li>
                )}
            </ul>
        </div>
    </Fragment>
}
const InputWEye = (props: any) => <div className='form-fields-box mb-3'>
    <div className="input-group">
        <input type={props.showHide ? "password" : "text"}
            name={props.name}
            onChange={(e) => {if(props?.handleInput) props?.handleInput(e)}}
            value={props.value}
            className={`form-control rounded-0 border-end-0 ${props.error ? 'is-invalid' : ''}`} placeholder={props.placeholder} />
        <div className="input-group-append">
            <span className={`input-group-text group-btn-span ${props.error ? 'border border-danger' : ''}`} onClick={() => props.onClick(props.selectEye)}> <i
                className={`fa ${props.showHide ? `fa-eye ${props.error ? 'text-danger' : 'text-secondary'}` : `fa-eye-slash ${props.error ? 'text-danger' : 'text-secondary'}`}`}
                aria-hidden="true"
            ></i></span>
        </div>
        <Errormessage class={props.error} error={props.error} />
    </div>
</div>
export default {
    Input,
    InputWButton,
    InputRef,
    InputWEye
}
export const SaveCancelButton = (props: any) => {
    const {language_data, authState, } = useContext(GlobalContext);
    return <div className="signin-button-box mt-3">
        <ul className="list-unstyled d-flex gap-2">
            <li className="w-100">
                <button type="button" className="btn btn-white w-100 bg-danger text-white" onClick={() => window.history.back()}>{`${language_data?.[authState.lang]?.common_cancel}`}</button>
            </li>
            <li className="w-100"> <button type="submit" className="btn btn-theme w-100" disabled={props.loading} onClick={props.onClick}>{props.loading ? <Spinner color={props.color} /> : <><i className='fa fa-save me-2'></i>{language_data?.[authState.lang]?.common_save}</>}</button></li>
        </ul>
    </div>
}