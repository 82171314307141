import { Fragment, useContext } from 'react';
import './App.css'
import {
  Routes,
  Route
} from "react-router-dom";
import Home from './pages/home'
import Signup from './pages/auth/signup'
import Signin from './pages/auth/signin'
import ForgotPassword from './pages/auth/forgot-password'
import SetPassword from './pages/auth/set-password';
// import MainActivity from './element/MainActivity';
import TermsActivity from './element/TermsActivity';
import TheLayout from './layouts/TheLayout';
import ChangePassword from './pages/auth/change-password';
import EditProfile from './pages/auth/edit-profile';
import { GlobalContext } from './context/Provider';
import Profile from './pages/auth/profile';
import OrderListing from './pages/order/order-listing';
import ViewProductDetail from './pages/product/product-detail';
import CouponListing from './pages/coupon/coupon-listing';
import AddCoupon from './pages/coupon/add-coupon';
import EditCoupon from './pages/coupon/edit-coupon';
import AddProduct from './pages/product/product-add';
import EditProduct from './pages/product/product-edit';
import AddVariant from './pages/product/variant/add-variant';
import EditVariant from './pages/product/variant/edit-variant';
import AddDelivery from './pages/product/delivery/add-delivery';
import AddProductFaq from './pages/product/faq/add-product-faq';
import ViewOrderDetail from './pages/order/order-detail';
import Products from './pages/product/products';
import AddHighlight from './pages/product/highlight/add-highlight';
import EditHighlight from './pages/product/highlight/edit-highlight';
import AddSpecification from './pages/product/specification/add-specification';
import EdiSpecification from './pages/product/specification/edit-specification';
import EditService from './pages/product/services/edit-service';
import CustomerInvoice from './pages/order/customer-invoice';
import OtpVerification from './pages/auth/otp-verification';
import EditDelivery from './pages/product/delivery/edit-delivery';
import EditProductFaq from './pages/product/faq/edit-product-faq';
import AddService from './pages/product/services/add-service';
import LoginWithUser from './pages/LoginWithUser';
import AllEarnings from './pages/transaction/transaction';
import CloneProduct from './pages/product/productclone';
import OrderCancellationList from './pages/order/order-cancelled-list';
import RatingReview from './pages/Rating&Review/rating&review';
import OrderCancelSeller from './pages/order/ordercancelseller';
import Campaign from './pages/campaign/campaign-listing';
import Delivery from './pages/delivery';
import CampaignDetails from './pages/campaign/campaign-detail';
import CampaignOrderListing from './pages/order/campaign-order-listing';
import CampaignOrderDetail from './pages/order/campaign-order-detail';
import ForumListing from './pages/forum/forum-listing';
import FroumDetails from './pages/forum/forum-details';
import CampaignRequest from './pages/campaign/campaign-requests';
import CampaignRequestDetail from './pages/campaign/campaign-request-details';
function App() {
  const { authState } = useContext(GlobalContext);

  return (
    <Fragment>   {authState.access_token ?
      <Routes>
        <Route path="*" element={<TheLayout />}>
          <Route index element={<Home />} />
          {/***************** Profile ****************/}
          <Route path='profile' element={<Profile />} />
          <Route path='change-password' element={<ChangePassword />} />
          <Route path='profile/edit' element={<EditProfile />} />

          {/***************** Transactions ****************/}
          <Route path="orders/transactions/:page" element={<AllEarnings />} />
          {/***************** Product ****************/}
          <Route path="products/:page" element={<Products />} />
          <Route path="product/:id" element={<ViewProductDetail />} />
          <Route path="product/add" element={<AddProduct />} />
          <Route path="product/clone/:id" element={<CloneProduct />} />
          <Route path="product/:id/edit" element={<EditProduct />} />
          <Route path="product/:id/highlight/add" element={<AddHighlight />} />
          <Route path="product/:id/highlight/:highlight_id/edit" element={<EditHighlight />} />

          <Route path="product/:id/specification/add" element={<AddSpecification />} />
          <Route path="product/:id/specification/:specification_id/edit" element={<EdiSpecification />} />

          <Route path="product/:id/variant/add" element={<AddVariant />} />
          <Route path="product/:id/variant/:variant_id/edit" element={<EditVariant />} />

          <Route path="product/:product_id/delivery/add" element={<AddDelivery />} />
          <Route path="product/:product_id/delivery/edit/:delivery_id" element={<EditDelivery />} />
          <Route path="product/:product_id/faq/add" element={<AddProductFaq />} />
          <Route path="product/:product_id/faq/edit/:faq_id" element={<EditProductFaq />} />
          <Route path="product/:product_id/services/add" element={<AddService />} />
          <Route path="product/:product_id/services/edit/:service_id" element={<EditService />} />

          {/***************** campaign ****************/}
          <Route path="campaign/:page" element={<Campaign />} />
          <Route path='campaign/:id/view' element={<CampaignDetails />} />
          {/***************** Order ****************/}
          <Route path="orders/:page" element={<OrderListing />} />
          <Route path="order/:id" element={<ViewOrderDetail />} />
          <Route path="order/cancel/:id/:order_id" element={<OrderCancelSeller />} />
          <Route path="orders/cancellation/requests/:id" element={<OrderCancellationList />} />
          {/***************** campaign Order ****************/}
          <Route path="orders/campaign/:page" element={<CampaignOrderListing />} />
          <Route path="orders/campaign/:id/:campaignid/view" element={<CampaignOrderDetail />} />
          <Route path="campaign-requests/:page" element={<CampaignRequest />} />
          <Route path="campaign-requests/:id/view" element={<CampaignRequestDetail />} />
          <Route path="campaign-order/:id" element={<CampaignOrderDetail />} />

          {/***************** Order Rating & Reviews ****************/}
          <Route path="ratings/:page" element={<RatingReview />} />
          {/***************** forum ****************/}
          <Route path="forums/:page" element={<ForumListing />} />
          <Route path='forum/:id' element={<FroumDetails />} />
          {/***************** Delivery ****************/}
          <Route path="delivery/:page" element={<Delivery />} />
          {/***************** Coupon Section ****************/}
          <Route path="coupons/:page" element={<CouponListing />} />
          <Route path="coupon/add" element={<AddCoupon />} />
          <Route path="coupon/:id/edit" element={<EditCoupon />} />
          {/***************** Transaction ****************/}
          <Route path="earnings/:page" element={<AllEarnings />} />
          {/***************** Admin Login ****************/}
          <Route path="login-with-user/:code/:access_token" element={<LoginWithUser />} />
        </Route>
        {/***************** Order Invoice ****************/}
        <Route path="order/:id/invoice" element={<CustomerInvoice />} />
      </Routes>
      :
      <Routes>
        {/* <Route index element={<MainActivity />} /> */}
        <Route path="terms" element={<TermsActivity />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/otp-verification" element={<OtpVerification />} />
        <Route path="/password/reset/" element={<SetPassword />} />
        <Route path="login-with-user/:code/:access_token" element={<LoginWithUser />} />
        <Route path="*" element={<Signin />} />
      </Routes>
    }
    </Fragment>
  )
}

export default App


