import '../../assets/styles/auth.scss'
import logo from '../../assets/images/logo/logo.png'
import { Link, useNavigate } from 'react-router-dom';
import { Fragment, useContext, useState } from 'react';
import henceforthApi from '../../utils/henceforthApi';
import { GlobalContext, handleError } from '../../context/Provider';
import Spinner from '../../components/common/spinner';
import { toast } from 'react-toastify';
import Errormessage from '../../components/common/errormessage';
import { validateEmail } from '../../utils/validations';
import Inputs from '../../components/common/Inputs';
const ForgotPassword = () => {
    const { language_data, authState, loading, setLoading, toastMessage, authDispatch } = useContext(GlobalContext)
    const [email, setEmail] = useState("")
    const [emailErr, setEmailErr] = useState("")
    const navigate: any = useNavigate()
    const ForgotPassword = async () => {
        if (!email.trim()) { return setEmailErr("email is required") }
        if (!validateEmail(email)) {
            return setEmailErr("please provide valid email");
        }
        setLoading(true)
        try {
            let emailRes = await henceforthApi.Auth.forgotPassword({ email: email, language: "ENGLISH" })
            navigate({
                pathname: '/otp-verification',
                search: `?unicode=${emailRes?.data?.unique_code}`,
            })
            toastMessage(emailRes?.data?.message)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            handleError(err, 'active', authDispatch);
        }
    }
    const handleChange = (e: any) => {
        setEmail(e.target.value)
        setEmailErr('')
    }
    return (
        <Fragment>
            <section className='auth-pages'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-7 col-sm-10">
                            <div className="auth-page-card mb-4">
                                {/* logo */}
                                <div className='auth-logo-box text-center'>
                                    <h2 className='fw-bold'>Quantity Savers</h2>
                                    {/* <img src={logo} alt="img" /> */}
                                </div>
                                {/* title  */}
                                <div className='auth-page-card-title text-center my-4'>
                                    <h3>Forgot password.</h3>
                                </div>
                                
                                {/* form  */}
                                <form onSubmit={(e) => { e.preventDefault(); ForgotPassword() }} >
                                    {/* Email  */}
                                    <Inputs.Input type="text" error={emailErr} value={email} placeholder="Email" name='email' handleChange={handleChange} />
                                    {/* Submit button  */}
                                    <div className='form-fields-box'>
                                        <button type='submit' className='btn btn-theme w-100' disabled={loading}>
                                            {loading ? <Spinner color={"text-white"} /> : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            {/* Have Account  */}
                            <div className='have-account-box'>
                                <div className='form-fields-box text-center mb-3'>
                                    <p>Already have an account?</p>
                                </div>
                                {/* Login button  */}
                                <div className='form-fields-box'>
                                    <Link to="/signin" className='btn btn-white w-100'>Sign In</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </Fragment>
    );
};

export default ForgotPassword;
