import '../../assets/styles/auth.scss'
import logo from '../../assets/images/logo/logo.png'
import { Link, useNavigate } from 'react-router-dom';
import { Fragment, useContext, useState } from 'react';
import loginSuccess from "../../context/actions/auth/loginSuccess";
import henceforthApi from '../../utils/henceforthApi';
import { GlobalContext, handleError } from '../../context/Provider';
import { validateEmail } from '../../utils/validations';
import Spinner from '../../components/common/spinner';
import Errormessage from '../../components/common/errormessage';
import { requestNotification } from '../../utils/firebase';
import henceofrthEnums from '../../utils/henceofrthEnums';
const SignIn = ({ logOutNow }: any) => {
    const navigate = useNavigate()
    const { authDispatch, toastMessage,language_data,authState } = useContext(GlobalContext);
    const [emailErr, setEmailErr] = useState('')
    const [passwordErr, setPasswordErr] = useState('')
    const [firstEye, setFirstEye] = useState(true);
    const [state, setState] = useState({
        email: "",
        password: "",
        loading: false,
    });
    const handleInput = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'email') {
            setEmailErr('')
        }
        if (name === 'password') {
            setPasswordErr('')
        }
        setState({
            ...state,
            [name]: value,
        });

    };

    const onSubmitForm = async (e: any) => {
        e.preventDefault();
        debugger
        if (!state.email && !state.password) {
            setEmailErr("email is required");
            setPasswordErr("password is required")
        }
        if (!state.email.trim()) return setEmailErr("email is required")
        if (!validateEmail(state.email)) return setEmailErr("please fill valid email")
        if (!state.password) return setPasswordErr("password is required");
        if (!state.password.trim()) return setPasswordErr("please fill valid password");
        const { email, password } = state;
        let fcmid = await requestNotification()
        let items = {
            email: email.toLowerCase(),
            password: password,
            language: "ENGLISH",
        } as any;
        if (fcmid) items['fcm_token'] = fcmid
        setState({
            ...state,
            loading: true,
        });
        try {
            let res = await henceforthApi.Auth.login(items)
            loginSuccess({...res.data,lang:henceofrthEnums.Language.ENGLISH})(authDispatch);
            henceforthApi.setToken(res.data.access_token)
            henceforthApi.languageChange(henceofrthEnums.Language.ENGLISH)
            navigate('/')
            toastMessage("Login Successfully");
        } catch (err: any) {
            handleError(err, 'active', authDispatch);
            console.log(err,"error")
        }
        finally {
            setState({
                ...state,
                loading: false,
            });
        }
    }
    return (
        <Fragment>
            <section className='auth-pages'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-7 col-sm-10">
                            <div className="auth-page-card mb-4">
                                {/* logo */}
                                <div className='auth-logo-box text-center'>
                                <h2 className='fw-bold'>Quantity Savers</h2>
                                    {/* <img src={logo} alt="img" /> */}
                                </div>
                                {/* title  */}
                                <div className='auth-page-card-title text-center my-4'>
                                    <h3 className='mb-2'>Login to Quantity Savers</h3>
                                    <p>to action.</p>
                                </div>
                                {/* form  */}
                                <form onSubmit={onSubmitForm}>
                                    {/* Username  */}
                                    <div className='form-fields-box mb-3'>
                                        <input type="text"
                                            name="email"
                                            value={state.email}
                                            onChange={handleInput}
                                            className={`form-control rounded-0 ${emailErr ? 'is-invalid' : ''}`} placeholder="Email"/>
                                        {/* error msg  */}
                                        <Errormessage class={emailErr} error={emailErr} />
                                    </div>
                                    {/* Password  */}
                                    <div className='form-fields-box mb-4'>
                                        <div className="input-group is-invalid">
                                            <input type={firstEye ? "password" : "text"}
                                                name="password"
                                                onChange={handleInput}
                                                value={state.password}
                                                className={`form-control rounded-0 ${passwordErr ? 'is-invalid' : ''}`} placeholder="password"
                                            />
                                            <div className="input-group-append">
                                                <span className={`input-group-text group-btn-span ${passwordErr ? 'border border-danger' : ''}`} onClick={() => setFirstEye(!firstEye)}> <i
                                                    className={`fa ${firstEye ? `fa-eye ${passwordErr ? 'text-danger' : 'text-secondary'}` : `fa-eye-slash ${passwordErr ? 'text-danger' : 'text-secondary'}`}`}

                                                    aria-hidden="true"
                                                ></i></span>
                                            </div>
                                        </div>
                                        {/* error msg  */}
                                        <Errormessage class={passwordErr} error={passwordErr} />
                                    </div>
                                    {/* Forgot password*/}
                                    <div className='auth-form-links mb-3 text-end'>
                                        <Link to="/forgot-password">Forget password?</Link>
                                    </div>
                                    {/* Signin button  */}
                                    <div className='signin-button-box'>
                                        <button type='submit' className='btn btn-theme w-100' disabled={state.loading}>
                                            {state.loading ? <Spinner color="text-white" /> : `Sign In`}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            {/* Have Account  */}
                            <div className='have-account-box'>
                                <div className='form-fields-box text-center mb-3'>
                                    <p>Don't have an account?</p>
                                </div>
                                {/* Register button  */}
                                <div className='register-button-box'>
                                    <Link to="/signup" className='btn btn-white w-100'>Register</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </Fragment>
    );
};

export default SignIn;
